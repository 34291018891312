import "./index.css";
import { useNavigate } from "react-router-dom";
import { Flex, Badge } from "antd";
import { pathData } from "../../../../utils/routes";
import { useSearch } from "../../../../components/contexts/SearchContext";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import ImageWithLoader from "../../../../components/common/ImageWithLoader";

const BrandItem = ({ designer }) => {
  const navigate = useNavigate();
  const { setShowSearchMobile } = useSearch();
  const { isMobileView } = useWindowSize();

  const handleClick = () => {
    setShowSearchMobile(false);
    navigate(`${pathData.brand}/${designer.id}`);
  };

  const loadingWidth = isMobileView ? "20vw" : "6vw";

  return (
    <Flex
      gap={isMobileView ? "0.75rem" : "0.5rem"}
      className="vfra-brands-item-container"
      justify="start"
      align="center"
      onClick={handleClick}>
      <ImageWithLoader src={designer.logo_url} style={{ width: "20%" }} loadingWidth={loadingWidth} />
      <Flex style={{ flexGrow: 1 }} justify="space-between" align="center" gap={"0.75rem"}>
        <span className="two-line-ellipsis" style={{ fontWeight: "700", fontSize: "0.9rem" }}>
          {designer.brand_name}
        </span>
        <Badge
          count={designer.perfume_count}
          style={{
            backgroundColor: "rgb(229 229 229 / 37%)",
            color: "rgba(0, 0, 0, 0.88)",
            fontWeight: "700",
            fontSize: "0.7rem",
          }}
        />
      </Flex>
    </Flex>
  );
};

export default BrandItem;
