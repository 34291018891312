import "./index.css";
import { Flex } from "antd";
import { useNavigate } from "react-router-dom";
import { pathData } from "../../../../../utils/routes";
import { useSearch } from "../../../../../components/contexts/SearchContext";
import Rating from "../../../../../components/common/Rating";
import useWindowSize from "../../../../../components/hooks/useWindowSize";

const ItemPerfume = ({ data, ...props }) => {
  const navigate = useNavigate();
  const { isMobileView } = useWindowSize();

  const imgStyle = {
    transform: "translateY(-2rem)",
    height: "25vh",
  };

  const { setShowSearchMobile } = useSearch();
  const handleClick = () => {
    if (isMobileView) setShowSearchMobile(false);
    navigate(`${pathData.perfume}/${data.id}`);
  };

  return (
    <Flex {...props} vertical gap={0} className="vfra-explore-card-popular" onClick={handleClick}>
      <Flex justify="center">
        <img style={imgStyle} alt="" src={data.image_url} />
      </Flex>

      <h1 className="one-line-ellipsis" style={{ margin: "-1rem 0 0.5rem 0", fontSize: "0.9rem" }}>
        {data.name}
      </h1>
      <Rating value={data.rating} />
    </Flex>
  );
};

export default ItemPerfume;
