import "./index.css";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Divider, Carousel, message, Button, Flex } from "antd";
import { getPostByPerfume } from "../../../../services/supabaseClient";
import { pathData } from "../../../../utils/routes";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import PreviewPost from "../../../../components/common/ListPost/PreviewPost";

const PAGE_SIZE = 8;

const RelatedPosts = () => {
  const { isMobileView } = useWindowSize();
  const { perfumeId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { error, data } = await getPostByPerfume(perfumeId, PAGE_SIZE);

        if (error) {
          message.error(error.message);
        } else {
          setPosts(data);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [perfumeId]);

  const span = isMobileView ? 2 : 6;

  let isLessMode = false;
  if (posts.length <= span) isLessMode = true;

  return (
    <>
      {!loading && posts.length > 0 && (
        <div className="vfra-relate-posts-container">
          <Divider>
            <Button
              style={{ fontSize: "0.9rem", fontWeight: "bold" }}
              size="small"
              shape="round"
              type="text"
              onClick={() => navigate(`${pathData.perfume}/${perfumeId}/relate-post`)}>
              Relate Posts
            </Button>
          </Divider>

          {!isLessMode && (
            <Carousel
              style={{ marginBottom: "2rem" }}
              dotPosition="bottom"
              arrows
              slidesToShow={span}
              slidesToScroll={span}>
              {posts.map((post) => (
                <PreviewPost key={post.post.id} post={post.post} />
              ))}
            </Carousel>
          )}

          {isLessMode && (
            <Flex justify="center" gap={isMobileView ? 6 : 12}>
              {posts.map((post) => (
                <PreviewPost key={post.post.id} post={post.post} />
              ))}
            </Flex>
          )}
        </div>
      )}
    </>
  );
};

export default RelatedPosts;
