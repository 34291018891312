import { createContext, useState, useContext } from "react";

const PerfumeContext = createContext();

export const PerfumeProvider = ({ children }) => {
  const [perfumeContext, setPerfumeContext] = useState(null);

  return (
    <PerfumeContext.Provider
      value={{
        perfumeContext,
        setPerfumeContext,
      }}>
      {children}
    </PerfumeContext.Provider>
  );
};

export const usePerfume = () => useContext(PerfumeContext);
