import "./index.css";
import { useState, useEffect } from "react";
import { useSearch } from "../../../components/contexts/SearchContext";
import PopularBrands from "./PopularBrands";
import SearchBrands from "./SearchBrands";
import useWindowSize from "../../../components/hooks/useWindowSize";

const Brands = ({ isMobileSearch = false }) => {
  const { isMobileView } = useWindowSize();
  const [searchMode, setSearchMode] = useState(false);
  const { searchValue } = useSearch();

  useEffect(() => {
    if (isMobileView) {
      if (isMobileSearch) {
        setSearchMode(true);
      } else {
        setSearchMode(false);
      }
    } else {
      if (searchValue) {
        setSearchMode(true);
      } else {
        setSearchMode(false);
      }
    }
  }, [searchValue, isMobileSearch, isMobileView]);

  return (
    <>
      {!searchMode && <PopularBrands />}
      {searchMode && <SearchBrands isMobileSearch={isMobileSearch} />}
    </>
  );
};

export default Brands;
