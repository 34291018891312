import "./index.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Button, Typography, Tag, Row, Col } from "antd";
import { formatTimeshow } from "../../../../../utils/service";
import { useAuth } from "../../../../contexts/AuthContext";
import { usePostContext } from "../../../../contexts/PostContext";
import useWindowSize from "../../../../hooks/useWindowSize";
import ImageWithHandler from "../../../ImageWithHandler";
const { Paragraph } = Typography;

const FeedContent = ({ post, onFollow, ...props }) => {
  const { postContext } = usePostContext();
  const { user } = useAuth();
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();

  const [expand, setExpand] = useState(false);
  const converted_created_at = formatTimeshow(postContext.created_at);

  const expandedStyle = {
    background: "linear-gradient(rgb(0 0 0 / 1%), rgb(0 0 0 / 100%))",
    paddingTop: "3rem",
    borderRadius: "0 0 0.75rem 0.75rem",
  };

  return (
    <Flex {...props} style={expand ? expandedStyle : ""} vertical>
      <Flex align="center" gap={12}>
        <div>
          <ImageWithHandler
            size="large"
            style={{ cursor: "pointer" }}
            title={postContext.owner.name[0]}
            src={postContext.owner.avatar}
            onClick={() => navigate(`/profile/${postContext.owner.id}`)}
          />
        </div>

        <Flex vertical="vertical" gap={3}>
          <span
            className="one-line-ellipsis"
            style={{ fontWeight: "bold", fontSize: isMobileView ? "1.1rem" : "0.9rem" }}>
            {postContext.owner.name}
          </span>

          <span className="vfra-post-infor-time">{converted_created_at}</span>
        </Flex>

        {user && user.user_id !== postContext.owner.id && postContext.owner.followed && (
          <Tag bordered={false} className="vfra-following-tag">
            Following
          </Tag>
        )}

        {user && user.user_id !== postContext.owner.id && !postContext.owner.followed && (
          <Button className="vfra-following-tag" size="small" shape="round" onClick={onFollow}>
            Follow
          </Button>
        )}
      </Flex>

      <Row>
        <Col span={21}>
          <Paragraph
            style={{
              marginTop: isMobileView ? "6px" : "0.75rem",
              overflow: "auto",
              maxHeight: "70vh",
              fontSize: "0.9rem",
            }}
            ellipsis={{
              rows: 1,
              expandable: "collapsible",
              symbol: (expanded) => (expanded ? "less" : "more"),
              expanded: expand,
              onExpand: () => setExpand((prev) => !prev),
            }}>
            {postContext.caption}
          </Paragraph>
        </Col>
      </Row>
    </Flex>
  );
};

export default FeedContent;
