import { MeiliSearch } from "meilisearch";

const client = new MeiliSearch({
  host: "https://search-service.perfumerates.com/",
  apiKey: "60e96f2edae22c7e865c4abaef44d5eb938471fea8dbe73d648e69691efd6769",
});

export const search_notes = (valueSearch, page) => {
  const pageSize = 20;
  const index = client.index("vfra_notes");
  return index.search(valueSearch, {
    limit: pageSize,
    offset: (page - 1) * pageSize,
  });
};

export default client;
