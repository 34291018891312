import { useState, useCallback, useEffect } from "react";
import { Flex, Row, Col, message, Empty } from "antd";
import { search_brands } from "../../../../services/meilisearchBrands";
import { useSearch } from "../../../../components/contexts/SearchContext";
import { debounce } from "lodash";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import BrandItem from "../BrandItem";
import BrandsLoading from "./BrandsLoading";
const PAGE_SIZE = 28;

const SearchBrands = ({ isMobileSearch = false }) => {
  const element = document.getElementById(isMobileSearch ? "vfra-search-tab-brand" : "vfra-home-tab-brand");

  const { isMobileView } = useWindowSize();
  const isMobileHome = isMobileView && !isMobileSearch;

  const { searchValue } = useSearch();
  const [searchValueLocal, setSearchValueLocal] = useState(isMobileHome ? null : searchValue);

  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [brands, setBrands] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!isMobileHome) {
      setSearchValueLocal(searchValue);
      if (element) {
        element.scrollTo(0, 0);
      }

      setPage(1);
    }
  }, [searchValue, isMobileHome, element]);

  const fetchData = useCallback(async (page) => {
    try {
      if (page > 1) setLoadingMore(true);
      else setLoading(true);

      const searchResults = await search_brands("", page, PAGE_SIZE);

      setTotal(searchResults.estimatedTotalHits);
      if (page > 1) {
        setBrands((prevBrands) => [...prevBrands, ...searchResults.hits]);
      } else {
        setBrands(searchResults.hits);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      if (page > 1) setLoadingMore(false);
      else setLoading(false);
    }
  }, []);

  const onSearch = useCallback(
    async (page) => {
      try {
        if (page > 1) setLoadingMore(true);
        else setLoading(true);

        const searchResults = await search_brands(searchValueLocal, page, PAGE_SIZE);

        setTotal(searchResults.estimatedTotalHits);
        if (page > 1) {
          setBrands((prevBrands) => [...prevBrands, ...searchResults.hits]);
        } else {
          setBrands(searchResults.hits);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        if (page > 1) setLoadingMore(false);
        else setLoading(false);
      }
    },
    [searchValueLocal]
  );

  useEffect(() => {
    if (!isMobileHome) {
      onSearch(page);
    } else {
      fetchData(page);
    }
  }, [onSearch, fetchData, page, isMobileHome]);

  const gutter = isMobileView ? [12, 12] : [24, 24];

  // handle auto-loadmore
  const handleScroll = debounce(() => {
    const scrollTop = element.scrollTop;
    const viewportHeight = element.clientHeight;
    const totalHeight = element.scrollHeight;
    const indexLoadmore = isMobileView ? 100 : 50;

    if (
      scrollTop + viewportHeight >= totalHeight - indexLoadmore &&
      !loading &&
      !loadingMore &&
      total > brands.length
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  }, 200);

  useEffect(() => {
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    // Cleanup the event listener
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll, element]);

  return (
    <>
      {loading && <BrandsLoading length={isMobileView ? 16 : 28} />}

      {!loading && brands.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Flex vertical align="center">
              <p style={{ fontWeight: "bold", fontSize: "0.9rem" }}>No brands found!</p>
            </Flex>
          }
        />
      )}

      {!loading && brands.length > 0 && (
        <Row gutter={gutter} justify="center" vertical="vertical">
          {brands.map((item) => {
            return (
              <Col key={item.id} span={!isMobileView ? 6 : 24}>
                <BrandItem designer={item} />
              </Col>
            );
          })}
        </Row>
      )}

      {loadingMore && <BrandsLoading length={isMobileView ? 4 : 12} />}

      <br></br>
    </>
  );
};

export default SearchBrands;
