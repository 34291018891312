import "./index.css";
import { playIcon, pauseIcon, volumeHeightIcon, volumeXmarkIcon } from "../../../../../utils/svgIcons";
import CustomeSvgIcon from "../../../CustomSvgIcon";

const PostAudioActions = ({ playing, muted, triggerPlaySound, triggerMuteSound }) => {
  return (
    <div className="vfra-audio-actions-container">
      <CustomeSvgIcon
        height={"1rem"}
        width={"1.25rem"}
        style={{ fill: "var(--white-color)" }}
        icon={playing ? pauseIcon : playIcon}
        onClick={triggerPlaySound}
      />
      <CustomeSvgIcon
        height={"1rem"}
        width={"2.1875rem"}
        style={{ fill: "var(--white-color)" }}
        icon={muted ? volumeXmarkIcon : volumeHeightIcon}
        onClick={triggerMuteSound}
      />
    </div>
  );
};

export default PostAudioActions;
