import { useState, useEffect } from "react";
import { Flex, Spin, message } from "antd";
import { getPlaylist } from "../../../../../../services/musicServer";
import MusicItem from "../../../../MusicItem";

const Playlist = ({ id, selectedSound, onSelect }) => {
  const [sounds, setSounds] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getPlaylist(id);
        if (
          res[`subsonic-response`].status === "ok" &&
          res[`subsonic-response`].playlist.entry &&
          res[`subsonic-response`].playlist.entry.length > 0
        ) {
          setSounds(res[`subsonic-response`].playlist.entry);
        } else {
          setSounds([]);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return (
    <>
      <div style={{ height: "45vh", overflow: "scroll", padding: "0 6px" }}>
        {loading && (
          <Flex justify="center" align="center" style={{ marginTop: "20vh" }}>
            <Spin size="small" />
          </Flex>
        )}

        {!loading && sounds.length === 0 && (
          <Flex height="100%" justify="center" align="center">
            Playlist empty!
          </Flex>
        )}

        {!loading &&
          sounds.length > 0 &&
          sounds.map((item) => (
            <MusicItem
              key={item.id}
              sound={item}
              onSelectSound={onSelect}
              isChecked={selectedSound && selectedSound.id === item.id}
              className={`vfra-sound-item ${selectedSound && selectedSound.id === item.id ? "active" : ""}`}
            />
          ))}
      </div>
    </>
  );
};

export default Playlist;
