import { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Flex, message } from "antd";
import { getPostDetails } from "../../../../services/supabaseClient";
import { useAuth } from "../../../../components/contexts/AuthContext";
import { AUTH_STATUS } from "../../../../utils/service";
import { markViewedPost } from "../../../../services/supabaseClient";
import Post from "../Post";
import PostAudio from "../PostAudio";
import PostLoading from "../PostLoading";

import { Helmet } from "react-helmet";

const PostDetail = () => {
  window.scrollTo(0, 0);
  const { postId } = useParams();
  const { user, authStatus } = useAuth();

  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      let { error, data } = await getPostDetails([postId]);
      if (error) {
        message.error(error.message);
      } else if (data.length > 0) {
        setPost(data[0].post);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [postId]);

  const markViewed = useCallback(async () => {
    try {
      const startTime = new Date().getTime();
      const endTime = startTime + 3000; //5 mins
      const payload = [
        {
          pid: postId,
          s: startTime,
          e: endTime,
        },
      ];

      markViewedPost(payload);
    } catch (error) {
      message.error(error.message);
    }
  }, [postId]);

  useEffect(() => {
    if (authStatus === AUTH_STATUS.authenticating) return;
    fetchData();

    if (user) {
      markViewed();
    }
  }, [fetchData, authStatus, markViewed, user]);

  // handle follow change
  const handleFollowChange = (userId) => {
    if (userId === post.owner.id) {
      post.owner.followed = true;
      setPost({ ...post });
    }
  };

  // handle audio
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(true);

  const triggerPlaySound = () => {
    setPlaying((prev) => !prev);
  };

  const triggerMuteSound = () => {
    setMuted((prev) => !prev);
  };

  const currentUrl = window.location.href;

  return (
    <>
      {isLoading && <PostLoading />}

      {/* post detail */}
      {!isLoading && post && (
        <>
          <Helmet>
            <title>Scentub</title>
            <meta property="og:site_name" content="Vfra" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Scentub" />
            <meta property="og:description" content={post.caption} />
            <meta property="og:image" content={post.media_data.urls[0]} />
            <meta property="og:image:width" content="360" />
            <meta property="og:image:height" content="360" />
            <meta property="og:image:alt" content="Vfra" />
            <meta property="og:url" content={currentUrl} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={post.caption} />
            <meta name="twitter:description" content={post.caption} />
            <meta name="twitter:image" content={post.media_data.urls[0]} />
          </Helmet>

          <Post
            post={post}
            index={0}
            onChangeFollow={handleFollowChange}
            style={{ margin: 0, marginBottom: "2rem", background: "var(--white-color)" }}
            playing={playing}
            triggerPlaySound={triggerPlaySound}
            muted={muted}
            triggerMuteSound={triggerMuteSound}
          />

          {post.music_data && (
            <PostAudio playing={playing} onPause={() => setPlaying(false)} muted={muted} data={post.music_data} />
          )}
        </>
      )}

      {!isLoading && !post && (
        <Flex justify="center" style={{ paddingTop: "20%" }}>
          <i>No post found!</i>
        </Flex>
      )}
    </>
  );
};

export default PostDetail;
