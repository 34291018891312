import "./index.css";
import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Flex, message } from "antd";
import _ from "lodash";
import { useAuth } from "../../components/contexts/AuthContext";
import { TIME_RESET_NEW_FEED, MIN_TIME_SPENT } from "../../utils/service";
import { setItemWithExpiry, getItemWithExpiry, localStorage_fields } from "../../utils/localstorage";
import { pathData } from "../../utils/routes";
import { markViewedPost } from "../../services/supabaseClient";
import Post from "../../components/common/Posts/Post";
import PostAudio from "../../components/common/Posts/PostAudio";

const SearchPostsDetail = () => {
  const [searchParams] = useSearchParams();
  const startId = searchParams.get("startId");

  const { user } = useAuth();
  const navigate = useNavigate();

  const initPosts = getItemWithExpiry(localStorage_fields.searchPostsResults) ?? [];
  const [posts, setPosts] = useState(initPosts);

  // handle group viewed
  const viewTimesRef = useRef([]);

  const postRefs = useRef([]);
  const enterTimes = useRef({});

  const onMarkViewedPosts = () => {
    const unsentPosts = viewTimesRef.current.filter((e) => e.timeChanged && e.timeSpent > MIN_TIME_SPENT);

    if (unsentPosts.length === 0) return;

    try {
      const payload = unsentPosts.map((e) => {
        return {
          pid: e.id,
          s: e.startTime,
          e: e.startTime + e.timeSpent,
        };
      });
      markViewedPost(payload);

      viewTimesRef.current = viewTimesRef.current.map((v) => {
        return {
          ...v,
          timeChanged: false,
        };
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  // handle cache last index post
  const cacheLastIndex = _.debounce((postIndex) => {
    setItemWithExpiry(localStorage_fields.searchLastIndexPost, postIndex, TIME_RESET_NEW_FEED);
  }, MIN_TIME_SPENT);

  const handleBack = useCallback(() => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(pathData.home);
    }
  }, [navigate]);

  useEffect(() => {
    return () => {
      onMarkViewedPosts();
    };
  }, [handleBack]);

  useEffect(() => {
    if (!startId) return;
    const startIndex = posts.findIndex((e) => e.id === startId);

    if (startIndex > 0) {
      postRefs.current[startIndex].scrollIntoView({
        behavior: "smooth", // or 'smooth'
        //block: "center", // or 'end', 'center', 'nearest'
        //inline: "center", // or 'start', 'center', 'end'
      });
    }
  }, [posts, startId]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const postIndex = parseInt(entry.target.getAttribute("data-index"), 10);
          const postId = posts[postIndex].id;

          if (entry.isIntersecting) {
            //handle reset sound
            let lastIndexPost = getItemWithExpiry(localStorage_fields.searchLastIndexPost);
            if (postIndex !== lastIndexPost) setPlaying(true);

            //handle cache post
            cacheLastIndex(postIndex);
            setCurrentIndex(postIndex);

            //mark viewed
            onMarkViewedPosts();

            // Record the time when the post enters the viewport
            enterTimes.current[postIndex] = new Date().getTime();
          } else {
            const exitTime = new Date().getTime();
            const enterTime = enterTimes.current[postIndex];

            if (enterTime) {
              const timeSpent = exitTime - enterTime;

              const viewTimes = viewTimesRef.current;
              const index = viewTimes.findIndex((e) => e.id === postId);
              viewTimes.push({
                id: postId,
                startTime: enterTime,
                timeSpent: timeSpent,
                isFirstTime: index >= 0 ? false : true,
                timeChanged: true,
              });

              delete enterTimes.current[postIndex];
            }
          }
        });
      },
      { threshold: 0.7 }
    );

    const currentPostRefs = postRefs.current;

    currentPostRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      currentPostRefs.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [user, posts, cacheLastIndex]);

  const handleFeedRender = (el, index) => {
    postRefs.current[index] = el;
  };

  // handle follow change
  const handleFollowChange = (userId) => {
    posts.forEach((post) => {
      if (userId === post.post.owner.id) {
        post.owner.followed = true;
      }
    });

    setPosts([...posts]);
  };

  // handle audio
  const [currentIndex, setCurrentIndex] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(true);

  const triggerPlaySound = () => {
    setPlaying((prev) => !prev);
  };

  const triggerMuteSound = () => {
    setMuted((prev) => !prev);
  };

  return (
    <>
      {posts.length > 0 && (
        <div className="vfra-infinite-view">
          {posts.map((post, index) => (
            <Post
              key={`${post.id}-${index}`}
              post={post}
              ref={(el) => handleFeedRender(el, index)}
              index={index}
              onChangeFollow={handleFollowChange}
              playing={playing && currentIndex === index}
              triggerPlaySound={triggerPlaySound}
              muted={muted}
              triggerMuteSound={triggerMuteSound}
            />
          ))}

          {posts[currentIndex].music_data && (
            <PostAudio
              id={posts[currentIndex].id}
              muted={muted}
              data={posts[currentIndex].music_data}
              playing={playing}
              onPause={() => setPlaying(false)}
            />
          )}

          <Flex align="center" gap={12} justify="center" style={{ marginTop: "-1.6rem" }}>
            <Button size="small" shape="round" onClick={handleBack} style={{ fontSize: "0.75rem", fontWeight: "500" }}>
              Back to search
            </Button>
          </Flex>
        </div>
      )}
    </>
  );
};

export default SearchPostsDetail;
