import "./index.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Divider, message, Row, Col, Carousel } from "antd";
import { search_perfumes } from "../../../services/meilisearchPerfumes";
import { useSearch } from "../../../components/contexts/SearchContext";
import { usePerfume } from "../../../components/contexts/PefumeContext";
import useWindowSize from "../../../components/hooks/useWindowSize";
import PerfumeItem from "./PerfumeItem";
const PAGE_SIZE = 36;

const RelatedPerfumes = () => {
  const { isMobileView } = useWindowSize();
  const { perfumeId } = useParams();
  const { perfumeContext } = usePerfume();

  const { searchValue } = useSearch();
  const [loading, setLoading] = useState(true);
  const [perfumes, setPerfumes] = useState([]);

  useEffect(() => {
    const fetchData = async (contentSearch) => {
      try {
        const searchResults = await search_perfumes(contentSearch, 1, PAGE_SIZE);
        const data = searchResults.hits.length > 0 ? searchResults.hits.filter((e) => e.id !== perfumeId) : [];
        setPerfumes(data);
      } catch (error) {
        setPerfumes([]);
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (searchValue) {
      fetchData(searchValue);
    } else if (perfumeContext) {
      fetchData(perfumeContext.brand_name);
    }
  }, [perfumeId, searchValue, perfumeContext]);

  const span = isMobileView ? 2 : 6;

  let isLessMode = false;
  if (perfumes.length <= span) isLessMode = true;

  return (
    <>
      {!loading && perfumes.length > 0 && (
        <div className="vfra-pefume-related-perfumes-container">
          <Divider style={{ fontWeight: "bold", fontSize: "0.9rem" }}>Maybe you are looking</Divider>

          {!isLessMode && (
            <Carousel infinite={false} dots={false} arrows slidesToShow={span} slidesToScroll={span}>
              {perfumes.map((item) => (
                <PerfumeItem key={item.id} item={item} />
              ))}
            </Carousel>
          )}

          {isLessMode && (
            <Row gutter={[12, 12]}>
              {perfumes.map((item) => (
                <Col key={item.id} span={4}>
                  <PerfumeItem item={item} />
                </Col>
              ))}
            </Row>
          )}
          <br></br>
        </div>
      )}
    </>
  );
};

export default RelatedPerfumes;
