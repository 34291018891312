import "./ActionBtn/index.css";
import { Dropdown, Flex, message } from "antd";
import { DislikeFilled, DislikeOutlined } from "@ant-design/icons";
import useWindowSize from "../../../../hooks/useWindowSize";
import {
  bookmarkIconFilled,
  heartIconFilled,
  commentIconFilled,
  shareIconFilled,
  ellipsisVerficalIconFilled,
  xmarkIconFilled,
} from "../../../../../utils/svgIcons";
import { reactionActions, reactionTypes, colors } from "../../../../../utils/service";
import { addPostReaction, removePostReaction } from "../../../../../services/supabaseClient";
import { pathData } from "../../../../../utils/routes";
import { useAuth } from "../../../../contexts/AuthContext";
import { usePostContext } from "../../../../contexts/PostContext";
import AudioAvatar from "../../../AudioPlayer";
import CustomeSvgIcon from "../../../CustomSvgIcon";
import ActionBtn from "./ActionBtn";

const PostActions = ({
  index,
  onShowComments,
  showComments,
  commentCount,
  showPerfumes,
  onTriggerRelatePerfumes,
  playing,
  triggerPlaySound,
  ...props
}) => {
  const { user, openLoginModal } = useAuth();
  const { postContext, setPostContext } = usePostContext();
  const { isMobileView } = useWindowSize();

  const menuItems = [
    {
      label: "Dislike",
      key: "dislike",
      icon: postContext.active_for_user_dislike ? (
        <DislikeFilled style={{ color: colors.primary }} />
      ) : (
        <DislikeOutlined />
      ),
    },
  ];

  const onMenuClick = (key) => {
    if (key === "dislike") {
      handleReactions(
        postContext.active_for_user_dislike ? reactionActions.remove : reactionActions.add,
        reactionTypes.dislike
      );
    }
  };

  // share button
  const handleCopyUrl = () => {
    const currentHost = window.location.host;

    const convertedUrl = currentHost + `${pathData.postDetail}/${postContext.id}`;

    navigator.clipboard
      .writeText(convertedUrl)
      .then(() => {
        message.success("Post's URL copied to clipboard!");
      })
      .catch(() => {
        message.error("Failed to copy URL.");
      });
  };

  const handleReactions = (action, reactionType) => {
    if (!user) {
      openLoginModal();
      return;
    }

    if (action === reactionActions.add) {
      handleReactionsChange(reactionActions.add, reactionType);
      addPostReaction(postContext.id, reactionType);
    } else {
      handleReactionsChange(reactionActions.remove, reactionType);
      removePostReaction(postContext.id, reactionType);
    }
  };

  const handleReactionsChange = (action, type) => {
    if (type === reactionTypes.heart) {
      if (action === reactionActions.add) {
        postContext.total_hearts++;
        postContext.active_for_user_heart = true;
      } else if (action === reactionActions.remove) {
        postContext.total_hearts--;
        postContext.active_for_user_heart = false;
      }
    } else if (type === reactionTypes.save) {
      if (action === reactionActions.add) {
        postContext.total_saves++;
        postContext.active_for_user_save = true;
      } else if (action === reactionActions.remove) {
        postContext.total_saves--;
        postContext.active_for_user_save = false;
      }
    } else if (type === reactionTypes.dislike) {
      if (action === reactionActions.add) {
        postContext.active_for_user_dislike = true;
      } else if (action === reactionActions.remove) {
        postContext.active_for_user_dislike = false;
      }
    }

    setPostContext({ ...postContext });
  };

  const musicAvtSize = isMobileView ? "35px" : "2.8125rem";

  return (
    <>
      <Flex vertical {...props} gap={isMobileView ? 16 : 12}>
        <ActionBtn
          id="save"
          showComments={showComments}
          active={postContext.active_for_user_save}
          icon={bookmarkIconFilled}
          count={postContext.total_saves}
          style={{ marginLeft: "0.1875rem" }}
          onClickBtn={() =>
            handleReactions(
              postContext.active_for_user_save ? reactionActions.remove : reactionActions.add,
              reactionTypes.save
            )
          }
        />

        <ActionBtn
          id="love"
          showComments={showComments}
          active={postContext.active_for_user_heart}
          icon={heartIconFilled}
          count={postContext.total_hearts}
          style={{ marginLeft: "-0.125rem" }}
          onClickBtn={() =>
            handleReactions(
              postContext.active_for_user_heart ? reactionActions.remove : reactionActions.add,
              reactionTypes.heart
            )
          }
        />
        <ActionBtn
          showComments={showComments}
          active={false}
          icon={commentIconFilled}
          count={commentCount}
          style={{ marginLeft: "-0.125rem" }}
          onClickBtn={onShowComments}
        />

        <ActionBtn
          showComments={showComments}
          title="Share"
          active={false}
          icon={shareIconFilled}
          onClickBtn={handleCopyUrl}
        />

        {postContext.perfumes && (
          <ActionBtn
            showComments={showComments}
            active={false}
            icon={showPerfumes ? xmarkIconFilled : null}
            image={showPerfumes ? null : postContext.perfumes[0].image_url}
            style={showPerfumes ? { marginLeft: "0.1875rem" } : { height: "1.5625rem", borderRadius: "0.1875rem" }}
            count={postContext.perfumes.length}
            onClickBtn={onTriggerRelatePerfumes}
          />
        )}

        <Dropdown
          menu={{
            items: menuItems,
            onClick: ({ key }) => {
              onMenuClick(key);
            },
          }}
          placement="topLeft"
          trigger={["click"]}>
          <Flex
            className={`vfra-post-action-btn ${showComments ? "vfra-post-action-btn-comment" : ""}`}
            justify="center"
            align="center">
            <CustomeSvgIcon
              style={{ marginLeft: isMobileView ? "17px" : "0.8125rem" }}
              className={`vfra-post-action-icon ${showComments ? "vfra-post-action-icon-comment" : ""}`}
              icon={ellipsisVerficalIconFilled}
            />
          </Flex>
        </Dropdown>

        {postContext.music_data && (
          <div
            style={{
              width: musicAvtSize,
              height: musicAvtSize,
              backgroundColor: "rgb(174 174 174 / 20%)",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            onClick={triggerPlaySound}>
            <AudioAvatar
              trackId={postContext.music_data.track_id}
              className={`${playing ? "spinning" : ""}`}
              style={{ borderRadius: "50%", width: "100%", height: "100%" }}
              alt=""
            />
          </div>
        )}
      </Flex>
    </>
  );
};

export default PostActions;
