import "./index.css";
import { Flex } from "antd";

const defaultData = [5, 10, 80];

const CustomProgress = ({ data = defaultData }) => {
  return (
    <Flex vertical gap={6}>
      <Flex>
        <Flex justify="center" style={{ width: `${data[0]}%`, fontSize: "0.7rem" }}>
          {data[0]}
        </Flex>
        <Flex justify="center" style={{ width: `${data[1]}%`, fontSize: "0.7rem" }}>
          {data[1]}
        </Flex>
        <Flex justify="center" style={{ width: `${data[2]}%`, fontSize: "0.7rem" }}>
          {data[2]}
        </Flex>
      </Flex>
      <Flex>
        <div className="vfra-part-progress" style={{ width: `${data[0]}%` }} />
        <div className="vfra-part-progress " style={{ width: `${data[1]}%` }}>
          <div className="vertical-divider" />
        </div>
        <div className="vfra-part-progress" style={{ width: `${data[2]}%` }}>
          <div className="vertical-divider" />
        </div>
      </Flex>
    </Flex>
  );
};

export default CustomProgress;
