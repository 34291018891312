import "./index.css";
import { Skeleton, Row, Col } from "antd";
import useWindowSize from "../../../../components/hooks/useWindowSize";

const PerfumeLoading = ({ length }) => {
  const { isMobileView } = useWindowSize();

  return (
    <>
      <Row className="vfra-perfumes-loading-container" gutter={!isMobileView ? [24, 24] : [12, 12]}>
        {Array.from({ length: length }).map((_, i) => (
          <Col key={i} span={!isMobileView ? 6 : 24}>
            <Skeleton.Node active={true} style={{ height: isMobileView ? "10vh" : "15vh" }} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default PerfumeLoading;
