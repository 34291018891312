import "./index.css";
import { Flex } from "antd";

const AccordTag = ({ name, ...props }) => {
  return (
    <Flex {...props} className="vfra-accord-tag" justify="center" algin="center">
      <span className="one-line-ellipsis">{name}</span>
    </Flex>
  );
};

export default AccordTag;
