import "./index.css";
import { useNavigate } from "react-router-dom";
import { Flex, Divider, Row, Col } from "antd";
import { pathData } from "../../../../utils/routes";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import ImageWithLoader from "../../../../components/common/ImageWithLoader";

const ListPerfume = ({ title, perfumes }) => {
  const navigate = useNavigate();
  const { isMobileView } = useWindowSize();

  const spanShelf = isMobileView ? 4 : 3;
  const imageHeight = isMobileView ? 64 : 96;

  return (
    <Flex vertical gap={"0.75rem"}>
      <Divider orientationMargin="0" orientation="left" style={{ fontSize: "0.9rem" }}>
        {title} <span style={{ color: "var(--gray-color)" }}>{perfumes.length}</span>
      </Divider>
      <Row gutter={[0, 54]} justify="center">
        {perfumes.map((e) => {
          return (
            <Col key={e.id} span={spanShelf}>
              <Flex justify="center">
                <ImageWithLoader
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`${pathData.perfume}/${e.id}`)}
                  className="perfume-on-shelf"
                  height={imageHeight}
                  src={e.image_url}
                  loadingHeight={"10vh"}
                  loadingWidth={isMobileView ? "12vw" : "4vw"}
                />
              </Flex>
              <div className="bottom-shelf"></div>
            </Col>
          );
        })}
      </Row>
    </Flex>
  );
};

export default ListPerfume;
