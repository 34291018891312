import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Flex, message } from "antd";
import { useAuth } from "../../../components/contexts/AuthContext";
import { getUserWithAccessToken } from "../../../services/supabaseClient";
import useWindowSize from "../../../components/hooks/useWindowSize";
import UserFollow from "./UserFollow";
import UserAvatar from "./UserAvatar";
import UserName from "./UserName";
import UserDescription from "./UserDescription";
import ProfileActions from "./ProfileActions";

const UserInfo = () => {
  const { isMobileView } = useWindowSize();
  const { user } = useAuth();
  const { userId } = useParams();
  const isYourself = user?.user_id === userId;

  const [loading, setLoading] = useState(true);
  const [vfraUser, setVfraUser] = useState(null);

  const getVfraUser = useCallback(async (userId) => {
    try {
      const { error, data } = await getUserWithAccessToken(userId);
      if (error) {
        message.error(error);
      } else {
        const vfra_user = data.length > 0 ? data[0] : null;
        setVfraUser(vfra_user);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getVfraUser(userId);
  }, [userId, getVfraUser]);

  return (
    <Flex gap={"1rem"} className="vfra-user-infor-container" style={{ marginTop: isMobileView ? "0.2rem" : "0.5rem" }}>
      <UserAvatar loading={loading} user={vfraUser} />

      <Flex vertical gap={isMobileView ? "0.4rem" : "0.6rem"} justify="center">
        <UserName loading={loading} user={vfraUser} />
        <UserFollow loading={loading} user={vfraUser} />
        <ProfileActions loading={loading} dataUser={vfraUser} isYourself={isYourself} />
        <UserDescription loading={loading} user={vfraUser} />
      </Flex>
    </Flex>
  );
};

export default UserInfo;
