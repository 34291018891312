import "./index.css";
import { useState, useCallback, useEffect, memo } from "react";
import { message, Flex, Empty, Skeleton } from "antd";
import { getFeeds } from "../../../../services/supabaseClient";
import { localStorage_fields, setItemWithExpiry, getItemWithExpiry } from "../../../../utils/localstorage";
import { TIME_RESET_NEW_FEED, AUTH_STATUS } from "../../../../utils/service";
import { useAuth } from "../../../../components/contexts/AuthContext";
import { debounce } from "lodash";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import PostItem from "./PostItem";
import PostListLoading from "./PostListLoading";
import DividerTitle from "../DividerTitle";
const values = [30, 40, 50, 60];
const getRandomHeight = () => `${values[Math.floor(Math.random() * values.length)]}vh`;

const PostExplore = memo(() => {
  const { isMobileView } = useWindowSize();
  const { authStatus } = useAuth();

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingmore] = useState(false);

  const fetchData = useCallback(
    async (isLoadmore = false) => {
      try {
        if (isLoadmore) setLoadingmore(true);
        else setLoading(true);

        const PAGE_SIZE_NEW_FEED = isMobileView ? 8 : 15;

        let page = getItemWithExpiry(localStorage_fields.page) ?? 0;
        page++;
        setItemWithExpiry(localStorage_fields.page, page, TIME_RESET_NEW_FEED);

        let { error, data, c_post } = await getFeeds(page, PAGE_SIZE_NEW_FEED);

        if (error) {
          message.error(error.message);
        } else {
          const fetchData = [];

          if (c_post && c_post.length > 0) {
            c_post.forEach((p) => {
              if (p.post !== null) {
                fetchData.push({
                  ...p,
                  isCPost: true,
                });
              }
            });
          }

          data.forEach((p) => {
            if (p.post) {
              const index = fetchData.findIndex((e) => e.post.id === p.post.id);
              if (index === -1) {
                fetchData.push(p);
              }
            }
          });

          if (fetchData && fetchData.length > 0) {
            setPosts((prev) => {
              let newPosts = fetchData;
              if (isLoadmore) {
                newPosts = [...prev, ...fetchData];
              }

              setItemWithExpiry(localStorage_fields.posts, newPosts, TIME_RESET_NEW_FEED);
              return newPosts;
            });
          }

          if (fetchData.length < PAGE_SIZE_NEW_FEED) {
            setItemWithExpiry(localStorage_fields.page, 0, TIME_RESET_NEW_FEED);
          }
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        if (isLoadmore) setLoadingmore(false);
        else setLoading(false);
      }
    },
    [isMobileView]
  );

  // handle force reload (f5)
  useEffect(() => {
    if (authStatus === AUTH_STATUS.authenticating) return;
    const handleBeforeUnload = () => {
      localStorage.removeItem(localStorage_fields.posts);
      localStorage.removeItem(localStorage_fields.lastIndexPost);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [authStatus]);

  useEffect(() => {
    const localsPosts = getItemWithExpiry(localStorage_fields.posts) ?? [];
    if (localsPosts.length > 0) {
      setPosts(localsPosts);
    } else {
      fetchData();
    }
  }, [fetchData]);

  const itemSpan = isMobileView ? 2 : 5;
  const itemWidth = isMobileView ? "50%" : "20%";
  const postsToShow = [];

  if (posts.length > 0) {
    posts.forEach((e, i) => {
      const index = i % itemSpan;

      if (typeof postsToShow[index] === "object") {
        postsToShow[index].push({ ...e, index: i });
      } else {
        postsToShow[index] = [{ ...e, index: i }];
      }
    });
  }

  const gapItem = isMobileView ? 8 : "1rem";

  // handle auto-loadmore
  const handleScroll = debounce(() => {
    const element = document.getElementById("vfra-home-tab-scentub");

    const scrollTop = element.scrollTop;
    const viewportHeight = element.clientHeight;
    const totalHeight = element.scrollHeight;
    const indexLoadmore = isMobileView ? 100 : 50;

    if (scrollTop + viewportHeight >= totalHeight - indexLoadmore && !loading && !loadingMore) {
      fetchData(true);
    }
  }, 200);

  useEffect(() => {
    const element = document.getElementById("vfra-home-tab-scentub");

    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    // Cleanup the event listener
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <Flex gap={0} vertical>
      <DividerTitle title="Explore posts" />

      {loading && <PostListLoading />}

      {!loading && posts.length === 0 && (
        <Empty
          style={{ marginTop: "3rem" }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Flex vertical align="center">
              <p style={{ fontWeight: "bold", fontSize: "0.9rem" }}>No posts found!</p>
            </Flex>
          }
        />
      )}

      {!loading && posts.length > 0 && (
        <Flex justify="start" gap={gapItem}>
          {postsToShow.map((item, i) => {
            return (
              <Flex key={`vfra-posts-to-show-${i}`} vertical style={{ width: itemWidth }} gap={gapItem}>
                {item.map((post) => {
                  return (
                    <PostItem
                      key={`${post.index}-${post.post.id}`}
                      keyPost={`${post.index}-${post.post.id}`}
                      post={post.post}
                    />
                  );
                })}

                {loadingMore && (
                  <Skeleton.Node
                    active={true}
                    style={{ borderRadius: "1rem", width: "100%", height: getRandomHeight() }}
                  />
                )}
              </Flex>
            );
          })}
        </Flex>
      )}
      <br></br>
    </Flex>
  );
});

export default PostExplore;
