import { Typography } from "antd";

const UserDescription = ({ user, loading }) => {
  if (loading) return null;
  else
    return (
      <Typography.Paragraph
        ellipsis={{
          fontSize: "0.8125rem",
          rows: 2,
          expandable: "collapsible",
          symbol: (expanded) => (expanded ? "less" : "more"),
        }}
        style={{ fontSize: "0.9rem", margin: 0 }}>
        {user ? user.description : ""}
      </Typography.Paragraph>
    );
};

export default UserDescription;
