import "./index.css";
import { useState, forwardRef } from "react";
import { Flex, Carousel } from "antd";
import useWindowSize from "../../../hooks/useWindowSize";
import { useAuth } from "../../../contexts/AuthContext";
import { follow } from "../../../../services/supabaseClient";
import { PostProvider } from "../../../contexts/PostContext";
import PostImage from "./PostImage";
import PostContent from "./PostContent";
import PostActions from "./PostActions";
import PostRelatePerfumes from "./PostRelatePerfumes";
import PerfumeAccords from "./PostRelatePerfumes/PerfumeAccords";
import PostComment from "./PostComment";
import PostAudioActions from "./PostAudioActions";

const DELAY_TIME = 200;

const commentCountTypes = {
  increament: "increament",
  decreament: "decreament",
};

const Post = forwardRef(
  ({ post, index, onChangeFollow, playing, triggerPlaySound, muted, triggerMuteSound, ...prop }, ref) => {
    const { isMobileView } = useWindowSize();
    const { user, openLoginModal } = useAuth();

    // handle comments
    const [showComments, setShowComments] = useState(false);
    const [commentsAnimationState, setCommentsAnimationState] = useState("");
    const [feedAnimationState, setFeedAnimationState] = useState("");

    // handle relate perfumes
    const [showPerfumes, setShowPerfumes] = useState(false);
    const [perfumesAnimationState, setPerfumesAnimationState] = useState("");

    // handle perfume accords
    const firstRelatePerfume = post.perfumes ? post.perfumes[0] : null;
    const [perfume, setPerfume] = useState(firstRelatePerfume);
    const [showAccords, setShowAccords] = useState(firstRelatePerfume);
    const [accordsAnimationState, setAccordsAnimationState] = useState("");

    const onShowComments = () => {
      if (!isMobileView) {
        setCommentsAnimationState("");
        setFeedAnimationState("appear-comments");
      }

      setShowComments(true);
    };

    const onHideComments = () => {
      if (!isMobileView) {
        setCommentsAnimationState("hide");
        setFeedAnimationState("hide-comments");
        setTimeout(() => setShowComments(false), DELAY_TIME);
      } else {
        setShowComments(false);
      }
    };

    const onTriggerRelatePerfumes = () => {
      if (!showPerfumes) {
        setPerfumesAnimationState("");
        setShowPerfumes(true);
      } else {
        setPerfumesAnimationState("hide-perfumes");
        setTimeout(() => {
          setShowPerfumes(false);
          setPerfume(null);
        }, DELAY_TIME);

        if (perfume) {
          setAccordsAnimationState("hide-accords");
          setTimeout(() => {
            setPerfume(null);
          }, DELAY_TIME);
        }
      }
    };

    const onTriggerAccords = (newPerfume) => {
      if (!perfume || newPerfume.id !== perfume.id) {
        setPerfume(newPerfume);
        setAccordsAnimationState("");
        setShowAccords(true);
      }
    };

    const onFollow = () => {
      if (!user) {
        openLoginModal();
        return;
      }

      const payload = {
        followee_id: post.owner.id,
      };

      follow(payload);
      onChangeFollow(post.owner.id);
    };

    const [commentCount, setCommentCount] = useState(post.total_comments);
    const handleCommentCountChange = (type) => {
      if (type === commentCountTypes.increament) {
        setCommentCount((prev) => prev + 1);
      } else {
        setCommentCount((prev) => prev - 1);
      }
    };

    const WIDTH_CARD = isMobileView ? "100%" : "49.5vh";
    const styleGroupFloatButton = {
      position: "absolute",
      right: isMobileView ? "1rem" : showComments ? "1rem" : "-3.5rem",
      bottom: "0.75rem",
    };

    const styleRelatePerfumeContainer = {
      right: isMobileView ? "64px" : showComments ? "4.5rem" : "1rem",
      bottom: "9.5rem",
    };

    const cardPostStyle = {
      width: WIDTH_CARD,
      borderRadius: showComments ? "12px 0 0 12px" : "12px",
      borderRight: showComments ? 0 : "none",
    };
    const cardPostClass = `${showComments ? "left-box-shadow" : "normal-box-shadow"}`;

    return (
      <PostProvider postInit={post}>
        <Flex {...prop} ref={ref} data-index={index} justify="center" className="vfra-newfeed-container">
          <Flex
            vertical
            justify="space-between"
            className={`vfra-post-container ${feedAnimationState} ${cardPostClass}`}
            style={cardPostStyle}>
            {post.media_data && post.media_data.urls && post.media_data.urls.length > 1 && (
              <Carousel slidesToShow={1} slidesToScroll={1} dotPosition="bottom" arrows infinite={false}>
                {post.media_data.urls.map((item) => (
                  <PostImage key={item} src={item} />
                ))}
              </Carousel>
            )}

            {post.media_data && post.media_data.urls && post.media_data.urls.length === 1 && (
              <PostImage src={post.media_data.urls[0]} />
            )}

            <PostContent post={post} onFollow={onFollow} className="vfra-post-infor-container" />

            <PostActions
              index={index}
              onShowComments={onShowComments}
              showComments={showComments}
              commentCount={commentCount}
              showPerfumes={showPerfumes}
              onTriggerRelatePerfumes={onTriggerRelatePerfumes}
              playing={playing}
              triggerPlaySound={triggerPlaySound}
              style={styleGroupFloatButton}
            />

            {post.perfumes && (
              <PostRelatePerfumes
                style={styleRelatePerfumeContainer}
                className={`vfra-relate-perfumes-container ${perfumesAnimationState} ${
                  showPerfumes ? "show-perfumes" : "hide-perfumes hidden"
                }`}
                perfumes={post.perfumes}
                activePerfume={perfume}
                onTriggerAccords={onTriggerAccords}
              />
            )}

            {perfume && (
              <PerfumeAccords
                className={`vfra-accords-container ${accordsAnimationState} ${
                  showAccords ? "show-accords" : "hide-accords hidden"
                }`}
                perfume={perfume}
              />
            )}

            {post.music_data && (
              <PostAudioActions
                muted={muted}
                triggerMuteSound={triggerMuteSound}
                playing={playing}
                triggerPlaySound={triggerPlaySound}
              />
            )}
          </Flex>

          <PostComment
            postId={post.id}
            showComments={showComments}
            commentCount={commentCount}
            commentsAnimationState={commentsAnimationState}
            handleCommentCountChange={handleCommentCountChange}
            onHideComments={onHideComments}
          />
        </Flex>
      </PostProvider>
    );
  }
);

export default Post;
