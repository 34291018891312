import "./index.css";
import { Col, Row, Progress, Divider, Flex } from "antd";
import { getMaxValueFromList, colors } from "../../../../../utils/service";
import useWindowSize from "../../../../../components/hooks/useWindowSize";

const Accords = ({ accords, showTitle = true, size = [370, 3], isVertical = false, fontSize = "0.8125rem" }) => {
  const max = getMaxValueFromList(accords, "count");
  accords.sort((a, b) => b.count - a.count);

  const { isMobileView } = useWindowSize();

  return (
    <>
      {showTitle && <Divider style={{ fontSize: "0.9rem", fontWeight: "bold" }}>Main accords</Divider>}
      <Flex gap="small" vertical style={{ width: "100%" }}>
        {accords.map((item) => {
          const percent = max === 0 ? 0 : (item.count / max).toFixed(2) * 100;
          return (
            <Row key={item.name} gutter={isVertical ? 0 : [4]} align="center">
              <Col span={isVertical ? 24 : !isMobileView ? 8 : 10}>
                <span style={{ fontSize: fontSize }}>{item.name}</span>
              </Col>
              <Col span={isVertical ? 24 : !isMobileView ? 16 : 14} className="progress-notes">
                <Progress
                  percent={percent}
                  percentPosition={{
                    align: "start",
                    type: "none",
                  }}
                  size={size}
                  strokeColor={colors.mainThemeColor}
                  style={{ width: "auto", marginTop: "-0.125rem" }}
                />
              </Col>
            </Row>
          );
        })}
      </Flex>
    </>
  );
};

export default Accords;
