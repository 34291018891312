import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Empty, Flex, message, Button, Skeleton, Divider } from "antd";
import { useAuth } from "../../contexts/AuthContext";
import useWindowSize from "../../hooks/useWindowSize";
import PreviewPost from "./PreviewPost";
const PAGE_SIZE = 10;
const typeList = {
  published: "published",
  saved: "saved",
  loved: "loved",
  allRelatePost: "allRelatePost",
};

export const ListPost = ({
  type,
  title,
  emptyTitle,
  emptyContent,
  reload,
  fetchApi,
  deleteApi,
  successDeleteMessage,
}) => {
  const { isMobileView } = useWindowSize();
  const { user } = useAuth();
  const { userId } = useParams();
  const isYourself = user?.user_id === userId;

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showLoadmore, setShowLoadmore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const fetchData = useCallback(
    async (startTime = 0) => {
      if (startTime) setLoadingMore(true);
      try {
        let res = null;
        if (type === typeList.published) {
          res = await fetchApi(userId, startTime, PAGE_SIZE);
        } else if (type === typeList.saved) {
          res = await fetchApi("save", startTime, PAGE_SIZE);
        } else if (type === typeList.saved) {
          res = await fetchApi("heart", startTime, PAGE_SIZE);
        } else {
          res = await fetchApi("heart", startTime, PAGE_SIZE);
        }

        let { error, data } = res;
        if (error) {
          message.error(error.message);
        } else {
          if (data.length === 0) {
            setShowLoadmore(false);
            if (startTime === 0) setPosts([]);
          } else {
            if (type === typeList.published) {
              data = data.map((e) => e.post);
            }

            if (data.length < PAGE_SIZE) {
              setShowLoadmore(false);
            }

            if (startTime !== 0) {
              setPosts((prev) => [...prev, ...data]);
            } else {
              setPosts(data);
            }
          }
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        if (startTime) setLoadingMore(false);
        else setLoading(false);
      }
    },
    [userId, fetchApi, type]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData, reload]);

  const handleRemove = async (id) => {
    try {
      let res = null;
      if (type === typeList.published) {
        res = await deleteApi(id);
      } else if (type === "saved") {
        res = await deleteApi(id, "save");
      } else {
        res = await deleteApi(id, "heart");
      }

      const { error } = res;

      if (error) {
        message.error(error.message);
      } else {
        message.success(successDeleteMessage);

        setPosts((prev) => {
          return prev.filter((post) => post.id !== id);
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleLoadmore = () => {
    let startTime = posts[posts.length - 1].created_at;

    fetchData(new Date(startTime).getTime());
  };

  const postSize = isMobileView ? { width: "45vw", height: "80vw" } : { width: "13vw", height: "23vw" };
  const itemGap = isMobileView ? 6 : 24;

  return (
    <>
      <Divider orientationMargin="0" orientation="left" style={{ fontSize: "0.9rem" }}>
        {title}
      </Divider>

      {loading && (
        <Flex gap={itemGap} style={{ flexWrap: "wrap" }}>
          {[...new Array(10)].map((_e, i) => (
            <div key={i} style={postSize}>
              <Skeleton.Node active={true} style={{ width: "100%" }} />
            </div>
          ))}
        </Flex>
      )}

      {!loading && posts.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Flex vertical align="center">
              <p style={{ fontWeight: "bold", fontSize: "1rem" }}>{emptyTitle}</p>
              {isYourself && <span style={{ fontSize: "0.8rem", color: "#606060" }}>{emptyContent}</span>}
            </Flex>
          }
        />
      )}

      {!loading && posts.length > 0 && (
        <Flex gap={itemGap} style={{ flexWrap: "wrap" }}>
          {posts.map((post) => (
            <PreviewPost style={postSize} key={post.id} post={post} showManageButton={true} onRemove={handleRemove} />
          ))}
        </Flex>
      )}

      {!loading && showLoadmore && (
        <Flex justify="center" style={{ marginTop: "1rem" }}>
          <Button size="small" shape="round" onClick={handleLoadmore} loading={loadingMore}>
            More
          </Button>
        </Flex>
      )}

      <br></br>
    </>
  );
};

export default ListPost;
