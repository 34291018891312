import { useState, useEffect, useRef, useCallback } from "react";
import { message, Skeleton, Flex, Button } from "antd";
import { getCommentsByPost } from "../../../../../../services/supabaseClient";
import { colors, convertComment } from "../../../../../../utils/service";
import CommentItem from "./CommentItem";
import CommentArea from "./CommentArea";

const PAGE_SIZE = 5;
const commentCountTypes = {
  increament: "increament",
  decreament: "decreament",
};

const Comments = ({ postId, onCommentCountChange }) => {
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [isLoadmore, setIsLoadmore] = useState(false);
  const [showLoadmore, setShowLoadmore] = useState(true);

  const listRef = useRef(null);

  const fetchData = useCallback(
    async (startTime = 0) => {
      try {
        if (startTime === 0) setLoading(true);
        else setIsLoadmore(true);

        const { data, error } = await getCommentsByPost(postId, startTime, PAGE_SIZE);
        if (error) {
          message.error(error.message);
        } else {
          if (data.length > 0) {
            const convertedData = data.map((e) => {
              return convertComment(e);
            });

            setComments((prev) => {
              if (startTime !== 0) {
                return [...prev, ...convertedData];
              } else if (data.length < PAGE_SIZE) {
                setShowLoadmore(false);
              }

              return convertedData;
            });
          } else {
            setShowLoadmore(false);
          }
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        if (startTime === 0) setLoading(false);
        else setIsLoadmore(false);
      }
    },
    [postId]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleLoadMore = () => {
    const startTime = comments.length > 0 ? comments[comments.length - 1].created_at_original : 0;

    fetchData(new Date(startTime).getTime());
  };

  const handleAddComment = (newComments) => {
    const convertedNewComments = convertComment(newComments);
    setComments((prev) => [convertedNewComments, ...prev]);
    onCommentCountChange(commentCountTypes.increament);
    scrollToTop();
  };

  const handleDeleteComment = (id) => {
    const dataAfterDelete = comments.filter((c) => c.id !== id);
    setComments(dataAfterDelete);
    onCommentCountChange(commentCountTypes.decreament);
  };

  const scrollToTop = () => {
    if (listRef.current) {
      listRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {loading && (
        <div style={{ padding: "1.5rem 0.75rem" }}>
          <Skeleton active avatar></Skeleton>
          <Skeleton active avatar></Skeleton>
          <Skeleton active avatar></Skeleton>
          <Skeleton active avatar></Skeleton>
          <Skeleton active avatar></Skeleton>
        </div>
      )}

      <Flex vertical style={{ height: "100%" }}>
        <div style={{ paddingTop: "0.75rem", flexGrow: 1, overflow: "hidden" }}>
          {!loading && comments.length === 0 && (
            <Flex align="center" justify="center" style={{ height: "100%" }}>
              <p style={{ color: "rgb(100 100 100)", fontSize: "0.8rem" }}>Write first comment for this post!</p>
            </Flex>
          )}

          {!loading && comments.length > 0 && (
            <Flex vertical size="large" className="vfra-scroll" ref={listRef}>
              {comments.map((item) => (
                <CommentItem key={item.id} data={item} onDeleteComment={handleDeleteComment} />
              ))}

              {showLoadmore && (
                <Flex justify="start">
                  <Button
                    style={{ fontSize: "0.7rem", color: colors.grayColor, marginLeft: "2.375rem" }}
                    size="small"
                    shape="round"
                    type="text"
                    onClick={handleLoadMore}
                    loading={isLoadmore}
                    iconPosition="end">
                    More comments
                  </Button>
                </Flex>
              )}
            </Flex>
          )}
        </div>

        <CommentArea postId={postId} onAddComment={handleAddComment} />
      </Flex>
    </>
  );
};

export default Comments;
