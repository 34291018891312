import "./index.css";
import { Flex, Skeleton } from "antd";
import useWindowSize from "../../../../../components/hooks/useWindowSize";

const UserLoading = ({ data, ...props }) => {
  const { isMobileView } = useWindowSize();

  const avtSize = isMobileView ? 64 : 80;

  return (
    <Flex
      gap={isMobileView ? 12 : 16}
      style={{ width: isMobileView ? "30vw" : "12vw" }}
      {...props}
      vertical
      align="center">
      <Skeleton.Avatar active={true} size={avtSize} />

      <Skeleton.Input active={true} size="small" />

      <Skeleton.Button active={true} size="small" shape="round" />
    </Flex>
  );
};

export default UserLoading;
