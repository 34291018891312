import { useState, useEffect, useCallback } from "react";
import { Col, Row, Flex, message, Spin } from "antd";
import { classifyDataByName } from "../../../../utils/service";
import { getAllBrandsLite } from "../../../../services/supabaseClient";
import { setItemWithExpiry, getItemWithExpiry, localStorage_fields, expires_in } from "../../../../utils/localstorage";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import BrandItem from "../BrandItem";

const PopularBrands = () => {
  const { isMobileView } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);

  const fetchDataPopularBrands = useCallback(async () => {
    try {
      setLoading(true);
      const { data, error } = await getAllBrandsLite();
      if (error) {
        message.error(error.message);
      } else {
        const converted = classifyDataByName(data, "brand_name");
        setBrands(converted);
        setItemWithExpiry(localStorage_fields.groupBrands, converted, expires_in.week);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const brandsData = getItemWithExpiry(localStorage_fields.groupBrands);

    if (brandsData) {
      setBrands(brandsData);
    } else {
      fetchDataPopularBrands();
    }
  }, [fetchDataPopularBrands]);

  const gutter = isMobileView ? [12, 12] : [24, 24];

  return (
    <>
      {loading && (
        <Flex justify="center" style={{ marginTop: "20%" }}>
          <Spin size="large" />
        </Flex>
      )}

      {!loading && brands.length === 0 && (
        <Flex justify="center" style={{ marginTop: "2rem" }}>
          <p style={{ color: "rgb(100 100 100)" }}>No brand found!</p>
        </Flex>
      )}

      {brands.length > 0 && (
        <Row gutter={gutter} justify="center">
          {brands.map((group) => {
            if (group.designers.length === 0) {
              return null;
            } else {
              return (
                <Col key={group.alphaCode} span={!isMobileView ? 6 : 24}>
                  <Flex vertical gap={"1rem"}>
                    <span
                      style={{
                        lineHeight: "1.4",
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                      }}>
                      {group.alphaCode}
                    </span>
                    {group.designers.map((designer) => {
                      return <BrandItem key={designer.id} designer={designer} />;
                    })}
                  </Flex>
                </Col>
              );
            }
          })}
        </Row>
      )}
    </>
  );
};

export default PopularBrands;
