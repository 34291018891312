import "./index.css";
import React, { useState, useCallback, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Flex, message, Skeleton, Button } from "antd";
import { getCommentsByUser } from "../../../services/supabaseClient";
import { formatTimeshow, countReactions } from "../../../utils/service";
import { pathData } from "../../../utils/routes";
import Comment from "../../../components/common/Comment";
import useWindowSize from "../../../components/hooks/useWindowSize";
import ImageWithLoader from "../../../components/common/ImageWithLoader";

const PAGE_SIZE = 10;

const Reviews = () => {
  const navigate = useNavigate();
  const { isMobileView } = useWindowSize();
  const { userId } = useParams();

  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showLoadmore, setShowLoadmore] = useState(true);

  const fetchData = useCallback(
    async (startTime = 0) => {
      try {
        if (startTime) setLoadingMore(true);
        const { error, data } = await getCommentsByUser(userId, startTime, PAGE_SIZE);
        if (error) {
          message.error(error);
        } else {
          if (data.length === 0) {
            setShowLoadmore(false);
            setComments([]);
          } else {
            const convertedData = data.map((e) => {
              const { reaction_count: likes_count, active_for_user: active_for_user_like } = countReactions(
                e.reactions_metadata,
                "like"
              );
              const { reaction_count: hearts_count, active_for_user: active_for_user_heart } = countReactions(
                e.reactions_metadata,
                "heart"
              );
              const created_at = formatTimeshow(e.created_at);
              return {
                id: e.id,
                user_id: e.user_id,
                comment: e.comment,
                likes_count,
                active_for_user_like,
                hearts_count,
                active_for_user_heart,
                replies_count: e.replies_count,
                user_name: e.user.name,
                user_avatar: e.user.avatar,
                created_at,
                created_at_original: e.created_at,
                perfume_id: e.perfume?.id,
                perfume_name: e.perfume?.name,
                perfume_image_url: e.perfume?.image_url,
              };
            });

            if (startTime !== 0) {
              setComments((prev) => [...prev, ...convertedData]);
            } else {
              setComments(convertedData);

              if (convertedData.length < PAGE_SIZE) setShowLoadmore(false);
            }
          }
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        if (startTime) setLoadingMore(false);
        else setLoading(false);
      }
    },
    [userId]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleLoadmore = () => {
    const startTime = comments.length > 0 ? comments[comments.length - 1].created_at_original : 0;
    const convertedStartTime = startTime === 0 ? startTime : new Date(startTime).getTime();
    fetchData(convertedStartTime);
  };

  const imageWidth = isMobileView ? 50 : 100;

  return (
    <div className="vfra-container vfra-profile-reviews-container">
      {loading &&
        Array.from({
          length: 5,
        }).map((_, i) => (
          <Skeleton
            key={i}
            avatar
            paragraph={{
              rows: isMobileView ? 1 : 2,
            }}
          />
        ))}

      {!loading && comments.length > 0 && (
        <Flex vertical gap={"0.25rem"}>
          {comments.map((item) => (
            <Flex key={item.id} gap={isMobileView ? "0" : "0.75rem"} justify="space-between">
              <Comment data={item} />
              <div style={{ paddingTop: "0.5rem" }}>
                <ImageWithLoader
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`${pathData.perfume}/${item.perfume_id}`)}
                  src={item.perfume_image_url}
                  width={imageWidth}
                  loadingWidth={imageWidth}
                />
              </div>
            </Flex>
          ))}
        </Flex>
      )}

      {showLoadmore && (
        <Flex justify="center" style={{ marginTop: "0.75rem" }}>
          <Button
            style={{ fontSize: "0.8rem" }}
            size="small"
            shape="round"
            onClick={handleLoadmore}
            loading={loadingMore}>
            More
          </Button>
        </Flex>
      )}

      <br></br>
    </div>
  );
};
export default Reviews;
