import "./index.css";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";

import BasicHeader from "./BasicHeader";

const { Footer } = Layout;

const BasicLayout = () => {
  return (
    <Layout>
      <BasicHeader />
      <div className="vfra-basic-content">
        <Outlet />
      </div>

      <Footer style={{ textAlign: "center" }}>Scentub ©{new Date().getFullYear()} All right reserved</Footer>
    </Layout>
  );
};

export default BasicLayout;
