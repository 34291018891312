import { useState, useEffect, useCallback } from "react";
import { Form, Input, Upload, Flex, Button, Select, message, Spin, Image, Row, Col, Collapse } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { search_notes } from "../../../../services/meilisearchNotes";
import { search_brands } from "../../../../services/meilisearchBrands";
import {
  addRequestPerfume,
  getImagePerfumeUploadKey,
  getLogoBrandUploadKey,
  uploadToSignedURL,
  updateRequestPerfume,
  removeRequestPerfume,
} from "../../../../services/supabaseClient";
import { getBase64, imageConverterToJpg } from "../../../../utils/service";
import { countries } from "../../../../utils/data";
import DebouncedSelect from "./DebouncedSelect";
import useWindowSize from "../../../../components/hooks/useWindowSize";

const PerfumeForm = ({ dataPerfume, onSubmitForm, form }) => {
  const { isMobileView } = useWindowSize();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [hasBrand, setHasBrand] = useState(false);

  const resetForm = useCallback(() => {
    form.resetFields();
    setFileList([]);
    setBrandFileList([]);
    setShowAddBrand(false);
    setHasBrand(false);
  }, [form]);

  const initForm = useCallback(() => {
    if (dataPerfume) {
      const newFileList = [
        {
          thumbUrl: dataPerfume.image_url,
          onlyViewFile: true,
        },
      ];
      setFileList(newFileList);

      form.setFieldsValue({
        name: dataPerfume.name,
        image: newFileList,
        description: dataPerfume.description ?? "",
        notes: dataPerfume.notes ?? [],
      });

      if (dataPerfume.brand_id) {
        setShowAddBrand(false);
        setHasBrand(true);

        form.setFieldsValue({
          brand: dataPerfume.brand_id,
        });
      } else {
        setShowAddBrand(true);
        form.setFieldsValue({
          brand_name: dataPerfume.brand_name,
          brand_website: dataPerfume.brand_data?.website,
          brand_country: dataPerfume.brand_data?.country ?? "",
          brand_main_activity: dataPerfume.brand_data?.main_activity ?? "",
          brand_parent_company: dataPerfume.brand_data?.parent_company ?? "",
        });

        const newBrandFileList = [
          {
            thumbUrl: dataPerfume.brand_image_url,
            onlyViewFile: true,
          },
        ];
        setBrandFileList(newBrandFileList);
        form.setFieldsValue({
          brand_logo: newBrandFileList,
        });
      }
    } else {
      resetForm();
    }
  }, [dataPerfume, form, resetForm]);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const handleUploadPerfumeImage = async (id, file) => {
    return new Promise(async (resolve, _reject) => {
      try {
        const { error, data } = await getImagePerfumeUploadKey(id);

        if (error) {
          message.error(error.message);
          resolve(null);
        } else {
          const { path, token } = data;
          const res1 = await uploadToSignedURL("perfume_requests", path, token, file);

          if (res1.error) {
            message.error(res1.error.message);
            resolve(null);
          } else {
            const { path } = res1.data;
            resolve(path);
          }
        }
      } catch (error) {
        message.error(error.message);
        resolve(null);
      }
    });
  };

  const handleUploadBrandLogo = async (id, file) => {
    return new Promise(async (resolve, _reject) => {
      try {
        const { error, data } = await getLogoBrandUploadKey(id);

        if (error) {
          message.error(error.message);
          resolve(null);
        } else {
          const { path, token } = data;
          const res1 = await uploadToSignedURL("perfume_requests", path, token, file);

          if (res1.error) {
            message.error(res1.error.message);
            resolve(null);
          } else {
            const { path } = res1.data;
            resolve(path);
          }
        }
      } catch (error) {
        message.error(error.message);
        resolve(null);
      }
    });
  };

  const handleCleanUpAddPerfumeRequestFail = async (perfumeId) => {
    try {
      const { error } = await removeRequestPerfume(perfumeId);
      if (error) {
        message.error(error.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const onSubmit = async (values) => {
    if (fileList.length === 0) {
      message.warning("Perfumer image is required!");
      return;
    }

    setIsSubmiting(true);
    let notes = [];
    if (values.notes.length > 0 && typeof values.notes[0] === "object") {
      notes = values.notes.map((n) => n.value);
    } else {
      notes = values.notes;
    }

    let payload = {
      name: values.name,
      brand_id: values.brand,
      description: values.description?.trim(),
      notes,
      image_path: "",
    };

    if (showAddBrand) {
      if (brandFileList.length === 0) {
        message.warning("Brand logo is required!");
        return;
      }

      payload = {
        ...payload,
        brand_id: null,
        brand_name: values.brand_name?.trim(),
        brand_image_path: "",
        brand_data: {
          website: values.brand_website?.trim(),
          country: values.brand_country,
          main_activity: values.brand_main_activity?.trim(),
          parent_company: values.brand_parent_company?.trim(),
        },
      };
    }

    try {
      let res = null;
      if (dataPerfume) {
        delete payload.image_path;
        delete payload.brand_image_path;

        res = await updateRequestPerfume(dataPerfume.id, payload);
      } else {
        res = await addRequestPerfume(payload);
      }

      if (res.error) {
        message.error(res.error.message);
      } else {
        let id = null;
        if (res.data?.length > 0) {
          id = res.data[0].id;
        } else {
          id = res.data.id;
        }

        let pathImage = null;
        if (!dataPerfume || !fileList[0].hasOwnProperty("onlyViewFile")) {
          // convert image to jpg
          const convertRes = await imageConverterToJpg(fileList[0].originFileObj, 2);

          if (convertRes.error) {
            message.error(convertRes.error.message);
          } else {
            pathImage = await handleUploadPerfumeImage(id, convertRes.data);
            if (!pathImage) {
              handleCleanUpAddPerfumeRequestFail(id);
              message.error("Error uploading perfume image, please try again!");
              setIsSubmiting(false);
              return;
            }
          }
        }

        let pathBrandLogo = null;
        if (showAddBrand && !brandFileList[0].hasOwnProperty("onlyViewFile")) {
          // convert image to jpg
          const convertRes = await imageConverterToJpg(brandFileList[0].originFileObj, 2);

          if (convertRes.error) {
            message.error(convertRes.error.message);
          } else {
            pathBrandLogo = await handleUploadBrandLogo(id, convertRes.data);
            if (!pathBrandLogo) {
              handleCleanUpAddPerfumeRequestFail(id);
              message.error("Error uploading brand logo, please try again!");
              setIsSubmiting(false);
              return;
            }
          }
        }

        let payloadUpdate = null;

        if (pathImage) {
          payloadUpdate = {
            image_path: pathImage,
          };
        }

        if (pathBrandLogo) {
          payloadUpdate = { ...payloadUpdate, brand_image_path: pathBrandLogo };
        }

        if (payloadUpdate) {
          const res = await updateRequestPerfume(id, payloadUpdate);
          if (res.error) {
            message.error(res.error.message);
          } else {
            message.success("Perfume was saved successfully");
            resetForm();
            onSubmitForm();
          }
        } else {
          message.success("Perfume was updated successfully");
          resetForm();
          onSubmitForm();
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmiting(false);
    }
  };

  //handle upload image
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return false;
  };

  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handlePreview = async (file) => {
    if (file.onlyViewFile) {
      setPreviewImage(file.thumbUrl);
    } else {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
    }

    setPreviewOpen(true);
  };

  //brand form
  const countryOptions = countries.map((c) => {
    return {
      ...c,
      label: c.name,
      value: c.name,
    };
  });

  const [showAddBrand, setShowAddBrand] = useState(false);
  const [brandFileList, setBrandFileList] = useState([]);
  const [brandPreviewOpen, setBrandPreviewOpen] = useState(false);
  const [brandPreviewImage, setBrandPreviewImage] = useState("");

  const handleBrandFileChange = ({ fileList: newFileList }) => {
    setBrandFileList(newFileList);
  };

  const handleBrandPreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setBrandPreviewImage(file.url || file.preview);
    setBrandPreviewOpen(true);
  };

  const handleOpenAddBrand = () => {
    form.setFieldsValue({
      brand: null,
    });

    setHasBrand(false);
    setShowAddBrand(true);
  };

  const activeKey = showAddBrand ? ["1"] : [];

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 18,
    },
  };

  return (
    <>
      <Spin spinning={isSubmiting}>
        <Form {...layout} form={form} name="control-hooks" onFinish={onSubmit}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input placeholder="enter perfume name" />
          </Form.Item>

          <Form.Item
            label="Image"
            name="image"
            rules={[
              {
                required: true,
              },
            ]}>
            <Upload
              fileList={fileList}
              beforeUpload={beforeUpload}
              onChange={handleFileChange}
              onPreview={handlePreview}
              listType="picture-card"
              maxCount={1}>
              <button
                style={{
                  border: 0,
                  background: "none",
                }}
                type="button">
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}></div>
              </button>
            </Upload>
          </Form.Item>

          {previewImage && (
            <Image
              wrapperStyle={{
                display: "none",
              }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(""),
              }}
              src={previewImage}
            />
          )}

          <Form.Item
            name="brand"
            label="Brand"
            rules={[
              {
                required: !showAddBrand,
              },
            ]}>
            <DebouncedSelect
              fieldValue="id"
              fieldLabel="brand_name"
              fieldImage="logo_url"
              placeholder="select a brand"
              searchFunction={search_brands}
              hasLoadmore={false}
              onAddItem={handleOpenAddBrand}
              onChange={(newValue) => {
                if (newValue) {
                  setHasBrand(true);
                } else {
                  setHasBrand(false);
                }

                form.setFieldsValue({
                  brand: newValue?.value ?? null,
                });
              }}
            />
          </Form.Item>

          {!hasBrand && (
            <Row style={{ marginBottom: "24px" }}>
              <Col span={isMobileView ? 24 : 18} offset={isMobileView ? 0 : 5}>
                <Collapse
                  size="small"
                  activeKey={activeKey}
                  onChange={() => setShowAddBrand((prev) => !prev)}
                  items={[
                    {
                      key: "1",
                      label: "Add brand",
                      children: (
                        <>
                          <Form.Item
                            name="brand_name"
                            label="Brand name"
                            rules={[
                              {
                                required: showAddBrand,
                              },
                            ]}>
                            <Input placeholder="enter brand name" />
                          </Form.Item>

                          <Form.Item
                            name="brand_logo"
                            label="Brand logo"
                            rules={[
                              {
                                required: showAddBrand,
                              },
                            ]}>
                            <Upload
                              fileList={brandFileList}
                              beforeUpload={beforeUpload}
                              onChange={handleBrandFileChange}
                              onPreview={handleBrandPreview}
                              listType="picture-card"
                              maxCount={1}>
                              <button
                                style={{
                                  border: 0,
                                  background: "none",
                                }}
                                type="button">
                                <PlusOutlined />
                                <div
                                  style={{
                                    marginTop: 8,
                                  }}></div>
                              </button>
                            </Upload>
                          </Form.Item>

                          {brandPreviewImage && (
                            <Image
                              wrapperStyle={{
                                display: "none",
                              }}
                              preview={{
                                visible: brandPreviewOpen,
                                onVisibleChange: (visible) => setBrandPreviewOpen(visible),
                                afterOpenChange: (visible) => !visible && setBrandPreviewImage(""),
                              }}
                              src={brandPreviewImage}
                            />
                          )}

                          <Form.Item
                            name="brand_website"
                            label="Website"
                            rules={[
                              {
                                required: showAddBrand,
                              },
                            ]}>
                            <Input placeholder="enter website" />
                          </Form.Item>

                          <Form.Item name="brand_country" label="Country">
                            <Select
                              showSearch
                              placeholder="select a country"
                              filterOption={(input, option) =>
                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                              }
                              options={countryOptions}
                              optionRender={(option) => (
                                <Flex align="center">
                                  <img
                                    height={isMobileView ? 16 : 20}
                                    width={isMobileView ? 24 : 30}
                                    alt=""
                                    src={option.data.img}
                                    style={{ marginRight: "12px" }}
                                  />
                                  {option.data.name}
                                </Flex>
                              )}
                            />
                          </Form.Item>

                          <Form.Item name="brand_main_activity" label="Main activity">
                            <Input placeholder="enter main activity" />
                          </Form.Item>

                          <Form.Item name="brand_parent_company" label="Parent company">
                            <Input placeholder="enter parent company" />
                          </Form.Item>
                        </>
                      ),
                    },
                  ]}
                />
              </Col>
            </Row>
          )}

          <Form.Item name="description" label="Description">
            <Input.TextArea
              autoSize={{
                minRows: 6,
                maxRows: 10,
              }}
              placeholder="enter perfume description"
            />
          </Form.Item>

          <Form.Item name="notes" label="Notes">
            <DebouncedSelect
              mode="multiple"
              searchFunction={search_notes}
              fieldValue="id"
              fieldLabel="name"
              fieldImage="image_url"
              placeholder="select multiple notes"
            />
          </Form.Item>

          <Flex justify="end">
            <Button type="primary" htmlType="submit">
              {dataPerfume ? "Update" : "Add"}
            </Button>
          </Flex>
        </Form>
      </Spin>
    </>
  );
};
export default PerfumeForm;
