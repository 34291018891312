import "./index.css";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Flex, Popover, Button, Row, Col, Drawer, Modal, Input, Divider } from "antd";
import {
  UserOutlined,
  PlusOutlined,
  MenuOutlined,
  CloseOutlined,
  ArrowLeftOutlined,
  SearchOutlined,
  // ClockCircleOutlined,
  LogoutOutlined,
  LoginOutlined,
  // ShoppingCartOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../contexts/AuthContext";
import { useSearch } from "../../contexts/SearchContext";
// import { useCart } from "../../contexts/CartContext";
import { localStorage_fields } from "../../../utils/localstorage";
import { pathData, pathMenus } from "../../../utils/routes";
import useWindowSize from "../../hooks/useWindowSize";
import CreatePost from "../../common/CreatePost";
import logoWeb from "../../../assets/logo-web.png";
import logoMobile from "../../../assets/logo-mobile.png";
import MultiSearch from "../../../pages/Home";
import ImageWithHandler from "../../common/ImageWithHandler";
// import CartContent from "../../common/CartContent";

const { Search } = Input;

const getMenuPath = (pathname) => {
  if (pathname === "/" || pathname.includes("/home"))
    return {
      currentPage: pathData.home,
      currentMenu: "Scentub",
    };

  const temp = pathMenus.find((e) => pathname.includes(e.path));
  return {
    currentPage: temp ? temp.path : "",
    currentMenu: temp ? temp.label : "",
  };
};

// const historiesSearchData = Array.from({ length: 10 }).map((_, i) => `search value ${i}`);
// const historiesSearchData = [];

const HeaderLayout = () => {
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { currentPage, currentMenu } = getMenuPath(pathname);
  const { user, openLoginModal, logout } = useAuth();

  const { searchValue, onSearchChange, showSearchMobile, setShowSearchMobile } = useSearch();
  // const { cartContext } = useCart();

  const handleOpenNewTab = (path) => {
    const currentUrl = window.location.origin;

    window.open(`${currentUrl}/${path}`, "_blank");
  };

  const onClickProfileMenu = (key) => {
    setOpen(false);
    if (key === "profile") navigate(`${pathData.profile}/${user.user_id}`);
    else if (key === "logout") logout();
  };

  const clearNewfeed = () => {
    localStorage.removeItem(localStorage_fields.posts);
    localStorage.removeItem(localStorage_fields.lastIndexPost);
  };

  // mobile menu
  const [open, setOpen] = useState(false);

  const showMobileMenu = () => {
    setOpen(true);
  };

  const onCloseMobileMenu = () => {
    setOpen(false);
  };

  const handleClickMobileMenu = (menu) => {
    setOpen(false);
    if (currentPage === pathData.explore) clearNewfeed();
    navigate(menu);
  };

  const handleReloadNewFeed = () => {
    if (currentPage === pathData.explore) {
      clearNewfeed();
      navigate(pathData.explore);
    }
  };

  // hanle search mobile
  const [searchValueLocal, setSearchValueLocal] = useState(searchValue);
  // const [openSearchMenu, setOpenSearchMenu] = useState(false);
  // const [openHistoryPopup, setOpenHistoryPopup] = useState(false);
  // const [searchHistories, setSearchHistories] = useState(historiesSearchData);

  // const deleteSearchHistory = (index) => {
  //   setSearchHistories((prev) => prev.filter((_e, i) => i !== index));
  // };

  const handleSearchMobile = (value) => {
    setSearchValueLocal(value);
    onSearchChange(value);

    // setOpenHistoryPopup(false);
  };

  const handleCloseSearchMobileMenu = () => {
    // setOpenHistoryPopup(false);
    // setSearchValueLocal(null);
    // onSearchChange(null);
    setShowSearchMobile(false);
  };

  // handle create post
  const [showCreatePost, setShowCreatePost] = useState(false);

  const handleCreatePost = () => {
    setShowCreatePost(false);
  };

  // Go back to the previous page
  const handleBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(pathData.home);
    }
  };

  const needHideBackgroundOrTextWhite =
    currentPage === pathData.explore || currentPage === pathData.searchDetail || currentPage === pathData.postDetail;

  const showIconMenu =
    currentPage === pathData.home ||
    currentPage === pathData.search ||
    currentPage === pathData.brand ||
    currentPage === pathData.perfume;

  const showCreateBtn = user && currentPage === pathData.explore;

  const goToHome = () => {
    onSearchChange(null);
    navigate(pathData.home);
  };

  // handle cart
  // const [openCart, setOpenCart] = useState(false);

  if (isMobileView) {
    return (
      <>
        {currentPage === pathData.home && !showSearchMobile && (
          <>
            <Button
              style={{ position: "fixed", zIndex: 1000, top: "1.5vh", left: "0.4rem" }}
              onClick={showMobileMenu}
              type="text"
              shape="circle"
              icon={<MenuOutlined />}
            />

            {/* <Badge
              style={{ position: "fixed", zIndex: 1000, top: "1.5vh", right: "2.8rem" }}
              offset={[-5, 5]}
              size="small"
              count={cartContext ? cartContext.length : 0}
              status="default"
              onClick={() => setOpenCart(true)}>
              <Button
                style={{ position: "fixed", zIndex: 1000, top: "1.5vh", right: "2.4rem" }}
                className={needHideBackgroundOrTextWhite ? "text-bold-white" : ""}
                shape="circle"
                type="text"
                icon={<ShoppingCartOutlined style={{ fontSize: "1.2rem" }} />}
              />
            </Badge> */}

            <Button
              style={{ position: "fixed", zIndex: 1000, top: "1.5vh", right: "0.4rem" }}
              onClick={() => setShowSearchMobile(true)}
              type="text"
              shape="circle"
              icon={<SearchOutlined />}
            />

            {/* <Drawer
              width={"100%"}
              getContainer={document.getElementById("root")}
              title={
                <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Cart{cartContext ? `(${cartContext.length})` : ""}
                </span>
              }
              onClose={() => setOpenCart(false)}
              open={openCart}>
              {isMobileView && <br></br>}
              <CartContent />
            </Drawer> */}
          </>
        )}

        {currentPage !== pathData.home && (
          <>
            <Row
              style={{
                background: needHideBackgroundOrTextWhite ? "transparent" : "var(--white-color)",
              }}
              className={`vfra-header-container ${needHideBackgroundOrTextWhite ? "text-bold-white" : ""}`}>
              <Col span={8}>
                {showIconMenu && (
                  <Button
                    className={needHideBackgroundOrTextWhite ? "text-bold-white" : ""}
                    onClick={showMobileMenu}
                    type="text"
                    shape="circle"
                    icon={<MenuOutlined />}
                  />
                )}

                {!showIconMenu && (
                  <Button
                    className={`${needHideBackgroundOrTextWhite ? "text-bold-white" : ""}`}
                    onClick={handleBack}
                    type="text"
                    shape="circle"
                    icon={<ArrowLeftOutlined />}
                  />
                )}
              </Col>

              <Col span={8} align="center">
                <Flex gap={6} justify="center" align="center" style={{ height: "100%" }}>
                  <span style={{ fontSize: "1rem", fontWeight: "600" }} onClick={handleReloadNewFeed}>
                    {currentMenu}
                  </span>
                  {showCreateBtn && (
                    <Button
                      size="small"
                      onClick={() => setShowCreatePost(true)}
                      shape="circle"
                      icon={<PlusOutlined />}></Button>
                  )}
                </Flex>
              </Col>
              <Col span={8}>
                <Flex gap={6} justify="end">
                  {/* <Badge
                    offset={[-5, 5]}
                    size="small"
                    count={cartContext ? cartContext.length : 0}
                    status="default"
                    onClick={() => setOpenCart(true)}>
                    <Button
                      className={needHideBackgroundOrTextWhite ? "text-bold-white" : ""}
                      shape="circle"
                      type="text"
                      icon={<ShoppingCartOutlined style={{ fontSize: "1.2rem" }} />}
                    />
                  </Badge> */}

                  <Button
                    className={needHideBackgroundOrTextWhite ? "text-bold-white" : ""}
                    onClick={() => setShowSearchMobile(true)}
                    type="text"
                    shape="circle"
                    icon={<SearchOutlined style={{ fontSize: "1.2rem" }} />}
                  />
                </Flex>
              </Col>
            </Row>

            <Modal width="100%" centered open={showCreatePost} closable={false} footer={null} getContainer={false}>
              <CreatePost onClose={() => setShowCreatePost(false)} onCreate={handleCreatePost} />
            </Modal>
          </>
        )}

        {showIconMenu && (
          <Drawer
            getContainer={document.getElementById("root")}
            title={
              <Flex align="center" justify="space-between">
                <Flex align="end" gap={3}>
                  <img
                    style={{ height: "2.4rem" }}
                    onClick={() => navigate(pathData.explore)}
                    src={logoMobile}
                    alt=""
                  />
                  <img
                    className={currentPage === pathData.home ? "vfra-header-logo-text-active" : ""}
                    style={{ cursor: "pointer", width: "6rem" }}
                    onClick={goToHome}
                    src={logoWeb}
                    alt=""
                  />
                </Flex>

                <Button onClick={onCloseMobileMenu} type="text" shape="circle" icon={<CloseOutlined />} />
              </Flex>
            }
            placement="left"
            open={open}
            closable={false}
            onClose={onCloseMobileMenu}
            width="60%">
            <Flex vertical gap={24} style={{ paddingTop: "1rem" }}>
              {pathMenus
                .filter((e) => e.visible)
                .map((item) => (
                  <span
                    key={item.id}
                    className={currentPage === item.path ? "vfra-menu-item vfra-menu-active" : "vfra-menu-item"}
                    onClick={() => handleClickMobileMenu(item.path)}>
                    {item.label}
                  </span>
                ))}

              {user && (
                <>
                  <Flex align="center" gap={6}>
                    <div>
                      <ImageWithHandler
                        title={user.vfra_user && user.vfra_user?.name ? user.vfra_user?.name[0] : user.name[0]}
                        size="large"
                        src={user.vfra_user?.avatar ?? user.avatar_url}
                      />
                    </div>
                    <span style={{ fontWeight: "bold" }}>{user.vfra_user?.name ?? user.name}</span>
                  </Flex>

                  <span className="vfra-menu-item" onClick={() => onClickProfileMenu("profile")}>
                    Profile
                  </span>

                  <span className="vfra-menu-item" onClick={() => onClickProfileMenu("logout")}>
                    Logout
                  </span>
                </>
              )}

              {!user && (
                <span className="vfra-menu-item" onClick={openLoginModal}>
                  Login
                </span>
              )}

              <Flex gap={6} align="center">
                <span onClick={() => handleOpenNewTab("policy")} className="vfra-header-sub-menu-item-btn">
                  Privacy policies
                </span>
                <span aria-hidden="true">·</span>
                <span onClick={() => handleOpenNewTab("terms")} className="vfra-header-sub-menu-item-btn">
                  Terms
                </span>
              </Flex>
            </Flex>
            <Divider>
              <span className="vfra-header-sub-menu-item">Scentub © {new Date().getFullYear()}</span>
            </Divider>
          </Drawer>
        )}

        <Drawer
          getContainer={document.getElementById("root")}
          title={
            <Flex align="center" justify="space-between" gap={6}>
              {/* <Popover
                placement="bottom"
                title={null}
                open={openHistoryPopup}
                content={
                  <Flex vertical gap={12} style={{ padding: "0 3px" }}>
                    {searchHistories.length > 0 &&
                      searchHistories.map((e, index) => (
                        <Flex key={index} gap={12} justify="space-between" align="center">
                          <ClockCircleOutlined />
                          <Flex
                            className="vfra-search-history-item"
                            justify="start"
                            flex={1}
                            onClick={() => handleSearchMobile(e)}>
                            <p style={{ fontSize: "1.2rem", fontWeight: "500" }}>{e}</p>
                          </Flex>
                          <Button type="text" shape="circle" onClick={() => deleteSearchHistory(index)}>
                            <CloseOutlined />
                          </Button>
                        </Flex>
                      ))}
                  </Flex>
                }>
                <Input
                  allowClear
                  size="large"
                  className="vfra-search-main-mobile"
                  value={searchValueLocal}
                  onFocus={() => setOpenHistoryPopup(true)}
                  onChange={(e) => setSearchValueLocal(e.target.value)}
                  onPressEnter={(e) => handleSearchMobile(e.target.value)}
                  addonBefore={<SearchOutlined />}
                  placeholder="Search posts, perfumes, brands..."
                />
              </Popover> */}

              <Input
                allowClear
                size="large"
                className="vfra-search-main-mobile"
                value={searchValueLocal}
                // onFocus={() => setOpenHistoryPopup(true)}
                onChange={(e) => setSearchValueLocal(e.target.value)}
                onPressEnter={(e) => handleSearchMobile(e.target.value)}
                addonBefore={<SearchOutlined />}
                placeholder="Search posts, perfumes, brands..."
              />

              <Button
                style={{ marginRight: "-6px" }}
                onClick={handleCloseSearchMobileMenu}
                type="text"
                shape="circle"
                icon={<CloseOutlined />}
              />
            </Flex>
          }
          placement="right"
          open={showSearchMobile}
          closable={false}
          width="100%">
          <MultiSearch isMobileSearch={true} />
        </Drawer>
      </>
    );
  } else {
    return (
      <>
        <Row className="vfra-header-container" align="middle">
          <Col span={6}>
            <Flex align="center" gap={12}>
              <img
                className={currentPage === pathData.home ? "vfra-header-logo-text-active" : ""}
                style={{ cursor: "pointer", width: "6rem" }}
                onClick={goToHome}
                src={logoWeb}
                alt=""
              />
              <div
                className={`vfra-header-menu-btn ${
                  currentPage === pathData.explore ? "vfra-header-menu-btn-active" : ""
                }`}
                onClick={() => navigate(`${pathData.explore}`)}>
                <span>Explore</span>
              </div>
            </Flex>
          </Col>

          <Col span={12}>
            <Flex justify="center">
              <Search
                allowClear
                size="large"
                className="vfra-search-main"
                placeholder="Search posts, perfumes, brands..."
                defaultValue={searchValue}
                onSearch={onSearchChange}
              />
            </Flex>
          </Col>

          <Col span={6} justify="end">
            <Flex justify="end" align="center" gap={"1rem"}>
              {showCreateBtn && (
                <Button
                  title="create post"
                  onClick={() => setShowCreatePost(true)}
                  shape="round"
                  icon={<PlusOutlined />}>
                  Create
                </Button>
              )}

              {/* <Badge count={cartContext ? cartContext.length : 0} status="default" onClick={() => setOpenCart(true)}>
                <Button shape="circle" type="text" icon={<ShoppingCartOutlined style={{ fontSize: "1.5rem" }} />} />
              </Badge> */}

              {/* <Drawer
                width={"30%"}
                getContainer={document.getElementById("root")}
                title={
                  <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                    Cart{cartContext ? `(${cartContext.length})` : ""}
                  </span>
                }
                onClose={() => setOpenCart(false)}
                open={openCart}>
                <CartContent />
              </Drawer> */}

              <Popover
                placement="bottomRight"
                content={
                  <Flex vertical gap={12}>
                    {user && (
                      <Flex vertical>
                        <Flex align="center" gap={12}>
                          <div>
                            <ImageWithHandler
                              title={user.vfra_user && user.vfra_user?.name ? user.vfra_user?.name[0] : user.name[0]}
                              size="large"
                              src={user.vfra_user?.avatar ?? user.avatar_url}
                            />
                          </div>
                          <h3 className="one-line-ellipsis">{user.vfra_user?.name ?? user.name}</h3>
                        </Flex>
                        <Button
                          onClick={() => navigate(`${pathData.profile}/${user.user_id}`)}
                          shape="round"
                          size="small"
                          style={{ fontWeight: "500" }}>
                          Profile
                        </Button>
                      </Flex>
                    )}

                    {user && (
                      <Flex onClick={() => logout()} align="center" className="vfra-header-menu-item" gap={6}>
                        <LogoutOutlined />
                        <span>Logout</span>
                      </Flex>
                    )}

                    {!user && (
                      <Flex onClick={openLoginModal} align="center" className="vfra-header-menu-item" gap={6}>
                        <LoginOutlined />
                        <span>Login</span>
                      </Flex>
                    )}

                    <Flex gap={6} align="center">
                      <span onClick={() => handleOpenNewTab("policy")} className="vfra-header-sub-menu-item-btn">
                        Privacy policies
                      </span>
                      <span aria-hidden="true">·</span>
                      <span onClick={() => handleOpenNewTab("terms")} className="vfra-header-sub-menu-item-btn">
                        Terms
                      </span>
                      <span aria-hidden="true">·</span>
                      <span className="vfra-header-sub-menu-item">Scentub © {new Date().getFullYear()}</span>
                    </Flex>
                  </Flex>
                }
                title={null}
                trigger="click">
                <Flex align="center" gap={12} style={{ padding: "6px 0" }}>
                  {user && (
                    <div>
                      <ImageWithHandler
                        style={{ cursor: "pointer" }}
                        title={user.vfra_user && user.vfra_user?.name ? user.vfra_user?.name[0] : user.name[0]}
                        size="large"
                        src={user.vfra_user?.avatar ?? user.avatar_url}
                      />
                    </div>
                  )}

                  {!user && (
                    <Button
                      shape="round"
                      icon={<UserOutlined style={{ fontSize: "1rem" }} />}
                      style={{ fontWeight: "500" }}>
                      Sign in
                    </Button>
                  )}
                </Flex>
              </Popover>
            </Flex>
          </Col>
        </Row>

        <Modal width="70%" centered open={showCreatePost} closable={false} footer={null} getContainer={false}>
          <CreatePost onClose={() => setShowCreatePost(false)} onCreate={handleCreatePost} />
        </Modal>
      </>
    );
  }
};

export default HeaderLayout;
