import "./index.css";
import { Flex } from "antd";
import CustomeSvgIcon from "../../../../CustomSvgIcon";
import useWindowSize from "../../../../../hooks/useWindowSize";

const getClass = (isMobileView, showComments, active, id) => {
  let btnClass = "vfra-post-action-btn";
  let iconClass = "vfra-post-action-icon";
  let badgeClass = "vfra-post-action-badge";

  if (isMobileView) {
    if (active) {
      if (id === "save") iconClass += " vfra-post-action-btn-mobile-save-active";
      else iconClass += " vfra-post-action-btn-mobile-love-active";
    }
  } else {
    if (showComments && active) {
      btnClass += " vfra-post-action-btn-comment-active";
      iconClass += " vfra-post-action-icon-comment-active";
      badgeClass += " vfra-post-action-badge-comment";
    } else if (showComments) {
      btnClass += " vfra-post-action-btn-comment";
      iconClass += " vfra-post-action-icon-comment";
      badgeClass += " vfra-post-action-badge-comment";
    } else if (active) {
      btnClass += " vfra-post-action-btn-active";
      iconClass += " vfra-post-action-icon-active";
    }
  }

  return { btnClass, iconClass, badgeClass };
};

const ActionBtn = ({ id, title, count = 0, icon, image, showComments, active, onClickBtn, ...prop }) => {
  const { isMobileView } = useWindowSize();
  const { btnClass, iconClass, badgeClass } = getClass(isMobileView, showComments, active, id);

  return (
    <Flex vertical justify="center" align="center" gap={4}>
      <Flex className={btnClass} justify="center" align="center" onClick={onClickBtn}>
        {!image && <CustomeSvgIcon {...prop} className={iconClass} icon={icon} />}
        {image && <img {...prop} alt="" src={image} />}
      </Flex>
      {title && (
        <span style={{ fontSize: isMobileView ? "0.9rem" : "0.7rem" }} className={badgeClass}>
          {title}
        </span>
      )}
      {!title && (
        <span style={{ color: count === 0 ? "transparent" : "" }} className={badgeClass}>
          {count}
        </span>
      )}
    </Flex>
  );
};

export default ActionBtn;
