import React, { createContext, useState, useContext } from "react";
import { getItemWithoutExpiry, localStorage_fields } from "../../utils/localstorage";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartContext, setCartContext] = useState(getItemWithoutExpiry(localStorage_fields.cart));

  const onChangeCartContext = (value) => {
    setCartContext(value);
    localStorage.setItem(localStorage_fields.cart, JSON.stringify(value));
  };

  return (
    <CartContext.Provider
      value={{
        cartContext,
        onChangeCartContext,
      }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
