import "./index.css";
import { Row, Col, Flex } from "antd";
import useWindowSize from "../../components/hooks/useWindowSize";

const Terms = () => {
  const { isMobileView } = useWindowSize();
  return (
    <Row>
      <Col span={isMobileView ? 24 : 16} offset={isMobileView ? 0 : 4} className="vfra-terms-container">
        <h1 style={{ fontSize: "3rem" }}>Terms of Service</h1>
        <Flex vertical>
          <i>Last updated: November 2024</i>
          <span>General Terms - All Users</span>
        </Flex>
        <h2>1. Your Relationship With Us</h2>
        <p>
          Welcome to Scentub (the “Platform”), which is provided by Laristo LTD. or one of its affiliates
          (“Scentub”,“we”or “us”). You are reading the terms of service (the “Terms”), which govern the relationship and
          serve as an agreement between you and us and set forth the terms and conditions by which you may access and
          use the Platform and our related websites, services, applications, products and content (collectively, the
          “Services”). Our Services are provided for private, non-commercial use. For purposes of these Terms, “you” and
          “your” means you as the user of the Services. The Terms form a legally binding agreement between you and us.
          Please take the time to read them carefully.
        </p>
        <h2>2. Accepting the Terms</h2>
        <p>
          By accessing or using our Services, you confirm that you can form a binding contract with Scentub, that you
          accept these Terms and that you agree to comply with them. Your access to and use of our Services is also
          subject to our Privacy Policy, the terms of which can be found directly on the Platform, or where the Platform
          is made available for download, on your mobile device's applicable app store, and are incorporated herein by
          reference. By using the Services, you consent to the terms of the Privacy Policy. If you are accessing or
          using the Services on behalf of a business or entity, then (a) “you” and “your” includes you and that business
          or entity, (b) you represent and warrant that you are an authorized representative of the business or entity
          with the authority to bind the entity to these Terms, and that you agree to these Terms on the entity's
          behalf, and (c) your business or entity is legally and financially responsible for your access or use of the
          Services as well as for the access or use of your account by others affiliated with your entity, including any
          employees, agents or contractors. You can accept the Terms by accessing or using our Services. You understand
          and agree that we will treat your access or use of the Services as acceptance of the Terms from that point
          onwards. You should print off or save a local copy of the Terms for your records.
        </p>
        <h2>3. Changes to the Terms</h2>
        <p>
          We amend these Terms from time to time, for instance when we update the functionality of our Services, when we
          combine multiple apps or services operated by us or our affiliates into a single combined service or app, or
          when there are regulatory changes. We will use commercially reasonable efforts to generally notify all users
          of any material changes to these Terms, such as through a notice on our Platform, however, you should look at
          the Terms regularly to check for such changes. We will also update the “Last Updated” date at the top of these
          Terms, which reflect the effective date of such Terms. Your continued access or use of the Services after the
          date of the new Terms constitutes your acceptance of the new Terms. If you do not agree to the new Terms, you
          must stop accessing or using the Services.
        </p>
        <h2>4. Your Access to and Use of Our Services</h2>
        <p>
          Your access to and use of the Services is subject to these Terms and all applicable laws and regulations. You
          may not:
          <ul>
            <li>Access or use the Services if you are not fully able and legally competent to agree to these Terms</li>
            <li>
              Make unauthorised copies, modify, adapt, translate, reverse engineer, disassemble, decompile or create any
              derivative works of the Services or any content included therein, including any files, tables or
              documentation (or any portion thereof) or determine or attempt to determine any source code, algorithms,
              methods or techniques embodied by the Services or any derivative works thereof
            </li>
            <li>
              Distribute, license, transfer, or sell, in whole or in part, any of the Services or any derivative works
              thereof
            </li>
            <li>
              Market, rent or lease the Services for a fee or charge, or use the Services to advertise or perform any
              commercial solicitation
            </li>
            <li>
              Use the Services, without our express written consent, for any commercial or unauthorized purpose,
              including communicating or facilitating any commercial advertisement or solicitation or spamming;
            </li>
            <li>
              Interfere with or attempt to interfere with the proper working of the Services, disrupt our website or any
              networks connected to the Services, or bypass any measures we may use to prevent or restrict access to the
              Services
            </li>
            <li>
              Incorporate the Services or any portion thereof into any other program or product. In such case, we
              reserve the right to refuse service, terminate accounts or limit access to the Services in our sole
              discretion
            </li>
            <li>Use automated scripts to collect information from or otherwise interact with the Services</li>
            <li>
              Impersonate any person or entity, or falsely state or otherwise misrepresent you or your affiliation with
              any person or entity, including giving the impression that any content you upload, post, transmit,
              distribute or otherwise make available emanates from the Services
            </li>
            <li>
              Intimidate or harass another, or promote sexually explicit material, violence or discrimination based on
              race, sex, religion, nationality, disability, sexual orientation or age
            </li>
            <li>
              Use or attempt to use another's account, service or system without authorisation from Scentub, or create a
              false identity on the Services
            </li>
            <li>
              Use the Services in a manner that may create a conflict of interest or undermine the purposes of the
              Services, such as trading reviews with other users or writing or soliciting fake reviews
            </li>
            <li>
              Use the Services to upload, transmit, distribute, store or otherwise make available in any way: files that
              contain viruses, trojans, worms, logic bombs or other material that is malicious or technologically
              harmful; any unsolicited or unauthorised advertising, solicitations, promotional materials, “junk mail,”
              “spam,” “chain letters,” “pyramid schemes,” or any other prohibited form of solicitation; any private
              information of any third party, including addresses, phone numbers, email addresses, number and feature in
              the personal identity document (e.g., National Insurance numbers, passport numbers) or credit card
              numbers; any material which does or may infringe any copyright, trade mark or other intellectual property
              or privacy rights of any other person; any material which is defamatory of any person, obscene, offensive,
              pornographic, hateful or inflammatory; any material that would constitute, encourage or provide
              instructions for a criminal offence, dangerous activities or self-harm; any material that is deliberately
              designed to provoke or antagonise people, especially trolling and bullying, or is intended to harass,
              harm, hurt, scare, distress, embarrass or upset people; any material that contains a threat of any kind,
              including threats of physical violence; any material that is racist or discriminatory, including
              discrimination on the basis of someone's race, religion, age, gender, disability or sexuality
            </li>
            <ul>
              <li>
                Any answers, responses, comments, opinions, analysis or recommendations that you are not properly
                licensed or otherwise qualified to provide
              </li>
              <li>
                Material that, in the sole judgment of Scentub, is objectionable or which restricts or inhibits any
                other person from using the Services, or which may expose Scentub, the Services or its users to any harm
                or liability of any type
              </li>
            </ul>
          </ul>
          In addition to the above, your access to and use of the Services must, at all times, be compliant with our
          Community Guidelines. We reserve the right, at any time and without prior notice, to remove or disable access
          to content at our discretion for any reason or no reason. Some of the reasons we may remove or disable access
          to content may include finding the content objectionable, in violation of these Terms or our Community
          Guidelines, or otherwise harmful to the Services or our users. Our automated systems analyze your content to
          provide you personally relevant product features, such as customized search results, tailored advertising, and
          spam and malware detection. This analysis occurs as the content is sent, received, and when it is stored.
        </p>
        <h2>5. Intellectual Property Rights</h2>
        <p>
          We respect intellectual property rights and ask you to do the same. As a condition of your access to and use
          of the Services, you agree not to use the Services to infringe on any intellectual property rights. We reserve
          the right, with or without notice, at any time and in our sole discretion to block access to and/or terminate
          the accounts of any user who infringes or is alleged to infringe any copyrights or other intellectual property
          rights.
        </p>
        <h2>6. User-Generated Content</h2>
        <p>
          Users of the Services may be permitted to upload, post or transmit (such as via a stream) or otherwise make
          available content through the Services including, without limitation, any text, photographs, user videos,
          sound recordings and the musical works embodied therein, including videos that incorporate locally stored
          sound recordings from your personal music library and ambient noise (“User Content”). Users of the Services
          may also extract all or any portion of User Content created by another user to produce additional User
          Content, including collaborative User Content with other users, that combine and intersperse User Content
          generated by more than one user. Users of the Services may also overlay music, graphics, stickers, and other
          elements provided by Scentub (“Scentub Elements”) onto this User Content and transmit this User Content
          through the Services. The information and materials in the User Content, including User Content that includes
          Scentub Elements, have not been verified or approved by us. The views expressed by other users on the Services
          (including through use of the virtual gifts) do not represent our views or values.
        </p>

        <p>
          Whenever you access or use a feature that allows you to upload or transmit User Content through the Services
          (including via certain third party social media platforms such as Instagram, Facebook, YouTube, Twitter), or
          to make contact with other users of the Services, you must comply with the standards set out at “Your Access
          to and Use of Our Services” above. You may also choose to upload or transmit your User Content, including User
          Content that includes Scentub Elements, on sites or platforms hosted by third parties. If you decide to do
          this, you must comply with their content guidelines as well as with the standards set out at “Your Access to
          and Use of Our Services” above.
        </p>
        <p>
          You warrant that any such contribution does comply with those standards, and you will be liable to us and
          indemnify us for any breach of that warranty. This means you will be responsible for any loss or damage we
          suffer as a result of your breach of warranty.
        </p>
        <p>
          Any User Content will be considered non-confidential and non-proprietary. You must not post any User Content
          on or through the Services or transmit to us any User Content that you consider to be confidential or
          proprietary. When you submit User Content through the Services, you agree and represent that you own that User
          Content, or you have received all necessary permissions, clearances from, or are authorised by, the owner of
          any part of the content to submit it to the Services, to transmit it from the Services to other third party
          platforms, and/or adopt any third party content.
        </p>
        <p>
          If you only own the rights in and to a sound recording, but not to the underlying musical works embodied in
          such sound recordings, then you must not post such sound recordings to the Services unless you have all
          permissions, clearances from, or are authorised by, the owner of any part of the content to submit it to the
          Services.
        </p>

        <p>
          You or the owner of your User Content still own the copyright in User Content sent to us, but by submitting
          User Content via the Services, you hereby grant us an unconditional irrevocable, non-exclusive, royalty-free,
          fully transferable, perpetual worldwide licence to use, modify, adapt, reproduce, make derivative works of,
          publish and/or transmit, and/or distribute and to authorise other users of the Services and other
          third-parties to view, access, use, download, modify, adapt, reproduce, make derivative works of, publish
          and/or transmit your User Content in any format and on any platform, either now known or hereinafter invented.
        </p>
        <p>
          You further grant us a royalty-free license to use your user name, image, voice, and likeness to identify you
          as the source of any of your User Content.
        </p>
        <p>
          For the avoidance of doubt, the rights granted in the preceding paragraphs of this Section include, but are
          not limited to, the right to reproduce sound recordings (and make mechanical reproductions of the musical
          works embodied in such sound recordings), and publicly perform and communicate to the public sound recordings
          (and the musical works embodied therein), all on a royalty-free basis. This means that you are granting us the
          right to use your User Content without the obligation to pay royalties to any third party, including, but not
          limited to, a sound recording copyright owner (e.g., a record label), a musical work copyright owner (e.g., a
          music publisher), a performing rights organization (e.g., ASCAP, BMI, SESAC, etc.) (a “PRO”), a sound
          recording PRO (e.g., SoundExchange), any unions or guilds, and engineers, producers or other royalty
          participants involved in the creation of User Content. Specific Rules for Musical Works and for Recording
          Artists. If you are a composer or author of a musical work and are affiliated with a PRO, then you must notify
          your PRO of the royalty-free license you grant through these Terms in your User Content to us. You are solely
          responsible for ensuring your compliance with the relevant PRO’s reporting obligations. If you have assigned
          your rights to a music publisher, then you must obtain the consent of such music publisher to grant the
          royalty-free license(s) set forth in these Terms in your User Content or have such music publisher enter into
          these Terms with us. Just because you authored a musical work (e.g., wrote a song) does not mean you have the
          right to grant us the licenses in these Terms. If you are a recording artist under contract with a record
          label, then you are solely responsible for ensuring that your use of the Services is in compliance with any
          contractual obligations you may have to your record label, including if you create any new recordings through
          the Services that may be claimed by your label
        </p>
        <p>
          Through-To-The-Audience Rights. All of the rights you grant in your User Content in these Terms are provided
          on a through-to-the-audience basis, meaning the owners or operators of third party services will not have any
          separate liability to you or any other third party for User Content posted or used on such third party service
          via the Services.
        </p>
        <p>
          Waiver of Rights to User Content. By posting User Content to or through the Services, you waive any rights to
          prior inspection or approval of any marketing or promotional materials related to such User Content. You also
          waive any and all rights of privacy, publicity, or any other rights of a similar nature in connection with
          your User Content, or any portion thereof. To the extent any moral rights are not transferable or assignable,
          you hereby waive and agree never to assert any and all moral rights, or to support, maintain or permit any
          action based on any moral rights that you may have in or with respect to any User Content you Post to or
          through the Services.
        </p>
        <p>
          We also have the right to disclose your identity to any third party who is claiming that any User Content
          posted or uploaded by you to our Services constitutes a violation of their intellectual property rights, or of
          their right to privacy.
        </p>
        <p>
          We, or authorised third parties, reserve the right to cut, crop, edit or refuse to publish, your content at
          our or their sole discretion. We have the right to remove, disallow, block or delete any posting you make on
          our Services if, in our opinion, your post does not comply with the content standards set out at “Your Access
          to and Use of Our Services” above. In addition, we have the right – but not the obligation – in our sole
          discretion to remove, disallow, block or delete any User Content (i) that we consider to violate these Terms,
          or (ii) in response to complaints from other users or third parties, with or without notice and without any
          liability to you. As a result, we recommend that you save copies of any User Content that you post to the
          Services on your personal device(s) in the event that you want to ensure that you have permanent access to
          copies of such User Content. We do not guarantee the accuracy, integrity, appropriateness or quality of any
          User Content, and under no circumstances will we be liable in any way for any User Content.
        </p>
        <p>
          You control whether your User Content is made publicly available on the Services to all other users of the
          Services or only available to people you approve. To restrict access to your User Content, you should select
          the privacy setting available within the Platform.
        </p>
        <p>
          We accept no liability in respect of any content submitted by users and published by us or by authorised third
          parties.
        </p>
        <p>
          Scentub takes reasonable measures to expeditiously remove from our Services any infringing material that we
          become aware of. It is Scentub’s policy, in appropriate circumstances and at its discretion, to disable or
          terminate the accounts of users of the Services who repeatedly infringe copyrights or intellectual property
          rights of others.
        </p>
        <p>
          While our own staff is continually working to develop and evaluate our own product ideas and features, we
          pride ourselves on paying close attention to the interests, feedback, comments, and suggestions we receive
          from the user community. If you choose to contribute by sending us or our employees any ideas for products,
          services, features, modifications, enhancements, content, refinements, technologies, content offerings (such
          as audio, visual, games, or other types of content), promotions, strategies, or product/feature names, or any
          related documentation, artwork, computer code, diagrams, or other materials (collectively “Feedback”), then
          regardless of what your accompanying communication may say, the following terms will apply, so that future
          misunderstandings can be avoided. Accordingly, by sending Feedback to us, you agree that:
        </p>

        <ul>
          <li>
            Scentub has no obligation to review, consider, or implement your Feedback, or to return to you all or part
            of any Feedback for any reason
          </li>
          <li>
            Feedback is provided on a non-confidential basis, and we are not under any obligation to keep any Feedback
            you send confidential or to refrain from using or disclosing it in any way
          </li>
          <li>
            You irrevocably grant us perpetual and unlimited permission to reproduce, distribute, create derivative
            works of, modify, publicly perform (including on a through-to-the-audience basis), communicate to the
            public, make available, publicly display, and otherwise use and exploit the Feedback and derivatives thereof
            for any purpose and without restriction, free of charge and without attribution of any kind, including by
            making, using, selling, offering for sale, importing, and promoting commercial products and services that
            incorporate or embody Feedback, whether in whole or in part, and whether as provided or as modified
          </li>
        </ul>

        <h2>7. Indemnity</h2>
        <p>
          You agree to defend, indemnify, and hold harmless Scentub, its parents, subsidiaries, and affiliates, and each
          of their respective officers, directors, employees, agents and advisors from any and all claims, liabilities,
          costs, and expenses, including, but not limited to, attorneys’ fees and expenses, arising out of a breach by
          you or any user of your account of these Terms or arising out of a breach of your obligations, representation
          and warranties under these Terms.
        </p>
      </Col>
    </Row>
  );
};

export default Terms;
