import { useState } from "react";
import { Avatar, Flex, Input, Button, message } from "antd";
import { SendOutlined, UserOutlined, LoginOutlined } from "@ant-design/icons";
import { useAuth } from "../../../../../../contexts/AuthContext";
import { addExplorerComment } from "../../../../../../../services/supabaseClient";
import useWindowSize from "../../../../../../hooks/useWindowSize";
import ImageWithHandler from "../../../../../ImageWithHandler";

const { TextArea } = Input;

const CommentArea = ({ postId, onAddComment }) => {
  const { isMobileView } = useWindowSize();
  const { user, openLoginModal } = useAuth();
  const [valueComment, setValueComment] = useState(null);
  const [sending, setSending] = useState(false);

  const doComment = async () => {
    if (!valueComment || !valueComment.trim()) return;
    if (sending) {
      message.warning("Please wait before submitting another comment.");
      return;
    }

    const comment = {
      comment: valueComment.trim(),
      mentioned_user_ids: [],
      parent_id: null,
      topic: postId,
    };

    try {
      setSending(true);
      const { error, data } = await addExplorerComment(comment);
      if (error) {
        message.error(error.message);
      } else {
        setValueComment(null);
        onAddComment(data[0]);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setSending(false);
    }
  };

  return (
    <Flex
      align="center"
      style={{ paddingTop: "0.375rem", paddingBottom: "0.75rem", borderTop: "1px solid rgb(5 5 5 / 6%)" }}>
      {user ? (
        <div>
          <ImageWithHandler
            style={{ marginRight: !isMobileView ? "0.5rem" : "4px" }}
            title={user.vfra_user && user.vfra_user?.name ? user.vfra_user?.name[0] : user.name[0]}
            size="large"
            src={user.vfra_user?.avatar ?? user.avatar_url}
          />
        </div>
      ) : (
        <div>
          <Avatar
            style={{ marginRight: !isMobileView ? "0.5rem" : "4px" }}
            onClick={openLoginModal}
            size="large"
            icon={<UserOutlined />}
          />
        </div>
      )}

      <TextArea
        style={{ padding: "0.75rem", paddingLeft: "0.375rem", paddingTop: "1.125rem" }}
        placeholder={user ? `Write a comment...` : "Sign in to comment..."}
        autoSize={{
          minRows: 1,
        }}
        maxLength={200}
        variant="borderless"
        value={valueComment}
        onChange={(e) => setValueComment(e.target.value)}
      />
      {user ? (
        <Button
          size={isMobileView ? "small" : "middle"}
          disabled={sending}
          loading={sending}
          onClick={doComment}
          shape="circle"
          icon={<SendOutlined />}
        />
      ) : (
        <Button
          shape="round"
          size={isMobileView ? "small" : "middle"}
          onClick={openLoginModal}
          icon={<LoginOutlined />}></Button>
      )}
    </Flex>
  );
};

export default CommentArea;
