import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Button, message, Typography, Modal, Dropdown } from "antd";
import { LikeOutlined, HeartOutlined, MoreOutlined, LikeFilled, HeartFilled } from "@ant-design/icons";
import { deleteComment, addCommentReaction, deleteCommentReaction } from "../../../services/supabaseClient";
import { useAuth } from "../../contexts/AuthContext";
import { HEART_REACTION, LIKE_REACTION, ADD_REACTION_ACTION, DELETE_REACTION_ACTION } from "../../../utils/service";
import useWindowSize from "../../hooks/useWindowSize";
import ImageWithHandler from "../ImageWithHandler";

const { Paragraph } = Typography;
const ownerItems = [
  {
    label: "Delete",
    key: "delete",
  },
];

const Comment = ({ data, onDeleteComment }) => {
  const navigate = useNavigate();
  const { isMobileView } = useWindowSize();
  const { user, openLoginModal } = useAuth();
  const [item, setItem] = useState(data);

  const onMenuClick = (key) => {
    if (key === "delete") {
      showDeleteConfirm();
    }
  };

  const showDeleteConfirm = () => {
    return Modal.confirm({
      title: "Confirm delete comment",
      content: "Delete your comment permanently?",
      centered: true,
      footer: (_props, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
      onOk() {
        doDeleteComment();
      },
      getContainer: document.getElementById("root"),
    });
  };

  const doDeleteComment = async () => {
    try {
      const response = await deleteComment(item.id);
      if (response.status !== 204) {
        message.error(response.message);
      } else {
        message.success("Comment deleted!");
        onDeleteComment(item.id);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleCommentReaction = (action, reactionType) => {
    if (!user) {
      openLoginModal();
      return;
    }

    if (action === "ADD_REACTION") {
      doAddCommentReaction(reactionType);
    } else {
      doRemoveCommentReaction(reactionType);
    }
  };

  const doAddCommentReaction = async (reactionType) => {
    const payload = {
      comment_id: item.id,
      reaction_type: reactionType,
    };

    try {
      onChangeCommentReaction(reactionType, ADD_REACTION_ACTION);
      addCommentReaction(payload);
    } catch (error) {
      console.log(error.message);
    }
  };

  const doRemoveCommentReaction = async (reactionType) => {
    try {
      onChangeCommentReaction(reactionType, DELETE_REACTION_ACTION);
      deleteCommentReaction(reactionType, item.id);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onChangeCommentReaction = (reactionType, action) => {
    if (reactionType === HEART_REACTION) {
      if (action === ADD_REACTION_ACTION) {
        item.hearts_count++;
        item.active_for_user_heart = true;
      } else if (action === DELETE_REACTION_ACTION) {
        item.hearts_count--;
        item.active_for_user_heart = false;
      }
    } else if (reactionType === LIKE_REACTION) {
      if (action === ADD_REACTION_ACTION) {
        item.likes_count++;
        item.active_for_user_like = true;
      } else if (action === DELETE_REACTION_ACTION) {
        item.likes_count--;
        item.active_for_user_like = false;
      }
    }

    setItem({ ...item });
  };

  const gapItem = isMobileView ? "0.5rem" : "0.75rem";
  const sizeAction = "1rem";

  return (
    <Flex vertical gap={"0.5rem"} style={{ borderBottom: "1px solid rgb(5 5 5 / 6%)", padding: "0.5rem 0" }}>
      <Flex gap={gapItem} align="center">
        <div style={{ cursor: "pointer" }}>
          <ImageWithHandler
            onClick={() => navigate(`/profile/${item.user_id}`)}
            size="large"
            src={item.user_avatar}
            title={item.user_name[0]}
          />
        </div>

        <Flex justify="space-between" align="center">
          <Flex vertical="vertical" gap={"0.25rem"}>
            <span
              style={{ fontWeight: "700", cursor: "pointer", fontSize: "1rem" }}
              onClick={() => navigate(`/profile/${item.user_id}`)}>
              {item.user_name}
            </span>
            <span style={{ fontWeight: "normal", fontSize: "0.75rem", color: "rgba(113,128,150), 0.75)" }}>
              {item.created_at}
            </span>
          </Flex>
          {user && user.user_id === item.user_id && (
            <Dropdown
              menu={{
                items: ownerItems,
                onClick: ({ key, domEvent }) => {
                  domEvent.stopPropagation();
                  onMenuClick(key);
                },
              }}
              placement="bottomLeft"
              trigger={["click"]}>
              <Button
                onClick={(e) => e.stopPropagation()}
                shape="circle"
                size={isMobileView ? "small" : "default"}
                icon={<MoreOutlined />}
              />
            </Dropdown>
          )}
        </Flex>
      </Flex>

      <Flex vertical>
        <Paragraph
          style={{ fontSize: "0.9rem", margin: 0 }}
          ellipsis={{ rows: 5, expandable: "collapsible", symbol: (expanded) => (expanded ? "less" : "more") }}>
          {item.comment}
        </Paragraph>

        <Flex gap={"0.5rem"}>
          <Button
            style={{ fontSize: "0.9rem" }}
            type="text"
            onClick={() =>
              item.active_for_user_heart
                ? handleCommentReaction("REMOVE_REACTION", HEART_REACTION)
                : handleCommentReaction("ADD_REACTION", HEART_REACTION)
            }
            icon={
              item.active_for_user_heart ? (
                <HeartFilled style={{ color: "hotpink", fontSize: sizeAction }} />
              ) : (
                <HeartOutlined style={{ fontSize: sizeAction }} />
              )
            }>
            {item.hearts_count}
          </Button>
          <Button
            style={{ fontSize: "0.9rem" }}
            type="text"
            onClick={() =>
              item.active_for_user_like
                ? handleCommentReaction("REMOVE_REACTION", LIKE_REACTION)
                : handleCommentReaction("ADD_REACTION", LIKE_REACTION)
            }
            icon={
              item.active_for_user_like ? (
                <LikeFilled style={{ color: "#1677ff", fontSize: sizeAction }} />
              ) : (
                <LikeOutlined style={{ fontSize: sizeAction }} />
              )
            }>
            {item.likes_count}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Comment;
