import "./index.css";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";

import HeaderLayout from "../HeaderLayout";

const { Footer } = Layout;

const MainLayout = () => {
  return (
    <Layout>
      <HeaderLayout />
      <div className="vfra-content">
        <Outlet />
      </div>

      <Footer style={{ textAlign: "center" }}>Scentub ©{new Date().getFullYear()} All right reserved</Footer>
    </Layout>
  );
};

export default MainLayout;
