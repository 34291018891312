import "./index.css";
import { Skeleton, Row, Col } from "antd";
import { useSearch } from "../../../../../components/contexts/SearchContext";
import useWindowSize from "../../../../../components/hooks/useWindowSize";

const BrandsLoading = ({ length }) => {
  const { isMobileView } = useWindowSize();
  const { showSearchMobile } = useSearch();

  const gutter = isMobileView ? [12, 12] : [24, 24];

  return (
    <>
      {!showSearchMobile && <br></br>}
      <Row className="vfra-brands-loading-container" gutter={gutter}>
        {Array.from({ length: length }).map((_, i) => (
          <Col key={i} span={!isMobileView ? 6 : 24}>
            <Skeleton.Node active={true} style={{ height: "10vh" }} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default BrandsLoading;
