import React, { createContext, useState, useEffect, useContext, useCallback } from "react";
import { message } from "antd";
import { supabase, getUser } from "../../services/supabaseClient";
import {
  getItemWithExpiry,
  setItemWithExpiry,
  localStorage_fields,
  clearLocalStorageByUser,
  expires_in,
} from "../../utils/localstorage";
import { AUTH_STATUS, TIME_RESET_NEW_FEED } from "../../utils/service";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authStatus, setAuthStatus] = useState(AUTH_STATUS.authenticating);
  const [user, setUser] = useState(getItemWithExpiry(localStorage_fields.user));
  const [showLoginModal, setShowLoginModal] = useState(false);

  const openLoginModal = () => {
    setShowLoginModal(true);
  };

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  const getVfraUser = (userId) => {
    return new Promise(async (resolve) => {
      try {
        const { error, data } = await getUser(userId);
        if (error) {
          message.error(error);
          resolve(null);
        } else {
          resolve(data.length > 0 ? data[0] : null);
        }
      } catch (error) {
        message.error(error.message);
        resolve(null);
      }
    });
  };

  const refreshVfraUser = async () => {
    if (!user) return;
    const data = await getVfraUser(user.user_id);
    setUser((prevUser) => {
      return {
        ...prevUser,
        vfra_user: data ?? null,
      };
    });
  };

  const initUser = useCallback(async (session) => {
    return new Promise(async (resolve, _reject) => {
      const {
        user: { id, user_metadata },
      } = session;

      const data = await getVfraUser(id);
      const userTemp = { ...user_metadata, user_id: id, vfra_user: data ?? null };
      setUser(userTemp);

      setItemWithExpiry(localStorage_fields.user, userTemp, expires_in.week);

      resolve(true);
    });
  }, []);

  const logout = () => {
    // clear c_post
    const posts = getItemWithExpiry(localStorage_fields.posts);
    if (posts) {
      const newPosts = posts.filter((p) => p.isCPost !== true);

      let currentLastIndexPost = getItemWithExpiry(localStorage_fields.lastIndexPost);
      if (currentLastIndexPost) {
        currentLastIndexPost = parseInt(currentLastIndexPost, 10);
      }

      if (currentLastIndexPost !== 0) {
        const currentLastPostId = posts[currentLastIndexPost].post.id;
        const prevLastPostId = posts[currentLastIndexPost - 1].post.id;

        let newLastIndexPost = newPosts.findIndex((p) => p.post.id === currentLastPostId);
        if (newLastIndexPost === -1) {
          newLastIndexPost = newPosts.findIndex((p) => p.post.id === prevLastPostId);
        }

        setItemWithExpiry(localStorage_fields.lastIndexPost, newLastIndexPost, TIME_RESET_NEW_FEED);
      }

      setItemWithExpiry(localStorage_fields.posts, newPosts, TIME_RESET_NEW_FEED);
    }

    setAuthStatus(AUTH_STATUS.authenticating);
    setUser(null);
    clearLocalStorageByUser();
    supabase.auth.signOut();
    message.success("Signed out successful!");
  };

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === "INITIAL_SESSION") {
        if (!session) setAuthStatus(AUTH_STATUS.unauthenticate);
      } else if (event === "SIGNED_IN") {
        if (session) {
          setAuthStatus(AUTH_STATUS.authenticated);
          if (!user) {
            await initUser(session);
            message.success("Sign in successful!");
          }
        }
      } else if (event === "SIGNED_OUT") {
        setAuthStatus(AUTH_STATUS.unauthenticate);
        setUser(null);
        clearLocalStorageByUser();
      }
    });

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, [user, initUser]);

  return (
    <AuthContext.Provider
      value={{
        user,
        showLoginModal,
        authStatus,
        setAuthStatus,
        openLoginModal,
        closeLoginModal,
        refreshVfraUser,
        logout,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
