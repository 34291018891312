import "./index.css";
import { Flex, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { HeartOutlined } from "@ant-design/icons";
import { pathData } from "../../../../utils/routes";
import { useSearch } from "../../../../components/contexts/SearchContext";
import ImageWithLoader from "../../../../components/common/ImageWithLoader";
import useWindowSize from "../../../../components/hooks/useWindowSize";

const PostItem = ({ post, ...prop }) => {
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();

  const itemGap = isMobileView ? 4 : 6;
  const { setShowSearchMobile } = useSearch();

  const handleClick = () => {
    if (isMobileView) setShowSearchMobile(false);
    navigate(`${pathData.searchDetail}?startId=${post.id}`);
  };

  const handleClickPerfumeImage = (id) => {
    if (isMobileView) setShowSearchMobile(false);
    navigate(`${pathData.perfume}/${id}`);
  };

  return (
    <Flex {...prop} vertical gap={itemGap}>
      <ImageWithLoader
        style={{ borderRadius: "1rem", cursor: "pointer" }}
        width="100%"
        src={post.media_data.urls[0]}
        onClick={handleClick}
      />
      <Flex style={{ padding: `0 ${isMobileView ? 0 : itemGap}px` }} vertical gap={itemGap}>
        {post.perfumes && (
          <Flex className="vfra-search-perfumes-container" gap={itemGap} justify="start">
            {post.perfumes.map((item) => (
              <div onClick={() => handleClickPerfumeImage(item.id)} className="vfra-search-perfume-item" key={item.id}>
                <span className="one-line-ellipsis" style={{ fontSize: "0.6rem" }}>
                  {item.name}
                </span>
              </div>
            ))}
          </Flex>
        )}

        <h3 style={{ fontSize: "0.8125rem", margin: 0, fontWeight: 700 }} className="two-line-ellipsis">
          {post.caption}
        </h3>

        <Flex justify="space-between" align="center" gap={itemGap}>
          <Flex
            align="center"
            style={{ cursor: "pointer" }}
            gap={itemGap}
            onClick={() => navigate(`/profile/${post.owner.id}`)}>
            <Avatar size={isMobileView ? 24 : "middle"} src={post.owner.avatar} />
            <h4 className="one-line-ellipsis" style={{ margin: 0, fontWeight: 400, fontSize: "0.8125rem" }}>
              {post.owner.name}
            </h4>
          </Flex>
          {post.total_hearts > 0 && (
            <Flex gap={itemGap} align="center">
              <span style={{ margin: 0, fontWeight: 400, fontSize: "0.8125rem" }}>{post.total_hearts}</span>
              <HeartOutlined />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PostItem;
