import "./index.css";
import { colors } from "../../../utils/service";

const VerticalProgressBar = ({
  percent,
  height = "12.5rem",
  width = "3px",
  color = colors.mainThemeColor,
  showText = true,
}) => {
  return (
    <>
      <div className="vertical-progress-bar-container" style={{ height: `${height}`, width: `${width}` }}>
        <div className="vertical-progress-bar">
          <div
            className="vertical-progress-bar-fill"
            style={{
              height: `${percent}%`,
              backgroundColor: color,
            }}></div>
        </div>
      </div>
      {showText && percent > 0 && (
        <div
          className="vertical-progress-bar-container"
          style={{ height: `${height}`, width: `1.5rem`, backgroundColor: "transparent" }}>
          <div className="vertical-progress-bar" style={{ backgroundColor: "transparent" }}>
            <div
              className="vertical-progress-bar-fill"
              style={{
                height: `${percent}%`,
                backgroundColor: "transparent",
              }}>
              <span className="vertical-progress-bar-text">{percent}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerticalProgressBar;
