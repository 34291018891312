import { useRef, useEffect } from "react";
import { stream } from "../../../../services/musicServer";

const PostAudio = ({ id, data, playing, onPause, muted }) => {
  const audioRef = useRef(id);
  const audioSrc = stream(data.track_id);
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 0.3;
      audioRef.current.pause();
      audioRef.current.currentTime = data.start_time;
    }
  }, [data]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.muted = muted;

      if (playing) {
        if (audioRef.current.currentTime === 0 && data.start_time !== 0) audioRef.current.currentTime = data.start_time;
        audioRef.current.play().catch((error) => {
          console.error("Error trying to play audio:", error);
        });
      } else {
        audioRef.current.pause();
      }
    }
  }, [muted, playing, data]);

  const handleTimeUpdate = () => {
    const current = audioRef.current.currentTime;
    if (current >= data.end_time) {
      if (data.loop) {
        audioRef.current.currentTime = data.start_time;
      } else {
        audioRef.current.pause();
        audioRef.current.currentTime = data.start_time;
        onPause();
      }
    }
  };

  return (
    <audio ref={audioRef} onTimeUpdate={handleTimeUpdate} loop={data.loop}>
      <source src={audioSrc} type="audio/mp3" />
    </audio>
  );
};

export default PostAudio;
