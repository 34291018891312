import "./index.css";
import { Row, Col } from "antd";
import useWindowSize from "../../components/hooks/useWindowSize";
import PerfumeInfo from "./PerfumeInfo";
import RelatedPerfumes from "./RelatedPerfumes";
import RelatedPosts from "./RelatedPosts";

export const PerfumeAllRelatePost = () => {
  const { isMobileView } = useWindowSize();

  const rowGutter = isMobileView ? [6, 6] : [12, 12];

  return (
    <Row gutter={rowGutter} style={{ marginBottom: "1rem" }}>
      <Col span={isMobileView ? 24 : 18}>
        <Row gutter={rowGutter} className="vfra-all-relate-post-container">
          <Col span={24}>
            <PerfumeInfo />
          </Col>
          <Col span={24}>
            <RelatedPosts />
          </Col>
        </Row>
      </Col>
      <Col span={isMobileView ? 24 : 6}>
        <RelatedPerfumes />
      </Col>
    </Row>
  );
};

export default PerfumeAllRelatePost;
