import "./index.css";
import { Flex, Button } from "antd";
import { CheckCircleOutlined, CaretRightOutlined, PauseOutlined } from "@ant-design/icons";
import { getAvatar } from "../../../services/musicServer";
import useWindowSize from "../../hooks/useWindowSize";

const MusicItem = ({ sound, isChecked = false, onSelectSound, ...props }) => {
  const { isMobileView } = useWindowSize();

  const handleSelectSound = () => {
    if (isChecked) {
      onSelectSound(null);
    } else {
      onSelectSound(sound);
    }
  };

  const imageSrc = getAvatar(sound.coverArt);

  return (
    <Flex {...props} justify="space-between" align="center" onClick={handleSelectSound}>
      <Flex align="center">
        <img
          className={`${isChecked ? "spinning" : ""}`}
          alt=""
          width={isMobileView ? "35px" : "50px"}
          crossOrigin="anonymous"
          src={imageSrc}
          style={{ marginRight: "12px" }}
        />
        <Flex vertical>
          <span style={{ fontWeight: "700", fontSize: "0.9" }} className="one-line-ellipsis">
            {sound.title}
          </span>
          <span className="one-line-ellipsis" style={{ fontSize: "0.7rem" }}>
            {sound.artist}
          </span>
        </Flex>
      </Flex>

      <Button shape="circle" size="small" icon={isChecked ? <PauseOutlined /> : <CaretRightOutlined />} />

      {isChecked && (
        <div className="vfra-sound-item-active-icon">
          <CheckCircleOutlined />
        </div>
      )}
    </Flex>
  );
};

export default MusicItem;
