import "./index.css";
import { Col, Row, Tabs } from "antd";
import { useParams } from "react-router-dom";
import { ShoppingOutlined, MessageOutlined, AppstoreOutlined, GlobalOutlined } from "@ant-design/icons";
import { useAuth } from "../../components/contexts/AuthContext";
import useWindowSize from "../../components/hooks/useWindowSize";
import UserInfo from "./UserInfo";
import OwnPerfumes from "./OwnPerfumes";
import Reviews from "./Reviews";
import Favorites from "./Favorites";
import Community from "./Community";

const getMenu = (isYourself, isMobileView) => {
  const menu = [
    {
      key: "0",
      label: `Community`,
      children: <Community />,
      icon: isMobileView ? "" : <GlobalOutlined />,
    },
    {
      key: "1",
      label: `Overall`,
      children: <Favorites />,
      icon: isMobileView ? "" : <ShoppingOutlined />,
    },
    {
      key: "2",
      label: `Reviews`,
      children: <Reviews />,
      icon: isMobileView ? "" : <MessageOutlined />,
    },
  ];

  if (isYourself)
    menu.push({
      key: "3",
      label: `Perfumes`,
      children: <OwnPerfumes />,
      icon: isMobileView ? "" : <AppstoreOutlined />,
    });

  return menu;
};

const Profile = () => {
  const { isMobileView } = useWindowSize();
  const { user } = useAuth();
  const { userId } = useParams();
  const isYourself = user?.user_id === userId;

  const menu = getMenu(isYourself, isMobileView);

  return (
    <Row gutter={[0, 16]} className="vfra-profile vfra-padding-top">
      <Col span={isMobileView ? 24 : 18} offset={isMobileView ? 0 : 3}>
        <UserInfo />
      </Col>
      <Col span={isMobileView ? 24 : 18} offset={isMobileView ? 0 : 3}>
        <Tabs defaultActiveKey="0" items={menu} size={isMobileView ? "middle" : "large"} />
      </Col>
    </Row>
  );
};

export default Profile;
