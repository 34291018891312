import "./index.css";
import { Flex, Skeleton } from "antd";

const PostLoading = () => {
  return (
    <Flex justify="center" className="vfra-feed-loading-container">
      <Skeleton.Node active={true} />

      <div className="vfra-post-loading-container">
        <Flex vertical gap={28} className="vfra-loading-post-actions">
          <Skeleton.Avatar active={true} size="large" />
          <Skeleton.Avatar active={true} size="large" />
          <Skeleton.Avatar active={true} size="large" />
          <Skeleton.Avatar active={true} size="large" />
        </Flex>
      </div>
    </Flex>
  );
};

export default PostLoading;
