// DEMO
// const endpoint = "https://demo.navidrome.org";
// const u = "demo";
// const t = "4aa44610e7c8b09fa33569f2182aa7a9";
// const s = "c19b2d";
// const c = "myapp";
// const f = "json";
// const v = "1.16.0";

const endpoint = "https://music.perfumerates.com";
const u = "listener";
const t = "bd38132edcfc6816d0d99414a8dcc0ef";
const s = "c19b2d21";
const c = "vfra_music";
const f = "json";
const v = "1.16.0";

const fetchBase = async (method, url, body, accessToken) => {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: body ? JSON.stringify(body) : null,
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    if (response.status === 204) {
      return {
        status: response.status,
      };
    }

    const results = await response.json();
    return results;
  } catch (error) {
    return { status: 204, error: error };
  }
};

// GET rest/search
export const search = async (searchContent, page, pageSize) => {
  const offset = (page - 1) * pageSize;
  return fetchBase(
    "GET",
    `${endpoint}/rest/search2?u=${u}&t=${t}&s=${s}&c=${c}&f=${f}&v=${v}&title=${searchContent}&songOffset=${offset}&songCount=${pageSize}`
  );
};

// GET rest/getPlaylists
export const getPlaylists = async () => {
  return fetchBase("GET", `${endpoint}/rest/getPlaylists?u=${u}&t=${t}&s=${s}&c=${c}&f=${f}&v=${v}`);
};

// GET rest/getPlaylist
export const getPlaylist = async (id) => {
  return fetchBase("GET", `${endpoint}/rest/getPlaylist?u=${u}&t=${t}&s=${s}&c=${c}&f=${f}&v=${v}&id=${id}`);
};

// GET rest/getSong
export const getSong = (id) => {
  return fetchBase("GET", `${endpoint}/rest/getSong?u=${u}&t=${t}&s=${s}&c=${c}&f=${f}&v=${v}&id=${id}`);
};

// GET rest/getAvatar
export const getAvatar = (coverArt) => {
  return `${endpoint}/rest/getCoverArt?u=${u}&t=${t}&s=${s}&c=${c}&f=${f}&v=${v}&id=${coverArt}`;
};

// GET /rest/stream
export const stream = (id) => {
  return `${endpoint}/rest/stream?u=${u}&t=${t}&s=${s}&c=${c}&f=${f}&v=${v}&id=${id}`;
};
