import { Skeleton } from "antd";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import ImageWithHandler from "../../../../components/common/ImageWithHandler";

const UserAvatar = ({ loading, user }) => {
  const { isMobileView } = useWindowSize();

  const avtSize = isMobileView ? 80 : 164;
  const titleSize = isMobileView ? "1.8rem" : "2.5rem";

  if (loading) return <Skeleton.Avatar active={true} size={avtSize} />;
  else if (user)
    return (
      <div>
        <ImageWithHandler titleSize={titleSize} title={user.name[0]} src={user.avatar} size={avtSize} />
      </div>
    );
};
export default UserAvatar;
