import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Flex, Progress, message } from "antd";
import { useAuth } from "../../../../../components/contexts/AuthContext";
import { sonIcon, moonIcon } from "../../../../../utils/svgIcons";
import CustomeSvgIcon from "../../../../../components/common/CustomSvgIcon";
import CustomeSecondLabel from "../../../../../components/common/CustomeSecondLabel";
import { getMaxValueFromObject, colors } from "../../../../../utils/service";
import { addVotes } from "../../../../../services/supabaseClient";
import useWindowSize from "../../../../../components/hooks/useWindowSize";

const styleIcon = { marginBottom: "12px", fill: colors.grayBackgroundColor };
const styleIconActive = { marginBottom: "12px", fill: colors.mainThemeColor };

const progressStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: "8px",
};
const porgressPercentPosition = {
  type: "none",
};

const WeatherVote = ({ data, userVote }) => {
  const { isMobileView } = useWindowSize();

  const { user } = useAuth();
  const { perfumeId } = useParams();

  const [day, setDay] = useState(null);
  const [night, setNight] = useState(null);

  const [dataVote, setDataVote] = useState(null);
  const [valueVote, setValueVote] = useState(null);
  const [dataUserVote, setDataUserVote] = useState(null);

  const setInitValues = (dataInit, initUserVote) => {
    setDataVote(dataInit);
    const max = getMaxValueFromObject(dataInit);

    const initDay = dataInit?.day ? (dataInit.day / max) * 100 : 0;
    setDay(initDay);

    const initNight = dataInit?.night ? (dataInit.night / max) * 100 : 0;
    setNight(initNight);

    if (initUserVote && initUserVote.weathers) {
      setValueVote(initUserVote.weathers);
      setDataUserVote(initUserVote);
    } else {
      setValueVote(null);
      setDataUserVote({ weathers: null });
    }
  };

  useEffect(() => {
    setInitValues(data, userVote);
  }, [data, userVote]);

  const updateDataVote = (value, newStatus, newDataUserVotes) => {
    const newValueVote = {
      ...valueVote,
      [value]: data[newStatus] + 1,
    };
    setValueVote(newValueVote);

    const newDataVotes = {
      ...dataVote,
      [value]: newStatus ? dataVote[value] + 1 : dataVote[value] - 1,
    };

    setInitValues(newDataVotes, newDataUserVotes);
  };

  const onVote = async (value) => {
    if (!user) message.warning("Please sign in to vote!");
    else {
      try {
        let newStatus = true;
        if (dataUserVote?.weathers?.hasOwnProperty(value)) {
          newStatus = !dataUserVote?.weathers[value];
        }

        const votes = {
          weathers: {
            ...dataUserVote.weathers,
            [value]: newStatus,
          },
        };

        const { error } = addVotes(perfumeId, votes);

        if (error) {
          message.error(error.message);
        } else {
          setValueVote(value);
          updateDataVote(value, newStatus, votes);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  };

  const progressSize = [isMobileView ? 60 : 70, 3];

  const iconHeight = !isMobileView ? "1.875rem" : 20;

  return (
    <Flex justify="space-around">
      <Flex vertical align="center">
        <Flex vertical align="center" className="vfra-vote-option" onClick={() => onVote("day")}>
          <CustomeSvgIcon
            style={valueVote?.day ? styleIconActive : styleIcon}
            width="40"
            height={iconHeight}
            icon={sonIcon}
          />
          <Flex vertical align="center">
            <span>day</span>
            <CustomeSecondLabel label={dataVote ? dataVote.day : 0} />
          </Flex>
        </Flex>

        <Progress
          style={progressStyle}
          percent={day}
          percentPosition={porgressPercentPosition}
          size={progressSize}
          strokeColor={colors.mainThemeColor}
        />
      </Flex>
      <Flex vertical align="center">
        <Flex vertical align="center" className="vfra-vote-option" onClick={() => onVote("night")}>
          <CustomeSvgIcon style={valueVote?.night ? styleIconActive : styleIcon} height={iconHeight} icon={moonIcon} />

          <Flex vertical align="center">
            <span>night</span>
            <CustomeSecondLabel label={dataVote ? dataVote.night : 0} />
          </Flex>
        </Flex>

        <Progress
          style={progressStyle}
          percent={night}
          percentPosition={porgressPercentPosition}
          size={progressSize}
          strokeColor={colors.mainThemeColor}
        />
      </Flex>
    </Flex>
  );
};

export default WeatherVote;
