import "./index.css";
import { useState } from "react";
import { Flex, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { HeartOutlined, HeartFilled, LikeOutlined, LikeFilled } from "@ant-design/icons";
import { colors } from "../../../../../utils/service";
import { pathData } from "../../../../../utils/routes";
import { convertComment } from "../../../../../utils/service";
import { useAuth } from "../../../../../components/contexts/AuthContext";
import { addCommentReaction, deleteCommentReaction } from "../../../../../services/supabaseClient";
import { HEART_REACTION, LIKE_REACTION } from "../../../../../utils/service";
import ImageWithHandler from "../../../../../components/common/ImageWithHandler";
import ImageWithLoader from "../../../../../components/common/ImageWithLoader";
import useWindowSize from "../../../../../components/hooks/useWindowSize";

const Comment = ({ data, index, ...props }) => {
  const navigate = useNavigate();
  const convertedComment = convertComment(data);
  const [comment, setComment] = useState({
    ...convertedComment,
  });
  const { isMobileView } = useWindowSize();
  const { user, openLoginModal } = useAuth();

  const perfumeSize = isMobileView ? "4rem" : "4.5rem";
  const btnSize = isMobileView ? "small" : "middle";

  const hanldeClickUser = () => {
    navigate(`${pathData.profile}/${comment.user_id}`);
  };

  const hanldeClickPerfume = () => {
    data.perfume && navigate(`${pathData.perfume}/${data.perfume.id}`);
  };

  const handleCommentReaction = (action, reactionType) => {
    if (!user) {
      openLoginModal();
      return;
    }

    if (action === "ADD_REACTION") {
      const payload = {
        comment_id: comment.id,
        reaction_type: reactionType,
      };

      addCommentReaction(payload);
    } else {
      deleteCommentReaction(reactionType, comment.id);
    }

    setComment((prev) => {
      if (reactionType === LIKE_REACTION) {
        prev = {
          ...prev,
          active_for_user_like: !prev.active_for_user_like,
          likes_count: action === "ADD_REACTION" ? prev.likes_count + 1 : prev.likes_count - 1,
        };
      } else {
        prev = {
          ...prev,
          active_for_user_heart: !prev.active_for_user_heart,
          hearts_count: action === "ADD_REACTION" ? prev.hearts_count + 1 : prev.hearts_count - 1,
        };
      }

      return {
        ...prev,
      };
    });
  };

  const gapItem = isMobileView ? 6 : 12;

  return (
    <Flex {...props} gap={3} align="center" className="vfra-explore-top-comment">
      <Flex vertical gap={gapItem} style={{ flexGrow: 1, maxWidth: "75%" }}>
        <Flex gap={gapItem} align="center">
          <div>
            <ImageWithHandler
              alt=""
              size="large"
              style={{ cursor: "pointer" }}
              src={comment.user_avatar}
              title={comment.user_name ? comment.user_name[0] : ""}
              onClick={hanldeClickUser}
            />
          </div>

          <Flex vertical>
            <h1
              onClick={hanldeClickUser}
              className="one-line-ellipsis"
              style={{ margin: 0, fontSize: "0.9rem", fontWeight: "600", cursor: "pointer" }}>
              {comment.user_name}
            </h1>
            <span style={{ fontWeight: "normal", fontSize: "0.7rem", color: colors.grayColor }}>
              {comment.created_at}
            </span>
          </Flex>
        </Flex>

        <span className="two-line-ellipsis" style={{ fontSize: isMobileView ? "0.9rem" : "0.8125rem", margin: 0 }}>
          {comment.comment}
        </span>

        <Flex gap={isMobileView ? 6 : 12} style={{ merginLeft: "-1rem" }}>
          <Button
            size={btnSize}
            style={{ border: "none", boxShadow: "none" }}
            icon={
              comment.active_for_user_heart ? (
                <HeartFilled style={{ color: "hotpink", fontSize: "0.9rem" }} />
              ) : (
                <HeartOutlined style={{ fontSize: "0.9rem" }} />
              )
            }
            onClick={() =>
              comment.active_for_user_heart
                ? handleCommentReaction("REMOVE_REACTION", HEART_REACTION)
                : handleCommentReaction("ADD_REACTION", HEART_REACTION)
            }>
            {comment.hearts_count}
          </Button>
          <Button
            size={btnSize}
            style={{ border: "none", boxShadow: "none" }}
            icon={
              comment.active_for_user_like ? (
                <LikeFilled style={{ color: "#1677ff", fontSize: "0.9rem" }} />
              ) : (
                <LikeOutlined style={{ fontSize: "0.9rem" }} />
              )
            }
            onClick={() =>
              comment.active_for_user_like
                ? handleCommentReaction("REMOVE_REACTION", LIKE_REACTION)
                : handleCommentReaction("ADD_REACTION", LIKE_REACTION)
            }>
            {comment.likes_count}
          </Button>
        </Flex>
      </Flex>

      {data.perfume && (
        <ImageWithLoader
          onClick={hanldeClickPerfume}
          style={{ width: perfumeSize, height: "auto", cursor: "pointer" }}
          src={data.perfume.image_url}
          loadingWidth={"15vh"}
        />
      )}
    </Flex>
  );
};

export default Comment;
