import "./index.css";
import { Flex, Rate, Badge } from "antd";

const badgeStyle = {
  backgroundColor: "rgb(229 229 229 / 37%)",
  color: "var(--main-theme-color)",
  fontWeight: "600",
};

const Rating = ({ value, badge, disabled = true, onChange, ...props }) => {
  return (
    <Flex align="center" {...props} gap={6}>
      <Rate onChange={onChange} disabled={disabled} defaultValue={value} style={{ fontSize: "0.8rem" }} />
      <Badge count={badge ?? value} size="small" style={badgeStyle} />
    </Flex>
  );
};

export default Rating;
