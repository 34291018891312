import { useNavigate } from "react-router-dom";
import { createContext, useState, useContext } from "react";
import { sessionStorage_fields } from "../../utils/localstorage";
import { pathData } from "../../utils/routes";

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const navigate = useNavigate();
  const localSearchValue = sessionStorage.getItem(sessionStorage_fields.searchMainValue);
  const [searchValue, setSearchValue] = useState(localSearchValue ?? null);
  const [showSearchMobile, setShowSearchMobile] = useState(false);

  const onSearchChange = (value) => {
    if (value) {
      sessionStorage.setItem(sessionStorage_fields.searchMainValue, value);
    } else {
      sessionStorage.removeItem(sessionStorage_fields.searchMainValue);
    }

    setSearchValue(value);

    if (
      window.location.pathname !== `${pathData.home}/perfume` &&
      window.location.pathname !== `${pathData.home}/post` &&
      window.location.pathname !== `${pathData.home}/brand`
    )
      navigate(`${pathData.home}/perfume`);
  };

  return (
    <SearchContext.Provider
      value={{
        searchValue,
        onSearchChange,
        showSearchMobile,
        setShowSearchMobile,
      }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
