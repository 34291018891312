import "../../index.css";
import { useNavigate } from "react-router-dom";
import { Flex } from "antd";
import AccordTag from "./AccordTag";
import useWindowSize from "../../../../../hooks/useWindowSize";

const maxCountAccords = 4;

const PerfumeAccords = ({ perfume, ...props }) => {
  const { isMobileView } = useWindowSize();
  const gapItem = isMobileView ? 6 : 12;
  const navigate = useNavigate();

  return (
    <Flex {...props} vertical gap={gapItem}>
      <Flex justify="center">
        <span
          style={{ fontSize: "0.8125rem", fontWeight: "600" }}
          className="vfra-title-accords-popover"
          onClick={() => navigate(`/perfume/${perfume.id}`)}>
          {perfume.name}
        </span>
      </Flex>

      <Flex
        gap={gapItem}
        justify="center"
        className={perfume.accords.length > maxCountAccords ? "vfra-accords-list" : ""}>
        {perfume.accords.map((accord, index) => (
          <AccordTag key={index} name={accord.name} />
        ))}
      </Flex>
    </Flex>
  );
};

export default PerfumeAccords;
