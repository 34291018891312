import { useCallback, useEffect, useMemo, useState } from "react";
import { Select, Spin, Space, Button, Flex, Divider, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import useWindowSize from "../../../../../components/hooks/useWindowSize";
import _ from "lodash";

const DebouncedSelect = ({
  debounceTimeout = 300,
  hasLoadmore = true,
  fieldValue,
  fieldLabel,
  fieldImage,
  searchFunction,
  onAddItem,
  ...props
}) => {
  const { isMobileView } = useWindowSize();
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1);

  const [valueSearch, setValueSearch] = useState("");
  const [totalSearch, setTotalSearch] = useState(0);

  const onSearch = useCallback(
    async (value, page = 1) => {
      setFetching(true);
      searchFunction(value, page)
        .then((data) => {
          if (data.error) {
            message.error(data.error);
            return [];
          } else {
            setTotalSearch(data.estimatedTotalHits);

            return data.hits.map((e) => ({
              ...e,
              value: e[fieldValue],
              label: e[fieldLabel],
            }));
          }
        })
        .then((newOptions) => {
          setOptions((prevOptions) => {
            if (page > 1) return [...prevOptions, ...newOptions];
            return newOptions;
          });
        })
        .catch((error) => message.error(error.message))
        .finally(() => setFetching(false));
    },
    [fieldValue, fieldLabel, searchFunction]
  );

  const debouncedSearch = useMemo(() => _.debounce(onSearch, 300), [onSearch]);

  useEffect(() => {
    onSearch();
  }, [onSearch]);

  const handleOnSearchChange = (value) => {
    if (value !== valueSearch) {
      setPage(1);
      debouncedSearch(value, 1);
    } else {
      debouncedSearch(value, page);
    }
    setValueSearch(value);
  };

  const handleLoadMore = () => {
    const newPage = page + 1;
    debouncedSearch(valueSearch, newPage);
    setPage((prevPage) => prevPage + 1);
  };

  const handleOnAddItem = () => {
    onAddItem();
  };

  return (
    <Select
      labelInValue
      filterOption={false}
      searchValue={valueSearch}
      showSearch
      allowClear
      onSearch={handleOnSearchChange}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
      optionRender={(option) => (
        <Space>
          <img width={isMobileView ? 24 : 30} alt="" src={option.data[fieldImage]} />
          {option.data.label}
        </Space>
      )}
      dropdownRender={(menu) => (
        <>
          {menu}

          {totalSearch > options.length && (
            <>
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              {hasLoadmore && (
                <Flex justify="center">
                  <Button loading={fetching} onClick={handleLoadMore} type="text" icon={<PlusOutlined />}>
                    Load more
                  </Button>
                </Flex>
              )}

              {!hasLoadmore && (
                <Flex justify="center">
                  <Button loading={fetching} onClick={handleOnAddItem} type="text">
                    Can not find your brand ? Add here
                  </Button>
                </Flex>
              )}
            </>
          )}
        </>
      )}
    />
  );
};

export default DebouncedSelect;
