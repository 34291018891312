import { useState } from "react";
import { Avatar, Button, message, Upload } from "antd";
import { UserOutlined, EditOutlined } from "@ant-design/icons";
import { getColorByName } from "../../../../../utils/service";
import useWindowSize from "../../../../../components/hooks/useWindowSize";

const UserAvatar = ({ user, onAvatarChange }) => {
  const { isMobileView } = useWindowSize();

  const [imageUrl, setImageUrl] = useState(null);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return false;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(file);

    return false;
  };

  const handleChange = (info) => {
    onAvatarChange(info.file);
  };

  const sizeEditButton = "small";
  const avtSize = isMobileView ? 96 : 128;

  return (
    <>
      {!imageUrl && (
        <>
          {user.avatar && <Avatar src={user.avatar} size={avtSize} icon={<UserOutlined />} />}
          {!user.avatar && (
            <Avatar
              size={avtSize}
              style={{
                backgroundColor: getColorByName(user.name),
                verticalAlign: "middle",
                cursor: "pointer",
              }}>
              {user.name[0]}
            </Avatar>
          )}
        </>
      )}

      {imageUrl && <Avatar src={imageUrl} size={avtSize} />}

      <Upload showUploadList={false} beforeUpload={beforeUpload} onChange={handleChange}>
        <Button
          style={{ position: "absolute", bottom: "-0.2rem", marginLeft: "-1.8rem" }}
          size={sizeEditButton}
          shape="circle"
          icon={<EditOutlined />}
        />
      </Upload>
    </>
  );
};
export default UserAvatar;
