import "./index.css";
import { useState } from "react";
import { Flex, Modal, Tabs, Skeleton, Space } from "antd";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import ListUsers from "./ListUsers";

const UserFollow = ({ loading, user = null }) => {
  const { isMobileView } = useWindowSize();

  const [showFollowDetail, setShowFollowDetail] = useState(false);
  const [activeKey, setActiveKey] = useState("followers");

  const items = [
    {
      key: "followers",
      label: (
        <span>
          Followers
          <span style={{ marginLeft: "6px", fontWeight: "bold" }}>
            {user && user.follow_detail ? user.follow_detail.total_followers : 0}
          </span>
        </span>
      ),
      children: (
        <ListUsers
          type="FOLLOWERS"
          total={user && user.follow_detail ? user.follow_detail.total_followers : 0}
          onClose={() => setShowFollowDetail(false)}
        />
      ),
    },
    {
      key: "following",
      label: (
        <span>
          Following
          <span style={{ marginLeft: "6px", fontWeight: "bold" }}>
            {user && user.follow_detail ? user.follow_detail.total_followees : "0"}
          </span>
        </span>
      ),
      children: (
        <ListUsers
          type="FOLLOWING"
          total={user && user.follow_detail ? user.follow_detail.total_followees : 0}
          onClose={() => setShowFollowDetail(false)}
        />
      ),
    },
  ];

  const handleShowFollowDetail = (key) => {
    setActiveKey(key);
    setShowFollowDetail(true);
  };

  return (
    <>
      {loading && (
        <Flex style={{ marginTop: "6px" }}>
          <Space size={12}>
            <Skeleton.Input active={true} size="small" block={true} />
            <Skeleton.Input active={true} size="small" block={true} />
          </Space>
        </Flex>
      )}

      {!loading && user && (
        <>
          <Flex gap={"1rem"} align="center">
            <span className="vfra-follow" onClick={() => handleShowFollowDetail("followers")}>
              <span className="vfra-follow-count">{user.follow_detail ? user.follow_detail.total_followers : 0}</span>
              followers
            </span>
            <span className="vfra-follow" onClick={() => handleShowFollowDetail("following")}>
              <span className="vfra-follow-count">{user.follow_detail ? user.follow_detail.total_followees : 0}</span>
              following
            </span>
          </Flex>

          <Modal
            centered
            closable={true}
            width={isMobileView ? "100%" : "30%"}
            open={showFollowDetail}
            footer={null}
            getContainer={false}
            onCancel={() => setShowFollowDetail(false)}>
            <Flex justify="center" style={{ fontWeight: "bold", fontSize: "1rem" }}>
              {user ? user.name : ""}
            </Flex>
            <Tabs activeKey={activeKey} items={items} onChange={(e) => setActiveKey(e)} />
          </Modal>
        </>
      )}
    </>
  );
};

export default UserFollow;
