import { useState, useEffect } from "react";
import { Flex, Button, message, Space, Skeleton, Modal, Row, Col, Input, Spin } from "antd";
import { PlusOutlined, ShareAltOutlined, EditOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { follow, unfollow } from "../../../../services/supabaseClient";
import { useAuth } from "../../../../components/contexts/AuthContext";
import {
  getAvatarUploadKey,
  uploadToSignedURL,
  getPublicUrl,
  updateUserInfo,
} from "../../../../services/supabaseClient";
import { imageConverterToJpg } from "../../../../utils/service";
import { pathData } from "../../../../utils/routes";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import UserAvatar from "./UserAvatar";

const { TextArea } = Input;
const labelStyle = {
  fontWeight: "700",
  fontSize: "0.9rem",
};

const ProfileActions = ({ dataUser, loading, isYourself }) => {
  const { user, refreshVfraUser, openLoginModal } = useAuth();
  const { isMobileView } = useWindowSize();

  const [isFollowing, setIsFollowing] = useState(false);

  const onFollow = async () => {
    if (!user) {
      openLoginModal();
      return;
    }

    try {
      setIsFollowing((prev) => !prev);

      const payload = {
        followee_id: dataUser.id,
      };

      follow(payload);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onUnfollow = async () => {
    try {
      setIsFollowing((prev) => !prev);

      const payload = {
        followee_id: dataUser.id,
      };
      unfollow(payload);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleShareProfile = () => {
    const currentHost = window.location.host;

    const convertedUrl = currentHost + `${pathData.profile}/${dataUser.id}`;

    navigator.clipboard
      .writeText(convertedUrl)
      .then(() => {
        message.success("User's URL copied to clipboard!");
      })
      .catch(() => {
        message.error("Failed to copy URL.");
      });
  };

  // hanlde edit profile
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [valueUsername, setValueUsername] = useState(null);
  const [valueDescription, setValueDescription] = useState(null);
  const [avtFile, setAvtFile] = useState(null);

  useEffect(() => {
    if (dataUser) {
      setIsFollowing(dataUser.follow_detail.followed);
      setValueUsername(dataUser.name);
      setValueDescription(dataUser.description);
    }
  }, [dataUser]);

  const onSubmitEditModal = async () => {
    try {
      setIsSubmiting(true);
      if (!valueUsername) {
        message.warning("Username is required!");
        return;
      }

      const payload = {
        name: valueUsername.trim(),
        description: valueDescription ? valueDescription.trim() : "",
      };

      if (avtFile) {
        const { error, data } = await getAvatarUploadKey();

        if (error) {
          message.error(error.message);
          return;
        } else {
          const { path, token } = data;

          //convert image to jpg
          const convertRes = await imageConverterToJpg(avtFile, 2);

          if (convertRes.error) {
            message.error(convertRes.error.message);
            return;
          } else {
            const res1 = await uploadToSignedURL("avatars", path, token, convertRes.data);

            if (res1.error) {
              message.error(res1.error.message);
              return;
            } else {
              const { path } = res1.data;
              const res2 = await getPublicUrl("avatars", path);

              if (res2.error) {
                message.error(res2.error.message);
                return;
              } else {
                const { publicUrl } = res2.data;
                payload.avatar = publicUrl;
              }
            }
          }
        }
      }

      const res3 = await updateUserInfo(payload);

      if (res3.error) {
        message.error(res3.error.message);
      } else {
        refreshVfraUser();
        message.success("Updated successfully!");
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsSubmiting(false);
    }
  };

  const itemSpanLabel = isMobileView ? 24 : 5;
  const itemSpan = isMobileView ? 24 : 14;

  const btnSize = isMobileView ? "small" : "default";

  return (
    <Flex gap={"0.5rem"}>
      {loading && (
        <Space size={12}>
          <Skeleton.Button active={true} shape="round" size={btnSize} />
          <Skeleton.Button active={true} shape="circle" size={btnSize} />
        </Space>
      )}

      {!loading && !isYourself && isFollowing && (
        <Button
          onClick={onUnfollow}
          size={btnSize}
          type="primary"
          shape="round"
          style={{ fontWeight: "500" }}
          icon={<UserSwitchOutlined />}>
          Following
        </Button>
      )}

      {!loading && !isYourself && !isFollowing && (
        <Button size={btnSize} shape="round" icon={<PlusOutlined />} onClick={onFollow}>
          <span style={{ fontSize: isMobileView ? "0.75rem" : "0.8125rem" }}>Follow</span>
        </Button>
      )}

      {!loading && isYourself && (
        <Button size={btnSize} shape="round" icon={<EditOutlined />} onClick={() => setOpenEdit(true)}>
          <span style={{ fontSize: isMobileView ? "0.75rem" : "0.8125rem" }}> Edit profile</span>
        </Button>
      )}

      {!loading && <Button size={btnSize} shape="circle" icon={<ShareAltOutlined />} onClick={handleShareProfile} />}

      {!loading && isYourself && (
        <Modal
          centered
          getContainer={false}
          title={<span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>Edit profile</span>}
          width={isMobileView ? "100%" : "50%"}
          open={openEdit}
          onCancel={() => setOpenEdit(false)}
          onOk={onSubmitEditModal}>
          <Spin spinning={isSubmiting}>
            <Flex vertical gap={isMobileView ? "1rem" : "2rem"} style={{ padding: isMobileView ? "1rem 0" : "2rem 0" }}>
              <Row>
                <Col span={itemSpanLabel}>
                  <span style={labelStyle}>Profile photo</span>
                </Col>
                <Col span={itemSpan}>
                  <Flex justify="center">
                    <UserAvatar user={dataUser} onAvatarChange={(file) => setAvtFile(file)} />
                  </Flex>
                </Col>
              </Row>

              <Row>
                <Col span={itemSpanLabel}>
                  <span style={labelStyle}>Username</span>
                </Col>
                <Col span={itemSpan}>
                  <Flex align="center">
                    <Input
                      value={valueUsername}
                      onChange={(e) => setValueUsername(e.target.value)}
                      placeholder="Enter your username..."
                      variant="filled"
                    />
                  </Flex>
                </Col>
              </Row>

              <Row>
                <Col span={itemSpanLabel}>
                  <span style={labelStyle}>Description</span>
                </Col>
                <Col span={itemSpan}>
                  <Flex justify="center">
                    <TextArea
                      value={valueDescription}
                      onChange={(e) => setValueDescription(e.target.value)}
                      placeholder="Share somthings about yourself..."
                      autoSize={{
                        minRows: 5,
                      }}
                      showCount
                      maxLength={200}
                      variant="filled"
                    />
                  </Flex>
                </Col>
              </Row>
            </Flex>
          </Spin>
        </Modal>
      )}
    </Flex>
  );
};

export default ProfileActions;
