import { Flex, Skeleton } from "antd";
import useWindowSize from "../../../../components/hooks/useWindowSize";

const UserName = ({ loading, user }) => {
  const { isMobileView } = useWindowSize();
  const btnSize = isMobileView ? "small" : "default";

  if (loading)
    return (
      <Flex vertical gap={12}>
        <Skeleton.Input active={true} size={btnSize} />
      </Flex>
    );
  else {
    const userName = user ? user.name : null;
    return <h1 style={{ margin: 0, fontSize: isMobileView ? "1.2rem" : "1.4rem" }}>{userName}</h1>;
  }
};

export default UserName;
