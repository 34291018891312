import "./index.css";
import { Flex, Rate } from "antd";

const Rating = ({ badge = 0, disabled = true, onChange, ...props }) => {
  const convertedBadge = badge.toFixed(2);
  return (
    <Flex align="center" {...props} className="vfra-custom-rate" gap={6}>
      <span
        style={{
          fontSize: "0.7rem",
          fontWeight: "500",
        }}>
        {convertedBadge}
      </span>
      <Rate
        value={convertedBadge}
        onChange={onChange}
        disabled={disabled}
        defaultValue={0}
        style={{ fontSize: "0.7rem" }}
      />
    </Flex>
  );
};

export default Rating;
