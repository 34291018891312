import "./index.css";
import { useState, useCallback, useEffect } from "react";
import { message, Flex, Empty, Skeleton } from "antd";
import { searchPosts, getFeeds } from "../../../services/supabaseClient";
import { useSearch } from "../../../components/contexts/SearchContext";
import { localStorage_fields, setItemWithExpiry } from "../../../utils/localstorage";
import { TIME_RESET_NEW_FEED } from "../../../utils/service";
import { debounce } from "lodash";
import useWindowSize from "../../../components/hooks/useWindowSize";
import PostItem from "./PostItem";
import PostListLoading from "./PostListLoading";
const PAGE_SIZE = 20;

const values = [30, 40, 50, 60];
const getRandomHeight = () => `${values[Math.floor(Math.random() * values.length)]}vh`;

const PostList = ({ isMobileSearch = false }) => {
  const element = document.getElementById(isMobileSearch ? "vfra-search-tab-post" : "vfra-home-tab-post");
  const { isMobileView } = useWindowSize();
  const isMobileHome = isMobileView && !isMobileSearch;

  const { searchValue } = useSearch();
  const [searchValueLocal, setSearchValueLocal] = useState(isMobileHome ? null : searchValue);

  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingmore] = useState(false);
  const [allowLoadingmore, setAllowLoadingMore] = useState(true);

  useEffect(() => {
    if (!isMobileHome) {
      setSearchValueLocal(searchValue);
      if (element) {
        element.scrollTo(0, 0);
      }

      setPage(1);
    }
  }, [searchValue, isMobileHome, element]);

  const fetchData = useCallback(async (page) => {
    try {
      if (page > 1) setLoadingmore(true);
      else setLoading(true);

      const { data, c_post } = await getFeeds(page, PAGE_SIZE);

      const fetchedData = [];

      if (c_post && c_post.length > 0) {
        c_post.forEach((p) => {
          if (p.post !== null) {
            fetchedData.push({
              ...p,
              isCPost: true,
            });
          }
        });
      }

      data.forEach((p) => {
        if (p.post) {
          const index = fetchedData.findIndex((e) => e.id === p.post.id);
          if (index === -1) {
            fetchedData.push(p.post);
          }
        }
      });

      if (fetchedData && fetchedData.length > 0) {
        if (fetchedData.length < PAGE_SIZE) setAllowLoadingMore(false);
        else setAllowLoadingMore(true);

        setPosts((prev) => {
          let newPosts = [...fetchedData];
          if (page > 1) {
            newPosts = [...prev, ...fetchedData];
          }

          setItemWithExpiry(localStorage_fields.searchPostsResults, newPosts, TIME_RESET_NEW_FEED);
          return newPosts;
        });
      } else {
        setAllowLoadingMore(false);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      if (page > 1) setLoadingmore(false);
      else setLoading(false);
    }
  }, []);

  const onSearch = useCallback(
    async (page) => {
      try {
        if (page > 1) setLoadingmore(true);
        else setLoading(true);

        let res = null;

        if (searchValueLocal) {
          res = await searchPosts(searchValueLocal, PAGE_SIZE);
        } else {
          res = await getFeeds(page, PAGE_SIZE);
        }

        if (res.error) {
          message.error(res.error.message);
        } else {
          if (searchValueLocal) {
            const { data } = res;
            if (data && data.length > 0) {
              if (data.length < PAGE_SIZE) setAllowLoadingMore(false);
              else setAllowLoadingMore(true);

              setPosts((prev) => {
                let newPosts = data;
                if (page > 1) {
                  newPosts = [...prev, ...data];
                }

                setItemWithExpiry(localStorage_fields.searchPostsResults, newPosts, TIME_RESET_NEW_FEED);
                return newPosts;
              });
            } else {
              setPosts([]);
              localStorage.removeItem(localStorage_fields.searchPostsResults);
              setAllowLoadingMore(false);
            }
          } else {
            const { data, c_post } = res;
            const fetchedData = [];

            if (c_post && c_post.length > 0) {
              c_post.forEach((p) => {
                if (p.post !== null) {
                  fetchedData.push({
                    ...p,
                    isCPost: true,
                  });
                }
              });
            }

            data.forEach((p) => {
              if (p.post) {
                const index = fetchedData.findIndex((e) => e.id === p.post.id);
                if (index === -1) {
                  fetchedData.push(p.post);
                }
              }
            });

            if (fetchedData && fetchedData.length > 0) {
              if (fetchedData.length < PAGE_SIZE) setAllowLoadingMore(false);
              else setAllowLoadingMore(true);

              setPosts((prev) => {
                let newPosts = [...fetchedData];
                if (page > 1) {
                  newPosts = [...prev, ...fetchedData];
                }

                setItemWithExpiry(localStorage_fields.searchPostsResults, newPosts, TIME_RESET_NEW_FEED);
                return newPosts;
              });
            } else {
              setAllowLoadingMore(false);
            }
          }
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        if (page > 1) setLoadingmore(false);
        else setLoading(false);
      }
    },
    [searchValueLocal]
  );

  useEffect(() => {
    if (!isMobileHome) {
      onSearch(page);
    } else {
      fetchData(page);
    }
  }, [onSearch, fetchData, page, isMobileHome]);

  const itemSpan = isMobileView ? 2 : 5;
  const itemWidth = isMobileView ? "50%" : "20%";
  const postsToShow = [];

  if (posts.length > 0) {
    posts.forEach((e, i) => {
      const index = i % itemSpan;

      if (typeof postsToShow[index] === "object") {
        postsToShow[index].push(e);
      } else {
        postsToShow[index] = [e];
      }
    });
  }

  const gapItem = isMobileView ? 8 : 16;

  // handle auto-loadmore
  const handleScroll = debounce(() => {
    const scrollTop = element.scrollTop;
    const viewportHeight = element.clientHeight;
    const totalHeight = element.scrollHeight;
    const indexLoadmore = isMobileView ? 100 : 150;

    if (scrollTop + viewportHeight >= totalHeight - indexLoadmore && !loading && !loadingMore && allowLoadingmore) {
      setPage((prevPage) => prevPage + 1);
    }
  }, 200);

  useEffect(() => {
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    // Cleanup the event listener
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll, element]);

  return (
    <>
      {loading && <PostListLoading />}

      {!loading && posts.length === 0 && (
        <Empty
          style={{ marginTop: "3rem" }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Flex vertical align="center">
              <p style={{ fontWeight: "bold", fontSize: "0.9rem" }}>No posts found!</p>
            </Flex>
          }
        />
      )}

      {!loading && posts.length > 0 && (
        <Flex justify="start" gap={gapItem}>
          {postsToShow.map((item, i) => {
            return (
              <Flex key={`vfra-posts-to-show-${i}`} vertical style={{ width: itemWidth }} gap={gapItem}>
                {item.map((post) => {
                  return <PostItem key={post.id} post={post} />;
                })}

                {loadingMore && (
                  <Skeleton.Node
                    active={true}
                    style={{ borderRadius: "1rem", width: "100%", height: getRandomHeight() }}
                  />
                )}
              </Flex>
            );
          })}
        </Flex>
      )}

      <br></br>
    </>
  );
};

export default PostList;
