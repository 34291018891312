import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { message, Skeleton, Empty, Flex } from "antd";
import { getPerfumes } from "../../../services/supabaseClient";
import ListPerfume from "./ListPerfume";

const Favorites = () => {
  const { userId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [likedPerfumes, setLikedPerfumes] = useState([]);
  const [ratedPerfumes, setRatedPerfumes] = useState([]);
  const [commentedPerfumes, setCommentedPerfumes] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const { error, data } = await getPerfumes(userId);
      if (error) {
        message.error(error);
      } else {
        const ratedPerfumesList = [];
        const likedPerfumesList = [];
        const commentedPerfumesList = [];

        if (data.length > 0) {
          data.forEach((e) => {
            if (e.tag.includes("RATED")) {
              ratedPerfumesList.push(e);
            } else if (e.tag.includes("LIKED")) {
              likedPerfumesList.push(e);
            } else if (e.tag.includes("COMMENTED")) {
              commentedPerfumesList.push(e);
            }
          });
        }

        setRatedPerfumes(ratedPerfumesList);
        setLikedPerfumes(likedPerfumesList);
        setCommentedPerfumes(commentedPerfumesList);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Flex vertical gap={"2rem"} className="vfra-container" style={{ marginBottom: "2rem" }}>
      {isLoading && (
        <Skeleton
          paragraph={{
            rows: 10,
          }}
          active
        />
      )}

      {!isLoading && likedPerfumes.length === 0 && commentedPerfumes.length === 0 && ratedPerfumes.length === 0 && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      {likedPerfumes.length > 0 && <ListPerfume title="Favorites" perfumes={likedPerfumes} />}

      {commentedPerfumes.length > 0 && <ListPerfume title="Reviewed" perfumes={commentedPerfumes} />}

      {ratedPerfumes.length > 0 && <ListPerfume title="Rated" perfumes={ratedPerfumes} />}
    </Flex>
  );
};

export default Favorites;
