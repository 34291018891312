import "./index.css";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import HeaderLayout from "../HeaderLayout";

const HomeLayout = () => {
  return (
    <Layout>
      <HeaderLayout />
      <div className="vfra-content-home">
        <Outlet />
      </div>
    </Layout>
  );
};

export default HomeLayout;
