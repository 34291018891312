import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { ConfigProvider } from "antd";
import { app_versions } from "./utils/app-version";
import { clearLocalStorageUpdateVersion } from "./utils/localstorage";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./components/contexts/AuthContext";
import { SearchProvider } from "./components/contexts/SearchContext";
import { PerfumeProvider } from "./components/contexts/PefumeContext";
import { CartProvider } from "./components/contexts/CartContext";

const APP_VERSION = app_versions.current.value;

const checkAndUpdateLocalStorage = () => {
  const storedVersion = localStorage.getItem("app_version");

  if (!storedVersion || storedVersion !== APP_VERSION) {
    console.log("Deleted outdated localStorage data. Welcome to Scentub version ", APP_VERSION);
    clearLocalStorageUpdateVersion();
    localStorage.setItem("app_version", APP_VERSION);
  }
};

checkAndUpdateLocalStorage();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "var(--gray-backgroud-color)",
        },
      }}>
      <AuthProvider>
        <CartProvider>
          <SearchProvider>
            <PerfumeProvider>
              <App />
            </PerfumeProvider>
          </SearchProvider>
        </CartProvider>
      </AuthProvider>
    </ConfigProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
