import "./index.css";
import { Flex, Skeleton } from "antd";
import useWindowSize from "../../../../../components/hooks/useWindowSize";
import { useSearch } from "../../../../../components/contexts/SearchContext";

const values = [30, 40, 50, 60];
const getRandomHeight = () => `${values[Math.floor(Math.random() * values.length)]}vh`;

const PostListLoading = () => {
  const { isMobileView } = useWindowSize();
  const { showSearchMobile } = useSearch();
  const gapItem = isMobileView ? 8 : "1rem";

  return (
    <Flex
      style={{ marginTop: showSearchMobile ? "0" : "1rem" }}
      justify="space-between"
      gap={gapItem}
      className="vfra-post-list-loading-container">
      {Array.from({ length: isMobileView ? 2 : 5 }).map((_, i) => (
        <Flex key={i} vertical gap={gapItem} style={{ width: "100%" }}>
          <Skeleton.Node active={true} style={{ height: getRandomHeight() }} />
          <Skeleton.Node active={true} style={{ height: getRandomHeight() }} />
        </Flex>
      ))}
    </Flex>
  );
};

export default PostListLoading;
