import { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Flex, Skeleton, message, Typography } from "antd";
import { getPerfumeById } from "../../../services/supabaseClient";
import { usePerfume } from "../../../components/contexts/PefumeContext";
import useWindowSize from "../../../components/hooks/useWindowSize";
import { pathData } from "../../../utils/routes";
import { getPerfumeDescription } from "../../../utils/service";

const { Paragraph } = Typography;

const PerfumeInfo = () => {
  const navigate = useNavigate();
  const { perfumeId } = useParams();
  const { isMobileView } = useWindowSize();
  const { setPerfumeContext } = usePerfume();

  const [perfume, setPerfume] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      const { data, error } = await getPerfumeById(perfumeId);
      if (error) {
        message.error(error.message);
        setPerfume(null);
        setPerfumeContext(null);
      } else {
        setPerfume(data[0]);
        setPerfumeContext(data[0]);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [perfumeId, setPerfumeContext]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const imgWidth = isMobileView ? "6rem" : "9rem";
  const perfumeDescription = perfume && getPerfumeDescription(perfume);

  return (
    <>
      {loading && (
        <Skeleton
          active
          paragraph={{
            rows: isMobileView ? 3 : 5,
          }}
        />
      )}
      {!loading && (
        <Flex gap={12} align="center">
          <img alt="" style={{ width: imgWidth }} src={perfume.image_url} />
          <Flex vertical gap={12}>
            <h1
              onClick={() => navigate(`${pathData.perfume}/${perfume.id}`)}
              style={{ margin: 0, fontSize: "1rem", fontWeight: "bold", cursor: "pointer" }}>
              {perfume.name}
            </h1>
            <Flex gap={12} align="center" style={{ cursor: "pointer" }}>
              <h3
                style={{ margin: 0, fontSize: "0.815rem", fontWeight: "300" }}
                onClick={() => navigate(`${pathData.brand}/${perfume.brand_id}`)}>
                {perfume.brand_name.toUpperCase()}
              </h3>
              <img
                onClick={() => navigate(`${pathData.brand}/${perfume.brand_id}`)}
                alt={perfume.brand_name}
                style={{ width: isMobileView ? "1rem" : "2rem", borderRadius: "0.5rem" }}
                src={perfume.brand_logo_url}
              />
            </Flex>
            <Paragraph
              style={{ fontSize: "0.9rem", margin: 0 }}
              ellipsis={{
                rows: 3,
                expandable: "collapsible",
                symbol: (expanded) => (expanded ? "less" : "more"),
              }}>
              {perfumeDescription}
            </Paragraph>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default PerfumeInfo;
