import "./index.css";
import { useState } from "react";
import { Button, Upload, message, Image } from "antd";
import { CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { getBase64 } from "../../../../utils/service";
const { Dragger } = Upload;

const VideoDragger = ({ onImageChange }) => {
  const [imageList, setImageList] = useState([]);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return false;
  };

  const props = {
    multiple: true,
    maxCount: 5,
    fileList: imageList,
    beforeUpload,
    async onChange({ fileList }) {
      for (let file of fileList) {
        file.previewSrc = await getBase64(file.originFileObj);
      }

      setImageList(fileList);
      onImageChange(fileList);
    },

    itemRender() {
      return null;
    },
  };

  const handleRemoveImage = (e, imageId) => {
    e.stopPropagation();
    const newImageList = imageList.filter((image) => image.uid !== imageId);
    setImageList(newImageList);
    onImageChange(newImageList);
  };

  return (
    <Dragger {...props}>
      {imageList.length === 0 && (
        <>
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ fontSize: "2rem" }} />
          </p>
          <p className="ant-upload-text" style={{ fontSize: "0.8rem" }}>
            Click or drag file to upload
          </p>
        </>
      )}

      {imageList.length > 0 && (
        <div onClick={(e) => e.stopPropagation()}>
          <Image.PreviewGroup>
            {imageList.map((image) => (
              <div
                key={image.uid}
                style={{
                  padding: "0 12px",
                  display: "inline-block",
                  width: `20%`,
                  position: "relative",
                }}>
                <Image height="100px" src={image.previewSrc} onClick={(e) => e.stopPropagation()} />

                <Button
                  onClick={(e) => handleRemoveImage(e, image.uid)}
                  size="small"
                  type="text"
                  className="vfra-remove-image-btn"
                  shape="circle"
                  icon={<CloseCircleOutlined />}
                />
              </div>
            ))}
          </Image.PreviewGroup>
        </div>
      )}
    </Dragger>
  );
};

export default VideoDragger;
