import "./index.css";
import { useNavigate } from "react-router-dom";
import { Flex } from "antd";
import { useSearch } from "../../../../components/contexts/SearchContext";
import { pathData } from "../../../../utils/routes";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import ImageWithLoader from "../../../../components/common/ImageWithLoader";

const PerfumeItem = ({ item }) => {
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();

  const { setShowSearchMobile } = useSearch();
  const handleClick = () => {
    if (isMobileView) setShowSearchMobile(false);
    navigate(`${pathData.perfume}/${item.pid}`);
  };

  const loadingWidth = isMobileView ? "12vw" : "7vw";

  return (
    <Flex
      className="vfra-perfume-list-item-container"
      onClick={handleClick}
      justify="start"
      align="center"
      gap={isMobileView ? "0.75rem" : "0.5rem"}>
      <ImageWithLoader src={item.image_url} style={{ height: "100%" }} loadingWidth={loadingWidth} />
      <Flex vertical>
        <span style={{ fontWeight: "bold", fontSize: "1rem" }} className="two-line-ellipsis">
          {item.name}
        </span>
        <span className="one-line-ellipsis" style={{ fontSize: "0.8rem" }}>
          {item.brand_name}
        </span>
      </Flex>
    </Flex>
  );
};

export default PerfumeItem;
