import { useEffect, useState } from "react";
import { Tabs, message, Spin, Flex } from "antd";
import { getPlaylists } from "../../../../../services/musicServer";
import Playlist from "./Playlist";

const TabSound = ({ selectedSound, onSelect }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getPlaylists();
        if (res["subsonic-response"].status === "ok") {
          const playlist = res["subsonic-response"].playlists.playlist;
          const newItems = playlist.map((e) => {
            return {
              key: e.id,
              label: e.name,
              children: <Playlist key={e.id} id={e.id} selectedSound={selectedSound} onSelect={onSelect} />,
            };
          });

          setItems(newItems);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedSound, onSelect]);

  return (
    <>
      {loading && (
        <Flex justify="center" align="center">
          <Spin size="small" />
        </Flex>
      )}

      {!loading && items.length > 0 && <Tabs defaultActiveKey="1" items={items} />}

      {!loading && items.length === 0 && (
        <Flex justify="center" align="center">
          No sound found!
        </Flex>
      )}
    </>
  );
};

export default TabSound;
