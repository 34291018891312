import "./index.css";
import { useEffect, useCallback, useState, useRef } from "react";
import { Button, Flex, message } from "antd";
import _ from "lodash";
import { getFeeds, markViewedPost } from "../../services/supabaseClient";
import { useAuth } from "../../components/contexts/AuthContext";
import { PAGE_SIZE_NEW_FEED, TIME_RESET_NEW_FEED, MIN_TIME_SPENT, AUTH_STATUS } from "../../utils/service";
import { setItemWithExpiry, getItemWithExpiry, localStorage_fields } from "../../utils/localstorage";
import Post from "../../components/common/Posts/Post";
import PostAudio from "../../components/common/Posts/PostAudio";
import PostLoading from "../../components/common/Posts/PostLoading";

const Explore = () => {
  window.scrollTo(0, 0);
  const { user, openLoginModal, authStatus } = useAuth();

  const initPosts = getItemWithExpiry(localStorage_fields.posts) ?? [];
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadmore, setIsLoadmore] = useState(false);
  const [posts, setPosts] = useState(initPosts);
  const [isEnding, setIsEnding] = useState(false);

  // handle group viewed
  const viewTimesRef = useRef([]);

  const postRefs = useRef([]);
  const enterTimes = useRef({});

  const onMarkViewedPosts = () => {
    const unsentPosts = viewTimesRef.current.filter(
      (e) => e.timeChanged && (e.isFirstTime || e.timeSpent > MIN_TIME_SPENT)
    );

    try {
      const payload = unsentPosts.map((e) => {
        return {
          pid: e.id,
          s: e.startTime,
          e: e.startTime + e.timeSpent,
        };
      });
      markViewedPost(payload);

      viewTimesRef.current = viewTimesRef.current.map((v) => {
        return {
          ...v,
          timeChanged: false,
        };
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchData = useCallback(async (isLoadmore = false) => {
    try {
      if (isLoadmore) setIsLoadmore(true);

      let page = getItemWithExpiry(localStorage_fields.page) ?? 0;
      page++;
      setItemWithExpiry(localStorage_fields.page, page, TIME_RESET_NEW_FEED);

      let { error, data, c_post } = await getFeeds(page, PAGE_SIZE_NEW_FEED);

      if (error) {
        message.error(error.message);
      } else {
        const fetchData = [];

        if (c_post && c_post.length > 0) {
          c_post.forEach((p) => {
            if (p.post !== null) {
              fetchData.push({
                ...p,
                isCPost: true,
              });
            }
          });
        }

        data.forEach((p) => {
          if (p.post) {
            const index = fetchData.findIndex((e) => e.post.id === p.post.id);
            if (index === -1) {
              fetchData.push(p);
            }
          }
        });

        if (fetchData && fetchData.length > 0) {
          setIsEnding(false);

          setPosts((prev) => {
            let newPosts = fetchData;
            if (isLoadmore) {
              newPosts = [...prev, ...fetchData];
            }

            setItemWithExpiry(localStorage_fields.posts, newPosts, TIME_RESET_NEW_FEED);
            return newPosts;
          });
        }

        if (fetchData.length < PAGE_SIZE_NEW_FEED) {
          setItemWithExpiry(localStorage_fields.page, 0, TIME_RESET_NEW_FEED);
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      if (isLoadmore) setIsLoadmore(false);
      else setIsLoading(false);
    }
  }, []);

  //handle cache last index post
  const cacheLastIndex = _.debounce((postIndex) => {
    setItemWithExpiry(localStorage_fields.lastIndexPost, postIndex, TIME_RESET_NEW_FEED);
  }, MIN_TIME_SPENT);

  // handle force reload (f5)
  useEffect(() => {
    if (authStatus === AUTH_STATUS.authenticating) return;
    const handleBeforeUnload = () => {
      localStorage.removeItem(localStorage_fields.posts);
      localStorage.removeItem(localStorage_fields.lastIndexPost);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [authStatus]);

  useEffect(() => {
    if (authStatus === AUTH_STATUS.authenticating) return;
    const localPosts = getItemWithExpiry(localStorage_fields.posts);
    if (localPosts) {
      let lastIndexPost = getItemWithExpiry(localStorage_fields.lastIndexPost);

      if (lastIndexPost) {
        lastIndexPost = parseInt(lastIndexPost, 10);

        if (lastIndexPost < localPosts.length && lastIndexPost !== 0) {
          postRefs.current[lastIndexPost].scrollIntoView({
            behavior: "smooth", // or 'smooth'
            //block: "center", // or 'end', 'center', 'nearest'
            //inline: "center", // or 'start', 'center', 'end'
          });
        }
      }
    } else {
      setIsLoading(true);
      fetchData();
    }
  }, [fetchData, user, authStatus]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const postIndex = parseInt(entry.target.getAttribute("data-index"), 10);
          const postId = posts[postIndex].post.id;

          if (entry.isIntersecting) {
            //handle reset sound
            let lastIndexPost = getItemWithExpiry(localStorage_fields.lastIndexPost);
            if (postIndex !== lastIndexPost) setPlaying(true);

            //handle cache post
            cacheLastIndex(postIndex);
            setCurrentIndex(postIndex);

            if (posts.length - postIndex < 2 && !isEnding && !isLoadmore) {
              onMarkViewedPosts();
              fetchData(true);
            }

            // Record the time when the post enters the viewport
            enterTimes.current[postIndex] = new Date().getTime();
          } else {
            const exitTime = new Date().getTime();
            const enterTime = enterTimes.current[postIndex];

            if (enterTime) {
              const timeSpent = exitTime - enterTime;

              const viewTimes = viewTimesRef.current;
              const index = viewTimes.findIndex((e) => e.id === postId);
              viewTimes.push({
                id: postId,
                startTime: enterTime,
                timeSpent: timeSpent,
                isFirstTime: index >= 0 ? false : true,
                timeChanged: true,
              });

              delete enterTimes.current[postIndex];
            }
          }
        });
      },
      { threshold: 0.7 }
    );

    const currentPostRefs = postRefs.current;

    currentPostRefs.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      currentPostRefs.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [fetchData, user, posts, isEnding, cacheLastIndex, isLoadmore]);

  const handleFeedRender = (el, index) => {
    postRefs.current[index] = el;
  };

  // handle follow change
  const handleFollowChange = (userId) => {
    posts.forEach((post) => {
      if (userId === post.post.owner.id) {
        post.post.owner.followed = true;
      }
    });

    setPosts([...posts]);
  };

  // handle audio
  const [currentIndex, setCurrentIndex] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(true);

  const triggerPlaySound = () => {
    setPlaying((prev) => !prev);
  };

  const triggerMuteSound = () => {
    setMuted((prev) => !prev);
  };

  return (
    <>
      {isLoading && <PostLoading />}

      {!isLoading && posts.length === 0 && (
        <Flex className="vfra-not-found" justify="center" align="center">
          <i>No post found!</i>
        </Flex>
      )}

      {/* new feed */}
      {!isLoading && posts.length > 0 && (
        <div className="vfra-infinite-view">
          {posts.map((post, index) => (
            <Post
              key={`${post.post.id}-${index}`}
              post={post.post}
              ref={(el) => handleFeedRender(el, index)}
              index={index}
              onChangeFollow={handleFollowChange}
              playing={playing && currentIndex === index}
              triggerPlaySound={triggerPlaySound}
              muted={muted}
              triggerMuteSound={triggerMuteSound}
            />
          ))}

          {posts[currentIndex].post.music_data && (
            <PostAudio
              id={posts[currentIndex].post.id}
              muted={muted}
              data={posts[currentIndex].post.music_data}
              playing={playing}
              onPause={() => setPlaying(false)}
            />
          )}

          {isEnding && (
            <Flex justify="center" style={{ marginTop: "-32px" }}>
              <span>Nothing new!</span>
            </Flex>
          )}

          {!user && (
            <Flex align="center" justify="center" style={{ marginTop: "-36px" }}>
              <span>
                Please{" "}
                <Button size="small" shape="round" onClick={() => openLoginModal()}>
                  Sign in
                </Button>{" "}
                to explore more!
              </span>
            </Flex>
          )}
        </div>
      )}

      {!isLoading && isEnding && posts.length === 0 && (
        <Flex justify="center" style={{ paddingTop: "20%" }}>
          <i>There are no posts, please visit later!</i>
        </Flex>
      )}
    </>
  );
};

export default Explore;
