import { useEffect, useState } from "react";
import { getSong, getAvatar } from "../../../services/musicServer";

const AudioAvatar = ({ trackId, AvatarSrc = null, ...props }) => {
  const [avtSrc, setAvtSrc] = useState(AvatarSrc);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getSong(trackId);
        if (res[`subsonic-response`].status === "ok") {
          const tempAvtSrc = getAvatar(res[`subsonic-response`].song.coverArt);
          setAvtSrc(tempAvtSrc);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (!AvatarSrc) fetchData();
  }, [trackId, AvatarSrc]);

  return <img alt="" {...props} src={avtSrc} />;
};

export default AudioAvatar;
