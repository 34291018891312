import { useState, useCallback, useEffect } from "react";
import { Flex, Row, Col, Empty, message } from "antd";
import { search_perfumes } from "../../../services/meilisearchPerfumes";
import { useSearch } from "../../../components/contexts/SearchContext";
import { debounce } from "lodash";
import useWindowSize from "../../../components/hooks/useWindowSize";
import PerfumeLoading from "./PerfumesLoading";
import PerfumeItem from "./PerfumeItem";
const PAGE_SIZE = 24;

const PerfumeList = ({ isMobileSearch = false }) => {
  const element = document.getElementById(isMobileSearch ? "vfra-search-tab-perfume" : "vfra-home-tab-perfume");
  const { isMobileView } = useWindowSize();
  const isMobileHome = isMobileView && !isMobileSearch;

  const { searchValue } = useSearch();
  const [searchValueLocal, setSearchValueLocal] = useState(isMobileHome ? null : searchValue);

  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [perfumes, setPerfumes] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!isMobileHome) {
      setSearchValueLocal(searchValue);
      if (element) {
        element.scrollTo(0, 0);
      }

      setPage(1);
    }
  }, [searchValue, isMobileHome, element]);

  const fetchData = useCallback(async (page) => {
    try {
      if (page > 1) setLoadingMore(true);
      else setLoading(true);

      const searchResults = await search_perfumes("", page, PAGE_SIZE);

      setTotal(searchResults.estimatedTotalHits);
      if (page > 1) {
        setPerfumes((prev) => [...prev, ...searchResults.hits]);
      } else {
        setPerfumes(searchResults.hits);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      if (page > 1) setLoadingMore(false);
      else setLoading(false);
    }
  }, []);

  const onSearch = useCallback(
    async (page) => {
      try {
        if (page > 1) setLoadingMore(true);
        else setLoading(true);
        const searchResults = await search_perfumes(searchValueLocal, page, PAGE_SIZE);

        setTotal(searchResults.estimatedTotalHits);
        if (page > 1) {
          setPerfumes((prev) => [...prev, ...searchResults.hits]);
        } else {
          setPerfumes(searchResults.hits);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        if (page > 1) setLoadingMore(false);
        else setLoading(false);
      }
    },
    [searchValueLocal]
  );

  useEffect(() => {
    if (!isMobileHome) {
      onSearch(page);
    } else {
      fetchData(page);
    }
  }, [onSearch, fetchData, page, isMobileHome]);

  // handle auto-loadmore
  const handleScroll = debounce(() => {
    const scrollTop = element.scrollTop;
    const viewportHeight = element.clientHeight;
    const totalHeight = element.scrollHeight;
    const indexLoadmore = isMobileView ? 100 : 150;

    if (
      scrollTop + viewportHeight >= totalHeight - indexLoadmore &&
      !loading &&
      !loadingMore &&
      total > perfumes.length
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  }, 200);

  useEffect(() => {
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    // Cleanup the event listener
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll, element]);

  return (
    <>
      {loading && <PerfumeLoading length={isMobileView ? 8 : 24} />}

      {!loading && perfumes.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Flex vertical align="center">
              <p style={{ fontWeight: "bold", fontSize: "0.9rem" }}>No perfumes found!</p>
            </Flex>
          }
        />
      )}

      {!loading && perfumes.length > 0 && (
        <Row gutter={!isMobileView ? [24, 24] : [12, 12]} justify="center" vertical="vertical">
          {perfumes.map((item) => {
            const keyItem = isMobileSearch ? `search-mobile-${item.id}` : item.id;
            return (
              <Col key={keyItem} span={!isMobileView ? 6 : 24}>
                <PerfumeItem item={item} />
              </Col>
            );
          })}
        </Row>
      )}

      {loadingMore && <PerfumeLoading length={isMobileView ? 2 : 8} />}
    </>
  );
};

export default PerfumeList;
