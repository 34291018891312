import "./index.css";
import { Row, Col } from "antd";
import useWindowSize from "../../components/hooks/useWindowSize";

const Policy = () => {
  const { isMobileView } = useWindowSize();

  return (
    <Row>
      <Col span={isMobileView ? 24 : 16} offset={isMobileView ? 0 : 4} className="vfra-policy-container">
        <h1 style={{ fontSize: "3rem" }}>Privacy Policy</h1>
        <p>
          Scentub (“we,” “our,” or “us”) is dedicated to protecting the privacy of our users. This Privacy Policy
          explains how we collect, use, and safeguard your information when you use our services, including our crypto
          wallet app, games, and other services. By using our services, you consent to the practices described in this
          Privacy Policy.
        </p>
        <h2>Information We Collect</h2>
        <p>
          Usage Data: We may collect anonymous usage data related to our services, such as the features accessed within
          the app. This data is used solely to enhance our app’s functionality and user experience.
        </p>
        <p>
          Personal Data: For certain services that require your email address for functionality, we may collect and use
          your email address, name, to provide those services. We do not share your data with third parties.
        </p>
        <h2>Data Sharing</h2>
        <p>
          Third-Party Advertising: While we do not collect personal information, please note that our advertising
          network may collect anonymous data for the purpose of serving ads within our games with ads. This data is used
          for targeted advertising and analytics, and it does not include personally identifiable information.
        </p>
        <h2>Your Consent</h2>
        <p>
          By using our services, you agree to the collection and use of information as outlined in this Privacy Policy.
        </p>
        <h2>Changes to Privacy Policy</h2>
        <p>
          We may periodically update this Privacy Policy to reflect changes in our services or relevant laws. Any
          modifications become effective when we post the revised Privacy Policy. Your continued use of our services
          after such changes constitutes your consent to the revised policy.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns regarding this Privacy Policy or our data practices, please reach out to
          us at support@laristo.com
        </p>
        <p>
          At Scentub, we are committed to transparency in data collection and usage. Your privacy is paramount, and we
          are dedicated to protecting your information as we strive to offer you the best possible services.
        </p>
      </Col>
    </Row>
  );
};

export default Policy;
