import { Flex } from "antd";
import { useNavigate } from "react-router-dom";
import { pathData } from "../../../../utils/routes";
import logoWeb from "../../../../assets/logo-web.png";

const BasicHeader = () => {
  const navigate = useNavigate();
  return (
    <Flex justify="center">
      <img
        onClick={() => navigate(`${pathData.home}`)}
        style={{ cursor: "pointer", width: "8rem" }}
        src={logoWeb}
        alt=""
      />
    </Flex>
  );
};

export default BasicHeader;
