import { createContext, useState, useContext } from "react";

const PostContext = createContext();

export const PostProvider = ({ children, postInit }) => {
  const [postContext, setPostContext] = useState(postInit);

  return (
    <PostContext.Provider
      value={{
        postContext,
        setPostContext,
      }}>
      {children}
    </PostContext.Provider>
  );
};

export const usePostContext = () => useContext(PostContext);
