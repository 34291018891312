import "./index.css";
import { useRef, useState } from "react";
import { Button, Flex } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { popular_perfumes } from "../../../../utils/data";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import DividerTitle from "../DividerTitle";
import ItemPerfume from "./ItemPerfume";

const PopularPerfumes = () => {
  const { isMobileView } = useWindowSize();
  const containerRef = useRef(null);

  const [isScrolledToStart, setIsScrolledToStart] = useState(false);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

  const maxScrollLeft = isMobileView ? 20 : 100;
  const stepScroll = isMobileView ? 250 : 200;

  const handleScroll = () => {
    const container = containerRef.current;
    const isAtStart = container.scrollLeft > maxScrollLeft;
    const isAtEnd = container.scrollLeft + container.offsetWidth >= container.scrollWidth;

    setIsScrolledToStart(isAtStart);
    setIsScrolledToEnd(isAtEnd);
  };

  const scrollLeft = () => {
    containerRef.current.scrollBy({ left: -stepScroll, behavior: "smooth" });
  };

  const scrollRight = () => {
    containerRef.current.scrollBy({ left: stepScroll, behavior: "smooth" });
  };

  const btnSize = isMobileView ? "small" : "middle";

  return (
    <Flex vertical gap={0} className="vfra-popular-perfumes-parent-container">
      <DividerTitle title="Popular perfumes" />

      {isScrolledToStart && (
        <Button
          className="vfra-popular-perfumes-prev-btn"
          size={btnSize}
          shape="circle"
          onClick={scrollLeft}
          icon={<LeftOutlined />}
        />
      )}

      <Flex
        ref={containerRef}
        onScroll={handleScroll}
        justify="start"
        className={`vfra-popular-perfumes-container ${
          isScrolledToStart ? "vfra-popular-perfumes-container-before" : ""
        } ${!isScrolledToEnd ? "vfra-popular-perfumes-container-after" : ""}`}>
        {popular_perfumes.map((perfume) => {
          return <ItemPerfume key={perfume.id} data={perfume} />;
        })}
      </Flex>

      {!isScrolledToEnd && (
        <Button
          className="vfra-popular-perfumes-next-btn"
          size={btnSize}
          shape="circle"
          onClick={scrollRight}
          icon={<RightOutlined />}
        />
      )}
    </Flex>
  );
};

export default PopularPerfumes;
