import "./index.css";
import { Tabs } from "antd";
import { SolutionOutlined, ShoppingOutlined, ShopOutlined, HomeOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import { pathData } from "../../utils/routes";
import PostList from "./PostList";
import Brands from "./Brands";
import PerfumeList from "./PerfumeList";
import ScentubExplore from "./ScentubExplore";
import useWindowSize from "../../components/hooks/useWindowSize";
import { useEffect, useState } from "react";

const Home = ({ isMobileSearch = false }) => {
  const navigate = useNavigate();
  const { tabId } = useParams();

  let defaultTab = "scentub";
  if (!isMobileSearch && (tabId === "scentub" || tabId === "perfume" || tabId === "post" || tabId === "brand"))
    defaultTab = tabId;

  const { isMobileView } = useWindowSize();
  const [activeTab, setActiveTab] = useState(defaultTab);

  useEffect(() => {
    setActiveTab(tabId);
  }, [tabId]);

  let menu = [
    {
      key: "perfume",
      label: "Perfumes",
      children: (
        <div
          className="vfra-home-tab-content"
          id={`${isMobileSearch ? "vfra-search-tab-perfume" : "vfra-home-tab-perfume"}`}>
          <PerfumeList isMobileSearch={isMobileSearch} />
        </div>
      ),
      icon: !isMobileView ? <ShoppingOutlined /> : null,
    },
    {
      key: "post",
      label: `Posts`,
      children: (
        <div className="vfra-home-tab-content" id={`${isMobileSearch ? "vfra-search-tab-post" : "vfra-home-tab-post"}`}>
          <PostList isMobileSearch={isMobileSearch} />
        </div>
      ),
      icon: !isMobileView ? <SolutionOutlined /> : null,
    },
    {
      key: "brand",
      label: `Brands`,
      children: (
        <div
          className="vfra-home-tab-content"
          id={`${isMobileSearch ? "vfra-search-tab-brand" : "vfra-home-tab-brand"}`}>
          <Brands isMobileSearch={isMobileSearch} />
        </div>
      ),
      icon: !isMobileView ? <ShopOutlined /> : null,
    },
  ];

  if (!isMobileSearch)
    menu = [
      {
        key: "scentub",
        label: "Scentub",
        children: (
          <div className="vfra-home-tab-content" id="vfra-home-tab-scentub">
            <ScentubExplore />
          </div>
        ),
        icon: !isMobileView ? <HomeOutlined /> : null,
      },
      ...menu,
    ];

  const handleTabChange = (value) => {
    setActiveTab(value);
    if (isMobileSearch) return;
    navigate(`${pathData.home}/${value}`);
  };

  return (
    <Tabs
      onChange={handleTabChange}
      size="small"
      centered
      className={`vfra-multisearch-tabs ${!isMobileSearch ? "vfra-multisearch-tabs-home-mobile" : ""}`}
      activeKey={activeTab}
      items={menu}
    />
  );
};

export default Home;
