import { useNavigate } from "react-router-dom";
import { Button, Result } from "antd";
import { pathData } from "../../utils/routes";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button onClick={() => navigate(`${pathData.home}`)} shape="round">
          Back Home
        </Button>
      }
    />
  );
};

export default NotFoundPage;
