import "./index.css";
import { useNavigate } from "react-router-dom";
import { Flex, Typography, Image } from "antd";
import { colors } from "../../../../utils/service";
import { getPerfumeDescription } from "../../../../utils/service";
import Rating from "./Rating";
import CustomeSecondLabel from "../../../../components/common/CustomeSecondLabel";
import useWindowSize from "../../../../components/hooks/useWindowSize";
// import PerfumeShopping from "./PerfumeShopping";

const { Paragraph } = Typography;

const PerfumeInfor = ({ perfume, onRate, rateData, ...props }) => {
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();

  return (
    <Flex {...props} vertical gap={isMobileView ? 8 : 12} align="center">
      <h1 style={{ margin: "0", fontSize: "1.4rem", color: colors.mainThemeColor }}>{perfume.name}</h1>
      <Flex vertical gap={12} align="center" className="vfra-perfume-infor-container">
        <Image style={{ maxHeight: isMobileView ? "30vh" : "38vh" }} src={perfume.image_url} />
        <Flex vertical align="center" gap={6}>
          <h4
            style={{ margin: "0", textTransform: "uppercase", cursor: "pointer" }}
            onClick={() => navigate(`/brand/${perfume.brand_id}`)}>
            {perfume.brand_name}
          </h4>
          <Flex gap={6} align="center">
            <Rating badge={rateData?.value} disabled={false} onChange={onRate} />
            <CustomeSecondLabel label={`${rateData?.count ?? 0}`} />
          </Flex>
        </Flex>
      </Flex>

      {/* <PerfumeShopping vertical gap={"0.75rem"} align="center" perfume={perfume} /> */}

      <Paragraph
        style={{ fontSize: "0.9rem" }}
        ellipsis={{
          rows: 3,
          expandable: "collapsible",
          symbol: (expanded) => (expanded ? "less" : "more"),
        }}>
        {getPerfumeDescription(perfume)}
      </Paragraph>
    </Flex>
  );
};

export default PerfumeInfor;
